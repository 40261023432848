<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">Category List</h2>
          <ng-container *ngIf="this.userData.auth.data.role === 'Super Admin'">
            <button class="btn primary-btn float-right mr-2" (click)="category_create_modal(create)"><i
              class="ft ft-plus-circle mr-1"></i>Add
            </button>
          </ng-container>

        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-xl-6 col-md-12 col-12">
                <ul class="list-group" *ngIf="userData.auth.data.role !== 'Super Admin'">
                  <li class="list-group-item cursor-pointer" (click)="category.collapse = !category.collapse;"
                      [attr.aria-expanded]="!category.collapse" aria-controls="{{category._id}}"
                      *ngFor="let category of categorylist;let i = index;">{{ category.categoryName }}
                    <div id="{{category._id}}" [ngbCollapse]="category.collapse">
                      <div class="row">
                        <div class="col-lg-6 col-md-4 col-sm-4 col-xl-4 col-12 p-1"
                             *ngFor="let label of category.propertiesList;let i = index;">
                          <span class="badge bg-light-primary text-capitalize">{{ label.labelName }}</span>
                        </div>
                      </div>
                    </div>
                    <a class="text-secondary float-right">
                      <i class=" font-medium-3" [ngClass]="!category.collapse ? 'ft-chevron-up' : 'ft-chevron-down'"></i>
                    </a>
                  </li>
                </ul>

                <ul class="list-group" *ngIf="userData.auth.data.role === 'Super Admin'">
                  <li class="list-group-item cursor-pointer"
                      *ngFor="let category of categorylist;let i = index;">{{ category.categoryName }}
                    <a class="danger float-right">
                      <i class="ft-trash font-medium-3" (click)="openModal(confirm,category)"></i>
                    </a>
                    <a (click)="openModal(editCategory,category,'lg')"
                       class="text-primary float-right mr-1">
                      <i class="ft-edit font-medium-3"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--<ng-template #create let-c="close" let-d="dismiss">-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title">Add Category</h4>-->
<!--    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--        <form [formGroup]="categoryForm" (ngSubmit)="OnSubmit()">-->
<!--          <div class="form-row">-->
<!--            <div class="col-md-6 col-12">-->
<!--              <div class="form-group mb-2">-->
<!--                <label>Category Name</label>-->
<!--                <input type="text" class="form-control" formControlName="categoryname">-->
<!--                <p *ngIf="submitted && ctgry.categoryname.hasError('required')" class="text-danger">Category Name is-->
<!--                  required</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <button type="submit" class="btn secondary-btn float-right"><i class="ft-check-square mr-1"></i>Save</button>-->
<!--        </form>-->
<!--      </div>-->
<!--</ng-template>-->
<ng-template #create let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Add Category</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close"
          (click)="d('Cross click'); this.selectedLabels = [];"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="categoryForm" (ngSubmit)="OnSubmit()">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <div class="form-group mb-2">
            <label>Category Name</label>
            <input type="text" class="form-control" formControlName="categoryName">
            <p *ngIf="submitted && ctgry.categoryName.hasError('required')" class="text-danger">Category Name is
              required</p>
            <p *ngIf=" ctgry.categoryName.hasError('pattern')" class="text-danger">Invalid category name</p>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <div class="form-group mb-2">
            <label>Description</label>
            <textarea name="" id="" cols="30" rows="3" class="form-control" formControlName="description"></textarea>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-12">
          <h6 class="modal-title text-uppercase">Properties to be displayed
            <i class="ft-info text-info cursor-pointer" (click)="isCollapsed =!isCollapsed;"
               [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"></i></h6>
          <p id="collapseExample" [ngbCollapse]="isCollapsed">The default labels are already selected.</p>
          <div class="row mt-2">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 pt-1"
                 *ngFor="let cate_label of categoryLables;let i = index;" [class.d-none]="!showAll && i >= 6">
              <div class="checkbox">
                <input type="checkbox" class="form-control" id="{{ cate_label._id }}" [disabled]="i<9 || cate_label.labelName === 'uploadProductInfo'"
                       [checked]="cate_label.checked"
                       (change)="OnSelectLabel($event,cate_label)">
                <label class="text-secondary"
                       for="{{ cate_label._id }}">{{ cate_label.labelName === 'uploadProductInfo' ? 'Additional Resources' : cate_label.labelName }}</label>
                <!--                                <span class="float-right"><i class="ft-trash text-danger cursor-pointer ml-2"-->
                <!--                                                             (click)="deleteLabel(cate_label)" ngbTooltip="Delete label"-->
                <!--                                                             placement="top"></i></span>-->

              </div>
            </div>
          </div>
          <p class="text-primary cursor-pointer mt-1" *ngIf="categoryLables.length > 0"
             (click)="this.showAll = !this.showAll;">{{ showAll ? 'Less...' : 'More...' }}</p>
          <span class="btn btn-sm primary-btn mt-1"
                (click)="openCreateModal(add_category_label,'md')">Add Label <i
            class="ft-plus-circle ml-1"></i></span>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <button type="submit" [disabled]="disableBTN" class="btn secondary-btn float-right"><i
            class="ft-check-square mr-1"></i>Save
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--Edit Category details-->

<ng-template #editCategory let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Update Category</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="d('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="categoryForm" (ngSubmit)="updateCategoryDetails(modal)">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <div class="form-group mb-2">
            <label>Category Name</label>
            <input type="text" class="form-control" formControlName="categoryName">
            <p *ngIf="submitted && ctgry.categoryName.hasError('required')" class="text-danger">Category Name is
              required</p>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <div class="form-group mb-2">
            <label>Description</label>
            <textarea cols="30" rows="3" class="form-control" formControlName="description"></textarea>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-12">
          <h6 class="modal-title text-uppercase">Properties to be displayed</h6>
          <div class="row mt-2">
            <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 pt-1"
                 *ngFor="let cate_label of categoryLables;let i = index;" [class.d-none]="!showAll && i > 9">
              <div class="checkbox">
                <input type="checkbox" class="form-control" id="{{ cate_label._id }}"
                       [checked]="cate_label.checked" [disabled]="i<9 || cate_label.labelName === 'uploadProductInfo'"
                       (change)="OnSelectLabel($event,cate_label)">
                <label
                  for="{{ cate_label._id }}">{{ cate_label.labelName === 'uploadProductInfo' ? 'Additional Resources' : cate_label.labelName }}</label>
                <span class="float-right" *ngIf="i > 9 && !cate_label.selected"> <i
                  class="ft-edit text-primary cursor-pointer ml-2"
                  (click)="openLabelModal(edit_category_label,cate_label,'labelsEdit',i)"
                  ngbTooltip="Edit label" placement="top"></i>
                </span>
                <span class="float-right" *ngIf="i > 9 && !cate_label.selected"><i
                  class="ft-trash text-danger cursor-pointer ml-2"
                  (click)="openDeleModal(confirm,cate_label)" ngbTooltip="Delete label"
                  placement="top"></i></span>

              </div>
            </div>
          </div>
          <p class="text-primary cursor-pointer mt-1" *ngIf="categoryLables.length > 6"
             (click)="this.showAll = !this.showAll;">{{ showAll ? 'Less...' : 'More...' }}</p>
          <span class="btn btn-sm primary-btn mt-1"
                (click)="openCreateModal(add_category_label,'md')">Add Label <i
            class="ft-plus-circle ml-1"></i></span>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-12 col-12">
          <button type="submit" class="btn secondary-btn float-right"><i
            class="ft-check-square mr-1"></i>Save Changes
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #edit_category_label let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Update Label Details</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="d('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="labelForm" (ngSubmit)="updateLabel_details(modal)">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12">
          <label>Label Name</label>
          <input type="text" class="form-control" formControlName="labelName" readonly>
          <p class="text-danger"
             *ngIf="submitted1 && this.labelForm.controls['labelName'].hasError('required')">Label name is
            required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12  col-sm-12 col-xl-12 col-lg-12">
          <label>Value</label>
          <input type="text" class="form-control" formControlName="value">
          <p class="text-danger" *ngIf="submitted1 && this.labelForm.controls['value'].hasError('required')">
            Label value is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12 text-center">
          <button type="submit" class="btn primary-btn mt-2">Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #add_category_label let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ modalType !== 'createModal' ? 'Update Label Details' : 'Add Label Details' }}</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="d('Cross click');"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="labelForm" (ngSubmit)="create_label(modal)">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12">
          <label>Label Name</label>
          <input type="text" class="form-control" formControlName="labelName">
          <p class="text-danger" *ngIf="submitted1 && this.labelForm.controls['labelName'].hasError('required')">Label
            name is required</p>
          <p class="text-danger" *ngIf="this.labelForm.controls['labelName'].hasError('pattern')">Invalid Label name</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12  col-sm-12 col-xl-12 col-lg-12">
          <label>Value</label>
          <input type="text" class="form-control" formControlName="value">
          <p class="text-danger" *ngIf="submitted1 && this.labelForm.controls['value'].hasError('required')">
            Label value is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12 pt-1 text-center">
          <button type="submit" [disabled]="disableBtn" class="btn primary-btn">Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--Delete category -->

<ng-template #confirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{modalTitle === 'category' ? 'Delete Category' : 'Delete Label'}}</h4>
  </div>
  <div class="modal-body text-center">
    <p>{{ modalTitle === 'category' ? 'Do you want to delete this Category ?' : 'Are you sure you want to delete this label?' }}</p>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" *ngIf="modalTitle === 'category'" class="btn btn-danger" (click)="deleteCategory(modal)">Yes</button>
    <button type="button" *ngIf="modalTitle !== 'category'"  class="btn btn-danger" (click)="deleteLabel(modal)">Yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>
