<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
           data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 ml-3 d-none d-lg-block">
                <span
                  class="text-right font-weight-bold primary-color text-capitalize">Welcome! {{this.user.firstName || 'root'}}</span>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div>
                <span class="text">APEX</span></a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown *ngIf="this.userData.auth.data.role !== 'User' && this.userData.auth.data.role !== 'Master Admin'">
            <!--Organization Dropdown list-->
            <select class="form-control" [ngModel]="defaultPharmacy" (change)="changePharmacy($event)">
              <option  *ngIf="this.userData.auth.data.role === 'Super Admin'" selected value="select">Select</option>
              <option *ngFor="let pharmacy of pharmacyList" [value]="pharmacy?._id">{{pharmacy?.pharmacy_name}}</option>
            </select>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link align-items-end">
              <div class="user mr-2" (click)="logout()">
                <i class="ft-power mr-2"></i><span>Logout</span>
              </div>
              <!--              <img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35" width="35"/>-->
            </a>
          </li>
          <!--          <li class="nav-item d-none d-lg-block mr-2 mt-1">-->
          <!--            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i-->
          <!--              class="ft-align-right font-medium-3"></i></a>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
</nav>

