<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="card-header">
              <h4 class="card-title">Audit Logs</h4>
            </div>
          </div>
          <div class="col-md-6 col-12 px-2 py-2">
            <div class="input-group float-right mr-2" style="width: 300px">
              <div class="input-group-prepend dp-hidden">
                <input
                  name="datepicker"
                  class="form-control dp-hidden"
                  ngbDatepicker
                  #datepicker="ngbDatepicker"
                  [autoClose]="'outside'"
                  (dateSelect)="onDateSelection($event)"
                  [displayMonths]="1"
                  [dayTemplate]="t"
                  outsideDays="hidden"
                  [startDate]="fromDate!"
                  tabindex="-1"
                />
                <ng-template #t let-date let-focused="focused">
					<span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
						{{ date.day }}
					</span>
                </ng-template>
              </div>
              <input
                #dpFromDate
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpFromDate"
                [value]="from + ' - ' + to"
                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
              />
              <div class="input-group-append">
                <div class="input-group-text" (click)="datepicker.toggle()">
                  <i class="fa fa-calendar" style="cursor: pointer;"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-12 px-3 py-3 d-flex justify-content-end align-items-end">
            <button class="primary-btn btn mx-1" (click)="download_audits()"><i
              class="ft-download mr-1"></i>Download
            </button>
            <select (change)="OnChangeEnums($event)"  class="form-control text-capitalize" style="width: 200px;">
              <option selected disabled>Select</option>
              <option *ngFor="let enum of tableData" [value]="enum">{{ enum }}</option>
            </select>
          </div>
        </div>
        <h4 class="text-center" *ngIf="auditLogs.length === 0">No Audit logs found</h4>
        <div class="card-body">
          <div class="table-responsive" *ngIf="auditLogs.length > 0">
            <table class="table">
              <thead class="thead-light text-center">
              <tr>
                <th>#</th>
                <th>Change</th>
                <th>Date</th>
                <th>Table</th>
                <th>Record</th>
              </tr>
              </thead>
              <tbody class="text-center">
              <tr *ngFor="let auditlog of auditLogs">
                <td>{{ auditlog.slNumber }}</td>
                <td>
                  <p class="text-justify">{{ auditlog.change }}</p>
                  </td>
                <td>{{ auditlog.changedDate.split('T')[0].split('-').reverse().join('-') }}</td>
                <td class="text-capitalize">{{ auditlog.table }}</td>
                <td>{{ auditlog.recordId }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <ngb-pagination class="float-right" [collectionSize]="this.maxSize * 10" (pageChange)="pagination($event)"
                            [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
