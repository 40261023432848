<div class="row" *ngIf="error !== ''">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title text-center">{{ error }}</h3>
      </div>
    </div>
  </div>
</div>
<section *ngIf="error === ''">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 col-12">
                <h1 class="head-title d-none d-lg-block" *ngIf="hasProductType('originalName')">{{ this.globalProdselected?.originalName }}</h1>
                <h3 class="head-title d-sm-block d-lg-none"
                    *ngIf="hasProductType('brandName')">{{ this.globalProdselected?.brandName }}</h3>
              </div>
              <div class="col-md-12 col-12" *ngIf="hasProductType('brandName')">
                <h5 class="font-weight-bold">{{ this.globalProdselected?.brandName }}</h5>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-8 col-lg-8 col-xl-9 col-12">
                <div class="row">
                  <div class="col-md-4 col-12" *ngIf="hasProductType('size_amount')">
                    <label class="font-weight-bold">Size Amount</label>
                    <p>{{ this.globalProdselected?.size_amount === undefined ? '' : this.globalProdselected?.size_amount + ' ' + this.globalProdselected?.size_measure === undefined ? '' : this.globalProdselected?.size_measure }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('price_per_mg')">
                    <label class="font-weight-bold">Price (per g)</label>
                    <p>{{ this.globalProdselected?.price_per_mg }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('originalCost')">
                    <label class="font-weight-bold">Cost</label>
                    <p>{{ this.globalProdselected?.originalCost }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('productType')">
                    <label class="font-weight-bold">Type</label>
                    <p>{{ this.globalProdselected?.productType }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('TGAProductCategory')">
                    <label class="font-weight-bold">TGA Product Category</label>
                    <p>{{ this.globalProdselected?.TGAProductCategory }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('THCLabel')">
                    <label class="font-weight-bold">THC Label <i class="ft-info cursor-pointer text-info"
                                                                 placement="top"
                                                                 ngbTooltip="tetrahydrocannabinol"></i></label>
                    <p>{{ this.globalProdselected?.THCLabel }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('CBDLabel')">
                    <label class="font-weight-bold">CBD Label <i class="ft-info cursor-pointer text-info"
                                                                 placement="top"
                                                                 ngbTooltip="cannabidiol"></i></label>
                    <p>{{ this.globalProdselected?.CBDLabel }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('productSchedule')">
                    <label class="font-weight-bold">Product Schedule</label>
                    <p>{{ this.globalProdselected?.productSchedule }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('category')">
                    <label class="font-weight-bold">Category</label>
                    <p>{{ this.globalProdselected?.category }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('productSpecies')">
                    <label class="font-weight-bold">Product Species</label>
                    <p>{{ this.globalProdselected?.productSpecies }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('aromas')">
                    <label class="font-weight-bold">Aromas</label>
                    <p>{{ this.globalProdselected?.aromas }}</p>
                  </div>

                  <div class="col-md-4 col-12" *ngFor="let item of this.externalLabel | keyvalue">
                    <label class="font-weight-bold text-capitalize">{{ item.key.replace('_', ' ') }}</label>
                    <p class="text-justify">{{ item.value }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('description')">
                    <label class="font-weight-bold">Description</label>
                    <p class="text-justify">{{ this.globalProdselected?.description }}</p>
                  </div>
                  <div class="col-md-4 col-12" *ngIf="hasProductType('originalData')">
                    <label class="font-weight-bold">Original Data</label>
                    <p class="text-justify">{{ this.globalProdselected?.originalData }}</p>
                  </div>
                  <div class="col-md-12 col-12" *ngIf="hasProductType('uploadProductInfo')">
                    <label class="font-weight-bold">Additional Resources</label><br>
                    <div class="btn-group"
                         *ngFor="let url of this.globalProdselected?.uploadProductInfo;let i = index;">
                      <a *ngIf="url?.fileName !== '' && url?.fileName !== undefined" class="cursor-pointer ml-2"
                         style="text-decoration: underline;"
                         (click)="downloadFiles(url,i)">{{ url?.fileName }}
                        <i class="ft-download mr-1"></i>
                      </a>
                      <a
                        *ngIf="userData?.auth?.data.role !== 'User' && url?.fileName !== '' && url?.fileName !== undefined"
                        ngbTooltip="Delete file" placement="top"
                        class="ft-x text-danger mr-1 cursor-pointer" (click)="deleteFile(url)"></a>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-xl-3 col-12 text-center" *ngIf="hasProductType('productImage')">
                <img [src]="this.globalProdselected?.productImage === ' ' || this.globalProdselected?.productImage === '' || this.globalProdselected?.productImage === undefined ? 'https://ttbackendstorage.s3.ap-southeast-2.amazonaws.com/2023-11-03T17%3A49%3A26.359Z160-1609305_vegan-logo-png-vegan-or-vegetarian-symbol-transparent.png'
                                   :this.globalProdselected?.productImage"
                     alt="{{this.globalProdselected?.originalName}}"
                     class="users-avatar-shadow rounded" width="100%">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

