import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {
  ModalDismissReasons,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct, NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {Json2csvService} from '../../../../shared/services/json2csv.service';
import {PdfServiceService} from '../../../../shared/services/pdf-service.service';
// @ts-ignore
const moment = require('moment');
const momentTimezone = require('moment-timezone');


@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {

  showcalendar = false;
  date = new Date();
  userData: any;
  pharmacy: any;
  auditLogs: any[] = [];
  todayDay: any;
  formattedDate: any;
  dateSearch = new Date();
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  from: string;
  to: string;
  isCollapsed = true;
  selectedOrder: any
  closeResult = '';
  rows: any[] = [];
  page = 1;
  maxSize = 0;
  totalCount = 0;
  tableData = ['administrators', 'medications', 'orderitems', 'orders', 'orderupdates', 'otps', 'orderpayments', 'pharmaadministrators', 'pharmacies', 'platform', 'pharmacyproductcategories', 'pharmacyproducts', 'pharmacyusers', 'prescriptions', 'roles', 'scripts', 'usages', 'userimages', 'verifications', 'eRx', 'issuecategory'];
  selectedEnum = '';

  constructor(private authService: AuthService, private monthPipe: MonthFormatPipe,
              private pdfService: PdfServiceService,
              private modalService: NgbModal, private jsonCSv: Json2csvService,
              private toastr: ToastrService, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar) {
  }

  ngOnInit(): void {
    this.todayDay = moment(new Date()).format('YYYY-MM-DD');
    this.userData = JSON.parse(localStorage.getItem('script-capture'));
    this.from = moment(new Date()).format('YYYY-MM-DD');
    this.to = moment(new Date()).format('YYYY-MM-DD');
    this.formattedDate = momentTimezone(this.monthPipe.transform(this.dateSearch)).tz('asia/kolkata').format();
    this.authService.fetch_audit_logs(this.userData, moment(new Date()).format(), moment(new Date()).format(), this.page).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.maxSize = resp.totalPages;
      this.auditLogs = resp.data;
      this.totalCount = resp.totalCount;
    }).catch(err => {
      if (err.status === 404) {
        this.auditLogs = [];
        this.maxSize = 0;
        this.totalCount = 0;
        return;
      }
    });
  }

  onDateSelection(date: NgbDate) {
    this.page = 1;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.toDate !== undefined && this.toDate !== null) {
      const x = moment(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day).format('YYYY-MM-DD');
      const {day, month, year} = this.toDate;
      const y = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
      this.from = x;
      this.to = y;
      this.authService.fetch_audit_logs(this.userData, x,
        y, this.page).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        this.maxSize = resp.totalPages;
        this.auditLogs = resp.data;
        this.totalCount = resp.totalCount;
      }).catch(err => {
        if (err.status === 404) {
          this.auditLogs = [];
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    }

  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  download_audits() {
    if (this.totalCount !== 0 && this.selectedEnum !== '') {
      this.authService.fetch_audit_logs(this.userData, this.from,
        this.to, this.page, this.totalCount, this.selectedEnum).then((resp: any) => {
        if (resp.status === 403) {
          this.toastr.error(resp.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        } else {
          this.auditLogs = resp.data;
          this.maxSize = resp.totalPages
          const temp = [];
          let body: any = {}
          this.auditLogs.filter(log => {
            body.Change = log.change;
            body.ChangedBy = log.changedBy;
            body.Date = log.changedDate.split('T')[0].split('-').reverse().join('-');
            body.Table = log.table;
            body.Record = log.recordId;
            body.User = log.changedByUser;
            temp.push(body);
            body = {};
          });
          // this.jsonCSv.downloadCsv(temp, 'AuditLogs')
          this.pdfService.generatePdf(temp);
        }

      }).catch(err => {
        if (err.status === 404) {
          this.auditLogs = [];
          this.totalCount = 0;
          this.maxSize = 0;
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
      });
    } else {
      const temp = [];
      let body: any = {}
      this.auditLogs.filter(log => {
        body.Change = log.change;
        body.ChangedBy = log.changedBy;
        body.Date = log.changedDate.split('T')[0].split('-').reverse().join('-');
        body.Table = log.table;
        body.Record = log.recordId;
        body.User = log.changedByUser;
        temp.push(body);
        body = {};
      });
      // this.jsonCSv.downloadCsv(temp, 'AuditLogs')
      this.pdfService.generatePdf(temp);
    }


  }

  OnChangeEnums(event: Event) {
    this.selectedEnum = (event.target as HTMLInputElement).value.toLowerCase();
    this.authService.fetch_audit_logs(this.userData, this.from,
      this.to, this.page, this.totalCount, this.selectedEnum).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.maxSize = resp.totalPages;
        this.auditLogs = resp.data;
        this.totalCount = resp.totalCount;
      }
    }).catch(err => {
      if (err.status === 404) {
        this.auditLogs = [];
        this.totalCount = 10;
        this.maxSize = 0;
        this.toastr.error(err.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    });
  }

  pagination(page: number) {
    this.page = page;
    this.authService.fetch_audit_logs(this.userData, this.from,
      this.to, this.page, 10, this.selectedEnum).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.auditLogs = resp.data;
      }
    }).catch(err => {
      if (err.status === 404) {
        this.auditLogs = [];
        this.toastr.error(err.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
    });
  }
}
