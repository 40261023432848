import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent, ColumnMode} from '@swimlane/ngx-datatable';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {WizardComponent, ArchwizardModule} from 'angular-archwizard';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit {

  pharmacyList: any[] = [];
  selectedPharmacyState: any;
  lockButton = false;
  choiceMade = '';
  post_code_pharmacyList: any[] = [];
  showPostcode = false;
  closeResult = '';
  showMedicines = false;
  count = 1;
  trackFileUpload = false;
  show = '';
  rows: any[] = [];
  userData: any;
  submitted = false;
  selectedOrder: any;
  ItemsTotal: number;
  showUpload = false;
  userStatus = '';
  selectedPharmacy: any;
  pickup = false;
  platform: any;
  validationObject = {
    required: false,
    invalid: false,
  }
  frontFile: any;
  backFile: any;
  pharmacyUser: any;
  viewScript = '';
  myorderObj: any = {};
  orderedItems: any[] = [];
  deliverTypeSelected = false;
  deliverModal = {
    delivery: false,
    pickup: false
  }
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
  showUploadMessage = '';
  productList: any[] = [];
  IHI_number = '';
  scriptImage = '';
  Medicinerows: any = [];
  selectedProduct: any;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;
  @ViewChild(WizardComponent) wizard: any;

  public ColumnMode = ColumnMode;
  scriptForm = new FormGroup({
    token: new FormControl('', [Validators.required, Validators.pattern('[A-Z0-9]{18}')])
  })
  index: number;
  showMessageBlock = true;
  isCollapsed = true;
  check = false;
  frontLicense = '';
  backLicense = '';
  medicareCard = '';
  pensionCard = '';
  fileCount = 0;
  selectedAddress: any;
  drivingLicendseType = '';

  addressForm = new FormGroup({
    addressType: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  })
  userAddress: any[] = [];
  defaultPharmacy: any;
  delivery_Instruction = '';
  selectedScript: any;
  selectedMedicince: any;
  selectedIndex: any;
  selectedCustomer: any;
  orderBy: any;
  type = '';
  file: any | undefined;
  orderedItemsModal = {
    'token': '',
    'product_brand_name': '',
    'product_name': '',
    'medication_strength': '',
    'quantity': '',
    'price': 0,
    'repeats': 0,
    'instruction': '',
    'note': '',
    'globalProductId': '',
  }
  showAuthTPAForm = false;
  validation = false;
  uploadType = '';
  selectedState = null;
  showUrl = false;
  scriptImage_for = 'for_complete_order';
  selectedMedicine_Index: number;
  selectedStep = '';

  constructor(private authService: AuthService, private toastr: ToastrService,
              private downloadFile: DownloadFileService, private storage: LocalStorageService,
              private router: Router, private activatedRoute: ActivatedRoute,
              private modalService: NgbModal, private spinner: NgxSpinnerService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(localStorage.getItem('platform'));
    this.activatedRoute.queryParams.subscribe((response: any) => {
      if (Object.keys(response).length > 0) {
        this.orderBy = response.type;
        this.selectedPharmacy = JSON.parse(response.pharmacy);
        this.selectedCustomer = JSON.parse(response.customer).userId;
        this.userAddress = this.selectedCustomer.addresses;
        this.userAddress.map((address) => {
          address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
          address.icon = 'ft-x-circle text-danger';
        });
        this.post_code_pharmacyList = [this.selectedPharmacy];
      }
    });
    if (this.selectedCustomer === undefined) {
      this.selectedCustomer = this.userData.auth.data;
    }
    if (this.orderBy === undefined) {
      this.orderBy = 'Customer'
    }

    this.authService.fetch_pharmacy_user_verified(this.userData, this.platform).then((resp: any) => {
      this.pharmacyList = resp;
    });
    if (this.userAddress.length === 0) {
      this.loadUserAddress();
    }
  }

  loadUserAddress() {
    this.authService.fetch_user_address(this.userData).then((resp) => {
      this.userAddress = resp;
      this.userAddress.map((address) => {
        address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
        address.icon = 'ft-x-circle text-danger';
      });
    })
  }

  open(content, size, step = 'step-2') {
    this.selectedStep = '';
    this.selectedStep = step;
    this.scriptImage_for = 'for_complete_order';
    this.uploadType = ''
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  AddScriptImage(modal, size, index) {
    this.uploadType = ''
    this.scriptImage_for = 'for_each_medicine';
    this.selectedMedicine_Index = index;
    this.modalService.open(modal, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openAddressModal(content, size) {
    this.addressForm.reset();
    this.submitted = false;
    if (this.userAddress.length === 3) {
      this.toastr.error('Maximum User address limit exceeded', 'Error!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  confirmation(content, size) {
    if (this.orderedItems.length === 0) {
      this.toastr.clear();
      this.toastr.warning('Kindly choose the medication by ticking the checkbox.', 'Warning!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (!this.deliverTypeSelected) {
      this.toastr.info('Please Select the Delivery Type to place the Order', 'Info!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const check = this.orderedItems.some((data) => {
      return data.token === '';
    });
    if (check) {
      this.toastr.clear();
      this.toastr.error('Please fill the token for the selected Products', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    this.open(content, size);
  }

  openDeliveryModal(content, size, deliveryType) {

    if (this.selectedAddress === undefined) {
      this.toastr.error('Please select your address', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
      this.deliverModal.delivery = false;
      this.deliverModal.pickup = false;
    }
    if (this.userStatus === 'unverified') {
      this.deliverModal.delivery = false;
      this.deliverModal.pickup = false;
      this.toastr.error('Please upload the document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (deliveryType) {
      this.deliverModal.pickup = true;
      this.deliverModal.delivery = false;
    } else {
      this.deliverModal.delivery = true;
      this.deliverModal.pickup = false;
    }

    this.deliverTypeSelected = true;
    this.pickup = deliveryType;
    this.myorderObj = {
      userId: this.selectedCustomer._id,
      pharmacyId: this.selectedPharmacy._id,
      pickup: this.pickup,
      orderedItem: this.orderedItems,
      address: this.selectedAddress.address,
      suburb: this.selectedAddress.suburb,
      state: this.selectedAddress.state,
      postcode: this.selectedAddress.postcode,
      usageId: '65168634b795d0617ab4d84e',
      uploadedScript: this.scriptImage === '' ? '' : this.scriptImage,
    }
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModal(content, type: string) {
    this.show = type;
    this.modalService.open(content, {size: 'md', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // This function is used in open


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.toggleExpandRow(row);
  }

  placeOrder(content) {
    this.myorderObj = {...this.myorderObj, ['deliveryInstruction']: this.delivery_Instruction};
    this.lockButton = true;
    this.authService.createOrder(this.userData, this.myorderObj).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else if (resp.status === 404) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      } else {
        this.toastr.success('Order placed', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.modalService.dismissAll(content);
        if (this.orderBy === 'Customer') {
          this.router.navigate(['/cust-order-history']);
        } else {
          this.router.navigate(['/orders']);
        }
      }
    }).catch(err => {
      this.toastr.error('Failed to placed the Order', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.lockButton = false;
      this.modalService.dismissAll(content);
    })
  }

  add(smallModal) {
    this.submitted = true;
    if (this.scriptForm.invalid) {
      return;
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const medicineObj = {
      token: this.scriptForm.value.token,
      data: [],
    }
    const medicineData = [];
    this.authService.getMedication_token_scan(this.userData, this.scriptForm.value.token, this.selectedCustomer, this.selectedPharmacy,
      this.orderBy).then((resp: any) => {
      if (resp.status === 400) {
        this.spinner.hide();
        this.toastr.error(resp.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.modalService.open(smallModal, {size: 'md', backdrop: 'static'});
      this.spinner.hide();
      if (resp.IHI_number) {
        const body = [{
          propName: 'Status', value: 'unverified'
        }];
        this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
        });
        for (const mediData of resp.medications) {
          medicineData.push(mediData);
        }
        medicineObj.data = medicineData;
        if (this.rows.length > 0) {
          const check = this.rows.some((item) => {
            return item.token === medicineObj.token;
          });
          if (check) {
            this.toastr.error('Token already exits in the medicine List', 'Error', {
              positionClass: 'toast-top-center'
            });
            this.submitted = false;
            this.scriptForm.reset();
            this.modalService.dismissAll();
            return;
          }
        }
        if (this.productList.length > 0) {
          const res = medicineObj.data.filter((item) => {
            this.productList.map((prod) => {
              if (item.product_name === prod.productName) {
                item.price = prod.price;
              }
            });
            return item;
          });
          medicineObj.data = res;
          this.rows.push(medicineObj);
        } else {
          this.rows.push(medicineObj);
        }

      } else {
        for (const mediData of resp) {
          medicineData.push(mediData);
        }
        medicineObj.data = medicineData;
        if (this.rows.length > 0) {
          const check = this.rows.some((item) => {
            return item.token === medicineObj.token;
          });
          if (check) {
            this.toastr.error('Token already exits in the medicine List', 'Error', {
              positionClass: 'toast-top-center'
            });
            this.submitted = false;
            this.scriptForm.reset();
            this.modalService.dismissAll();
            return;
          }
        }

        if (this.productList.length > 0) {
          const res = medicineObj.data.filter((item) => {
            this.productList.map((prod) => {
              if (item.product_name === prod.productName) {
                item.price = prod.productCost;
              }
            });
            return item;
          });
          medicineObj.data = res;
          this.rows.push(medicineObj);
        } else {
          this.rows.push(medicineObj);
        }
      }
    }).catch(err => {
    })
    this.submitted = false;
    this.scriptForm.reset();
    this.modalService.dismissAll();

  }


  getPharmacy(event: any, postcode) {
    this.selectedState = null;
    postcode.value = '';
    this.defaultPharmacy = event;
    if (this.defaultPharmacy === undefined) {
      this.selectedPharmacyState = undefined;
      this.post_code_pharmacyList = [];
    } else {
      this.post_code_pharmacyList = [this.defaultPharmacy];
    }
  }


  upload(file: any, modal) {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
    this.authService.uploadScript_image(this.userData, file.target.files[0]).then((resp: any) => {
      this.toastr.success('Script Uploaded Successfully', 'Success!', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
      if (this.scriptImage_for === 'for_each_medicine') {
        this.orderedItems[this.selectedMedicine_Index].scriptData.uploadedScript = resp.location;
        this.orderedItems[this.selectedMedicine_Index].scriptData.type = this.uploadType;
      } else {
        this.scriptImage = resp.location;
      }
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(this.scriptImage) ? true : false;
      modal.dismiss('Cross-Click');
    });
  }

  searh_phar_post(postcode: any) {
    this.selectedState = null;
    this.selectedPharmacyState = null;
    this.states = [];
    this.states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
    if (postcode.value === '') {
      this.validationObject.required = true;
      this.validationObject.invalid = false;
      return;
    }
    this.validationObject.required = false;
    this.validationObject.invalid = false;
    const postCode = Number(postcode.value);
    this.authService.fetch_pharmacy_postcode(this.userData, this.platform, 'postcode', postCode).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.info('No Pharmacy Found', 'Info!', {
          positionClass: 'toast-top-center'
        });
        this.post_code_pharmacyList = [];
      } else {
        this.post_code_pharmacyList = resp;
      }
    }).catch(err => {
    });
  }

  validatepostcode(postcode) {
    const regex = /[0-9]{4}/;
    if (!regex.test(postcode.value)) {
      this.validationObject.invalid = true;
      this.validationObject.required = false;
      this.post_code_pharmacyList = [];
    } else {
      this.post_code_pharmacyList = [];
      this.validationObject.invalid = false;
      this.validationObject.required = false;
      this.searh_phar_post(postcode);
    }
  }

  setItem(medicine, event, token) {
    // this.check = event.target.checked;
    // if (this.orderedItems.length > 0) {
    //   const check = this.orderedItems.some((item) => {
    //     return item.token === medicine.token;
    //   })
    //   if (check) {
    //     const filtered = this.orderedItems.filter((item) => {
    //       return medicine.token !== item.token;
    //     });
    //     this.orderedItems = filtered;
    //   } else {
    //     this.orderedItems.push(medicine);
    //   }
    // } else {
    //   this.orderedItems.push(medicine);
    // }

    this.orderedItemsModal.token = token.value.toUpperCase();
    this.orderedItemsModal.price = medicine.productCost;
    this.orderedItemsModal.product_brand_name = medicine.brandName;
    this.orderedItemsModal.product_name = medicine.productName;
    this.orderedItemsModal.quantity = medicine.quantity;
    this.orderedItemsModal.globalProductId = medicine.globalProductId
    this.orderedItemsModal.repeats = 0;
    this.orderedItemsModal.instruction = '';
    this.orderedItemsModal.note = '';

    //
    // if (this.orderedItemsModal.token === '') {
    //   this.check = false;
    //   this.toastr.warning('Please enter the Token Number ', 'Warning', {
    //     positionClass: 'toast-top-center'
    //   });
    //   return;
    // }
    //
    // // @ts-ignore
    // if (this.orderedItemsModal.quantity === 0) {
    //   this.check = false
    //   this.toastr.warning('Please update the quantity ', 'Warning', {
    //     positionClass: 'toast-top-center'
    //   });
    //   return;
    // }


    this.check = event.target.checked;
    if (this.orderedItems.length > 0) {
      const check = this.orderedItems.some((item) => {
        return item.product_name === this.orderedItemsModal.product_name;
      })
      if (check) {
        const filtered = this.orderedItems.filter((item) => {
          return this.orderedItemsModal.product_name !== item.product_name;
        });
        this.orderedItems = filtered;
      } else {
        this.orderedItems.push(this.orderedItemsModal);
      }
    } else {
      this.orderedItems.push(this.orderedItemsModal);
    }
    this.orderedItemsModal = {
      'token': '',
      'product_brand_name': '',
      'product_name': '',
      'medication_strength': '',
      'quantity': '',
      'price': 0,
      'repeats': 0,
      'instruction': '',
      'note': '', 'globalProductId': ''
    };
  }


  onChangeProduct(event: any, modal) {
    if (this.selectedStep === 'step-3') {
      this.selectedProduct = event;
      if (this.selectedPharmacy.acceptBackOrders) {
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.stock <= 0) {
          this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
        }
      } else {
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.error('The selected product is out of stock.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
      }
      if (this.orderedItems.length > 0) {
        const check = this.orderedItems.some((d: any) => {
          return d.product_name === this.selectedProduct.productName;
        });

        if (check) {
          this.toastr.info('Medicine is already in the list', 'Info', {
            positionClass: 'toast-top-center'
          });
          modal.dismiss('Cross-Click');
          return;
        }
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
        modal.dismiss('Cross-Click');
      } else {
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
        modal.dismiss('Cross-Click');
      }
    } else {
      this.selectedProduct = event;
      if (this.selectedPharmacy.acceptBackOrders) {
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.stock <= 0) {
          this.toastr.info('This product is currently on back order - there may be additional delays while we order stock in for you', 'Info', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
        }
      } else {
        if (this.selectedProduct.stock <= 0) {
          this.toastr.clear();
          this.toastr.error('The selected product is out of stock.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (this.selectedProduct.isDiscontinued) {
          this.toastr.clear();
          this.toastr.error('Sorry for the inconvenience, but this product is no longer available at our pharmacy.', 'Error', {
            positionClass: 'toast-top-center',
          });
          modal.dismiss('Cross-Click');
          return;
        }
      }
      if (this.Medicinerows.length > 0) {
        const check = this.Medicinerows.some((d: any) => {
          return d._id === this.selectedProduct._id;
        });

        const duplicates = this.orderedItems.some((d: any) => {
          return d.product_name === this.selectedProduct.productName;
        });

        if (check && duplicates) {
          this.toastr.info('Medicine is already in the list', 'Info', {
            positionClass: 'toast-top-center'
          });
          modal.dismiss('Cross-Click');
          return;
        }
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );

      } else {
        this.Medicinerows.push({
          ...this.selectedProduct,
          ['isBackOrder']: this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders,
          ['quantity']: 1,
        });
        this.orderedItems.push(
          {
            'token': '',
            'product_brand_name': this.selectedProduct.brandName,
            'product_name': this.selectedProduct.productName,
            'medication_strength': '',
            'quantity': 1,
            'price': this.selectedProduct.productCost,
            'repeats': 0,
            'instruction': '',
            'note': '',
            'globalProductId': this.selectedProduct.globalProductId,
            'eRx': false,
            'scriptData': {
              'uploadedScript': '',
              'type': ''
            },
            'isBackOrder': this.selectedProduct.stock <= 0 && this.selectedPharmacy.acceptBackOrders
          }
        );
      }
      modal.dismiss('Cross-Click');
    }
  }


  increase(Item, step = 'step-2') {
    if (step === 'step-2') {
      this.Medicinerows.map((item) => {
        if (Item._id === item._id) {
          item.quantity = item.quantity + 1;
          if (item.quantity > Item.stock) {
            this.toastr.clear();
            this.toastr.info(`Please select a quantity within the available stock range, as the requested quantity exceeds the available stock of ${Item.stock}`,
              'Info!', {
                positionClass: 'toast-top-center'
              });
            item.quantity = 1;
            return;
          }
        }
      });

      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.productName === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.stock) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
    } else {
      this.Medicinerows.map((item) => {
        if (Item.product_name === item.productName) {
          item.quantity = item.quantity + 1;
          if (item.quantity > Item.stock) {
            this.toastr.clear();
            this.toastr.info(`Please select a quantity within the available stock range, as the requested quantity exceeds the available stock of ${Item.stock}`,
              'Info!', {
                positionClass: 'toast-top-center'
              });
            item.quantity = 1;
            return;
          }
        }
      });
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            item.quantity = item.quantity + 1;
            if (item.quantity > Item.stock) {
              item.quantity = 1;
              return;
            }
          }
        })
      }
    }
  }

  openConfimationModal(confirm, item) {
    // , medicine, index, script
    // this.selectedMedicince = medicine;
    // this.selectedIndex = index;
    // this.selectedScript = script;
    this.selectedMedicince = item;
    this.modalService.open(confirm, {size: 'md', backdrop: 'static'});
  }


  decrease(Item, step = 'step-2') {
    if (step === 'step-2') {
      this.Medicinerows.map((item) => {
        if (Item._id === item._id) {
          if (item.quantity > 1) {
            item.quantity = item.quantity - 1;
          }

        }
      });
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.productName === item.product_name) {
            if (item.quantity > 1) {
              item.quantity = item.quantity - 1;
            }
          }
        })
      }
    } else {
      this.Medicinerows.map((item) => {
        if (Item.product_name === item.productName) {
          if (item.quantity > 1) {
            item.quantity = item.quantity - 1;
          }
        }
      });

      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            if (item.quantity > 1) {
              item.quantity = item.quantity - 1;
            }
          }
        })
      }
    }
  }


  openConfimation(confirm, item, step = 'step-2') {
    if (step === 'step-2') {
      this.selectedMedicince = item;
    } else {
      this.selectedMedicince = {...this.selectedMedicince, ['productName']: item.product_name};
    }
    this.modalService.open(confirm, {size: 'md', backdrop: 'static'});
  }


  remove(modal) {
    // if (this.check) {
    //   const remove = this.orderedItems.filter((ele) => {
    //     return ele.token !== this.selectedMedicince.token;
    //   });
    //   this.orderedItems = remove;
    // }
    //
    // if (this.rows[this.selectedIndex].data.length === 1) {
    //   const res = this.rows[this.selectedIndex]?.data.filter((med) => {
    //     return med.product_name !== this.selectedMedicince.product_name;
    //   });
    //   this.rows[this.selectedIndex].data = res;
    //   const removeObj = this.rows?.filter((ele) => {
    //     return ele.token !== this.selectedScript.token;
    //   })
    //   this.rows = removeObj;
    // } else {
    //   const res = this.rows[this.selectedIndex]?.data?.filter((med) => {
    //     return med.product_name !== this.selectedMedicince.product_name;
    //   });
    //   this.rows[this.selectedIndex].data = res;
    // }
    // this.modalService.dismissAll();
    const res = this.orderedItems.filter((ele) => {
      return ele.product_name !== this.selectedMedicince.productName;
    });
    this.orderedItems = res;
    const remove = this.Medicinerows.filter((ele) => {
      return ele.productName !== this.selectedMedicince.productName;
    });
    this.Medicinerows = remove;
    modal.dismiss('Cross-Click')
  }


  uploadLicence(file: any, type: string) {
    if (this.type === 'DriversLicense') {
      if (type === 'licenseFront') {
        this.frontFile = file;
      } else {
        this.backFile = file;
      }
    } else {
      this.file = file;
    }
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }


  SelectedPharmacy(pharmacy, index, content) {
    this.showMessageBlock = true;
    this.type = '';
    this.Medicinerows = [];
    this.orderedItems = [];
    this.showUpload = false;
    this.selectedPharmacy = pharmacy;
    this.userAddress.filter((address) => {
      if (address.verifiedPharmacies.length > 0) {
        address.verifiedPharmacies.map((data) => {
          if (data === this.selectedPharmacy._id) {
            address.icon = 'ft-check text-success'
          } else {
            address.icon = 'ft-x-circle text-danger'
          }
        })
      } else {
        address.icon = 'ft-x-circle text-danger'
      }
    });
    this.index = index;
    this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, this.selectedPharmacy._id).then(resp => {
      if (resp.response === 'No user found with the given criteria') {
        this.pharmacyUser = resp;
        this.userStatus = 'unverified'
        this.openModal(content, 'md');
      } else {
        this.pharmacyUser = resp;
        this.frontLicense = '';
        this.backLicense = '';
        this.medicareCard = '';
        this.pensionCard = '';
        this.userStatus = this.pharmacyUser.Status;
        if (this.userStatus === 'unverified') {
          this.openModal(content, 'md');
        }
      }
    });
    this.authService.loadProductsList_of_pharmacy(this.userData, this.selectedPharmacy._id, this.platform).then((resp: any) => {
      if (resp.results.length === 0) {
        this.productList = [];
      } else {
        if (this.selectedPharmacy.acceptBackOrders) {
          this.productList = resp.results;
        } else {
          // this.productList = resp.results.filter(prod => prod.stock > 0);
          this.productList = resp.results;
        }
      }
    }).catch(err => {
    });

  }

  OnChangeType(type: any) {
    this.trackFileUpload = false;
    this.type = type.target.value;
    this.file = undefined;
  }

  nextStep(event, wizard: WizardComponent) {
    this.wizard = wizard
    if (this.Medicinerows.length === 0) {
      this.toastr.warning('Kindly choose the medication', 'Warning!', {
        positionClass: 'toast-top-center'
      });
      return;
    } else if (this.orderedItems.length === 0) {
      this.toastr.warning('Kindly choose the medication by ticking the checkbox.', 'Warning!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const check = this.orderedItems.some((data) => {
      return data.token === '';
    });
    if (check) {
      this.toastr.error('Please fill the token for the selected Products', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      this.wizard.goToNextStep();
    }
  }

  createPharmacyUser() {
    this.showMessageBlock = false
    this.showUpload = true;
    if (this.pharmacyUser.response === 'No user found with the given criteria') {
      const body = {
        userId: this.userData.auth.data._id,
        pharmacyId: this.selectedPharmacy._id
      }
      this.authService.createPharmacy_user(this.userData, body).then((resp) => {
        if (resp.status === 400) {
          if (resp.error.pharmacyUser.Status === 'unverified') {
            this.pharmacyUser = resp.error.pharmacyUser;
          }
        } else {
          this.pharmacyUser = resp.pharmacyUser_created;
        }
      }).catch(err => {
        this.toastr.error('Failed to create Pharmacy User', 'Error!', {
          positionClass: 'toast-top-center'
        });
      })
    }
  }

  closeModal(content6) {
    if (this.fileCount !== 2) {
      this.toastr.error('Upload both front side and back side of the license', 'Error!', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.modalService.dismissAll(content6);
  }


  OnChangeAddress(event: any, content) {
    this.type = '';
    this.selectedAddress = event;
    if (this.selectedAddress === undefined) {
    } else {
      if (this.selectedAddress.verifiedPharmacies.length === 0) {
        if (this.selectedAddress.icon === 'ft-x-circle text-danger') {
          this.userStatus = 'unverified';
          this.showMessageBlock = true;
          this.openModal(content, 'md');
        }
        const body = [{
          propName: 'user_address', value: this.selectedAddress._id
        }];
        this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
        });
      } else {
        if (!this.selectedAddress.verifiedPharmacies.includes(this.selectedPharmacy._id)) {
          this.userStatus = 'unverified';
          this.showMessageBlock = true;
          this.openModal(content, 'md');
          const body = [{
            propName: 'Status', value: 'unverified'
          }];
          this.authService.update_pharmacy_user(this.pharmacyUser, this.userData, body).then((resp) => {
          });
        } else {
          this.userStatus = 'verified';
        }
      }
    }
  }

  showGlobalProd(globalProductId: any) {
    this.authService.fetch_particularglobalProdcut(this.userData, globalProductId, this.platform).then((response: any) => {
      const queryParams = {data: JSON.stringify(globalProductId)};
      const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
      window.open(urlWithQueryParams, '_blank');
    });
  }

  submitAddress(content) {
    this.submitted = true;
    if (this.addressForm.invalid) {
      return;
    }
    const body = {
      userId: this.selectedCustomer._id,
      addressType: this.addressForm.value.addressType,
      address: this.addressForm.value.address,
      suburb: this.addressForm.value.suburb,
      state: this.addressForm.value.state,
      postcode: parseInt(this.addressForm.value.postcode)
    }

    this.authService.create_Address(body).then((resps: any) => {
      this.toastr.info('Address created successfully', 'Updated', {
        positionClass: 'toast-top-center'
      });
      this.userAddress.push(resps);
      this.authService.fetch_user_address(this.userData).then((resp) => {
        this.userAddress = resp;
        this.userAddress.map((address) => {
          address.completeAddress = address.address + ' ' + address.suburb + ' ' + address.state + ' ' + address.postcode;
          address.icon = 'ft-x-circle text-danger';
        });
        this.userAddress.filter((address) => {
          if (address.verifiedPharmacies.length > 0) {
            address.verifiedPharmacies.map((data) => {
              if (data === this.selectedPharmacy._id) {
                address.icon = 'ft-check text-success'
              } else {
                address.icon = 'ft-x-circle text-danger'
              }
            })
          } else {
            address.icon = 'ft-x-circle text-danger'
          }
        });
      });
      this.modalService.dismissAll(content);
      this.addressForm.reset();
    }).catch(err => {
      this.toastr.error('Failed to create the address', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }


  confirm(deliverIns: string, content) {
    if (this.pickup) {
      this.modalService.dismissAll(content);
    } else {
      this.delivery_Instruction = deliverIns;
      this.modalService.dismissAll(content);
    }

  }

  goNext() {
    if (this.type === '') {
      this.toastr.error('Please upload document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (this.file === undefined && this.type !== 'DriversLicense') {
      this.toastr.error('Please upload' + this.type + ' document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if ((this.frontFile === undefined || this.backFile === undefined) && this.type === 'DriversLicense') {
      this.toastr.error('Please upload ' + this.type + ' document', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (this.type === 'DriversLicense') {
      this.trackFileUpload = true;
      this.spinner.show(undefined,
        {
          zIndex: 99999,
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true,
        });
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.frontFile.target.files[0], 'licenseFront_temp_encrypted').then((resp: any) => {
        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        }
        if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          this.frontLicense = atob(resp.licenseFront_temp_encrypted);
          this.userStatus = 'Verified'
          this.pensionCard = '';
          this.medicareCard = '';
          this.spinner.hide();
          this.trackFileUpload = false;
        }
      }).catch(err => {
        this.trackFileUpload = false;
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      });
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.backFile.target.files[0], 'licenseBack_temp_encrypted').then((resp: any) => {

        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          this.frontLicense = atob(resp.licenseFront_temp_encrypted);
          this.toastr.success('License Uploaded Successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.userStatus = 'Verified'
          this.pensionCard = '';
          this.medicareCard = '';
          this.spinner.hide();
          this.trackFileUpload = false;
          this.modalService.dismissAll();
        }
      }).catch(err => {
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      });
    } else {
      this.trackFileUpload = true;
      this.spinner.show(undefined,
        {
          zIndex: 99999,
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      this.fileCount += 1;
      this.authService.upload_licence(this.userData, this.pharmacyUser, this.file.target.files[0], this.type).then((resp: any) => {

        if (resp.status === 400) {
          this.trackFileUpload = false;
          this.toastr.error(resp.error.error, 'Error', {
            positionClass: 'toast-top-center'
          });
        } else if (resp.status === 500) {
          this.trackFileUpload = false;
          this.toastr.error('Upload only png and jpeg files', 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
        } else {
          if (this.type === 'pensionCardNumber') {
            this.pensionCard = atob(resp.pensionCardNumber);
            this.frontLicense = '';
            this.backLicense = '';
          } else {
            this.frontLicense = '';
            this.backLicense = '';
            this.medicareCard = atob(resp.medicareCardNumber);
          }
          this.toastr.success('License Uploaded Successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.userStatus = 'Verified'
          this.showMessageBlock = true;
          this.showUpload = false;
          this.spinner.hide();
          this.modalService.dismissAll();
          this.trackFileUpload = false;
        }
      }).catch(err => {
        this.toastr.error('Failed to upload the license', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.trackFileUpload = false;
        this.spinner.hide();
      });
    }


  }

  closeMe() {
    if (this.fileCount !== 2) {
      this.toastr.error('Please upload your license', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.modalService.dismissAll();
    this.fileCount = 0;
  }

  selectMe(me: string) {
    this.choiceMade = me;
    if (me === 'Carrier') {
      this.showAuthTPAForm = !this.showAuthTPAForm;
    } else {
      this.showAuthTPAForm = false;
    }
  }

  fillData(fullName: string, relType: string, modal: any) {
    if (fullName === '' || relType === '') {
      this.toastr.error('Please fill the Details', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.myorderObj = {...this.myorderObj, ['relationship_with_customer']: relType};
    this.myorderObj = {...this.myorderObj, ['pickup_name']: fullName};
    modal.dismiss('Cross-Click');
  }

  closeMod(modal) {
    modal.dismiss('Cross-Click');
  }

  gotoNext() {
    if (this.userStatus === 'unverified') {
      this.toastr.error('Please upload document', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.selectedPharmacy = null;
      this.index = -1;
      return;
    } else {
      this.wizard.goToNextStep();
    }
  }

  validateText(token) {
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(token.value)) {
      this.validation = true;
    } else {
      this.validation = false;
    }
  }

  OnChangeUploadType(event) {
    this.uploadType = event.target.value;
  }

  paste(url, modal) {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (url === '') {
      this.toastr.error('Please Enter ScriptImage URL', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(url) ? true : false;
    if (!pattern.test(url)) {
      this.toastr.error('URL is invalid', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    // const a = document.createElement('a');
    // a.href = url;
    // a.target = '_blank';
    // a.click();

    if (this.scriptImage_for === 'for_each_medicine') {
      this.orderedItems[this.selectedMedicine_Index].scriptData.uploadedScript = url;
      this.orderedItems[this.selectedMedicine_Index].scriptData.type = this.uploadType;
    } else {
      this.scriptImage = url;
    }
    this.toastr.success('URL inserted', 'Success', {
      positionClass: 'toast-top-center'
    });
    modal.dismiss('Cross-Click');
  }

  OnChangeState(value: string, postcode) {
    this.selectedPharmacyState = null;
    this.selectedState = value;
    postcode.value = '';
    this.post_code_pharmacyList = [];
    this.authService.fetch_pharmacy_postcode(this.userData, this.platform, 'state', value).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.info('No Pharmacy Found', 'Info!', {
          positionClass: 'toast-top-center'
        });
        this.post_code_pharmacyList = [];
      } else {
        this.post_code_pharmacyList = resp;
      }
    }).catch(err => {
    });
  }

  OnChangeToken(Item, data, type) {
    if (type === 'token') {
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.productName === item.product_name) {
            item.token = data.value;
          }
        })
      }
    }
  }

  OnChangeToken1(Item, data, type) {
    if (type === 'token') {
      if (this.orderedItems.length > 0) {
        this.orderedItems.map((item) => {
          if (Item.product_name === item.product_name) {
            item.token = data.value;
          }
        })
        this.Medicinerows.map((item) => {
          if (Item.product_name === item.productName) {
            item.token = data.value;
          }
        })
      }
    }
  }

  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

}
