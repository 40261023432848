<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Scripts On File</h4>
          <div class="row mt-2">
            <div class="col-md-3 col-12">
              <select class="form-control" (change)="OnSearchBy($event)">
                <option *ngFor="let ele of searchBy;" [value]="ele.value">{{ ele.title }}</option>
              </select>
            </div>
            <div class="col-md-3 col-12">
              <fieldset>
                <div class="input-group" *ngIf="search.title === 'Name'">
                  <input type="text" #Nametextbox class="form-control" placeholder="Enter Name"
                         (keyup.backspace)="loadOrders()"
                         (keyup.enter)="searchOrder(Nametextbox.value)"
                  >
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(Nametextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>

                <div class="input-group" *ngIf="search.title === 'Phone Number'">
                  <input type="text" #Phonetextbox class="form-control" (keyup.enter)="searchOrder(Phonetextbox.value)"
                         maxlength="10" (keyup.backspace)="loadOrders()"
                         placeholder="Enter Phone Number">
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(Phonetextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>
                <div class="input-group" *ngIf="search.title === 'Email'">
                  <input type="text" #Emailtextbox class="form-control" (keyup.enter)="searchOrder(Emailtextbox.value)"
                         (keyup.backspace)="loadOrders()"
                         placeholder="Enter Email">
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(Emailtextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>
                <div class="input-group" *ngIf="search.title === 'Order No'">
                  <input type="text" #OrderNotextbox class="form-control" (keyup.backspace)="loadOrders()"
                         (keyup.enter)="searchOrder(OrderNotextbox.value)" maxlength="6" placeholder="Enter Order No">
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(OrderNotextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-6 col-12">
              <div class="input-group float-right cal-width">
                <div class="input-group-prepend dp-hidden">
                  <input
                    name="datepicker"
                    class="form-control dp-hidden"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [autoClose]="'outside'"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="1"
                    [dayTemplate]="t"
                    outsideDays="hidden"
                    [startDate]="fromDate!"
                    tabindex="-1"
                  />
                  <ng-template #t let-date let-focused="focused">
					<span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
						{{ date.day }}
					</span>
                  </ng-template>
                </div>
                <input
                  #dpFromDate
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dpFromDate"
                  [value]="from + ' - ' + to"
                  (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                />
                <div class="input-group-append">
                  <div class="input-group-text" (click)="datepicker.toggle()">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">

          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn primary-btn badge float-right mr-3" [disabled]="this.multipleOrders.length === 0"
                    (click)="submitOrders()">Place Order
            </button>
          </div>
        </div>
        <div class="card-content mt-1">
          <h3 class="card-title text-center" *ngIf="rows.length === 0">{{ message }}</h3>
          <div class="table-responsive">
            <table class="table w-100">
              <thead>
              <tr>
                <th>Order No</th>
                <th>Customer Name</th>
                <th>Phone Number</th>
                <th>Token</th>
                <th>Repeats</th>
                <th>Next Dispense Date</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let medi of rows">
                <td>{{ medi.orderNo }}</td>
                <td>{{ medi.name }}</td>
                <td>{{ medi.mobile }}</td>
                <td class="text-uppercase">{{ medi.orderedItem[0].token }}</td>
                <td>{{ medi.orderedItem[0].repeats === '' ? 0 : medi.orderedItem[0].repeats }}</td>
                <td>{{ medi.orderDate | date:'mediumDate' }}</td>
                <td>
                  <div class="d-flex">
                    <div class="checkbox mt-1" *ngIf="medi.isCurrentDate">
                      <input type="checkbox" id="{{medi._id}}" (change)="OnChange(medi,$event)">
                      <label for="{{medi._id}}"></label>
                    </div>
                    <button class="badge secondary-btn btn" (click)="open(viewOrder,medi)">View</button>
                    <button class="badge primary-btn btn text-white ml-1"
                            *ngIf="!medi.isCurrentDate && !medi.isAfterDate"
                            (click)="openModal1(confirmReOrderModal1,medi,'Do you have approval to dispense the script','md')">
                      Advanced Request
                    </button>
                    <button class="badge bg-light-info btn ml-1"
                            *ngIf="medi.isAfterDate && !medi.isCurrentDate"
                            (click)="openModal(Modal1,medi)">Order
                    </button>
                    <button class="badge primary-btn btn ml-1"
                            *ngIf="medi.isCurrentDate"
                            (click)="openModal(Modal1,medi)">Re-Order
                    </button>
                    <span class="ft-trash cursor-pointer danger ml-1 mt-1"
                          (click)="open(confirmReOrderModal,medi,'large-modal')"></span>
                  </div>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <div class="card-footer">
            <ngb-pagination class="float-right" [collectionSize]="this.maxsize * 10" (pageChange)="pagination($event)"
                            [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #viewOrder let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Order - #{{ selectedOrder.orderNo }}</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-dialog-scrollable">
    <div class="modal-body">
      <h5 class="font-weight-bold font-italic">User Information</h5>
      <hr>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xl-9 col-12">
          <div style="overflow-y: scroll !important;overflow-x: hidden !important;">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xl-8 col-12">
                <div class="row">
                  <div class="col-md-6 col-lg-3 col-sm-12 col-xl-4 col-12">
                    <label class="font-weight-bold">Name</label>
                    <p>{{ selectedOrder?.name }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Mobile Number</label>
                    <p>{{ selectedOrder?.mobile }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Email</label>
                    <p style="word-break: break-word">{{ selectedOrder?.email }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Order By</label><br>
                    <p>{{ selectedOrder?.orderedBy }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Ordered Date and Time</label><br>
                    <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12"
                       *ngIf="selectedOrder?.status === 'cancelled' && (selectedOrder?.rejectReason !== '' || selectedOrder?.cancellation_reason.reason !== '')">
                    <label class="font-weight-bold">Cancellation Reason</label><br>
                    <p *ngIf="selectedOrder?.rejectReason !== ''">{{ selectedOrder?.rejectReason }}</p>
                    <p
                      *ngIf="selectedOrder?.cancellation_reason.reason !== ''">{{ selectedOrder?.cancellation_reason.reason }}</p>
                  </div>

                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Delivery Type</label><br>
                    <span class="badge bg-light-primary">{{ selectedOrder?.pickup ? 'Pickup' : 'Delivery' }}</span>
                    <span class="ml-2">{{ !selectedOrder?.pickup ? selectedOrder?.deliveryInstruction : '' }}</span>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                    <label class="font-weight-bold">Status</label><br>
                    <span
                      class="badge bg-light-success btn text-capitalize" [ngClass]="{'bg-light-success btn' : pharmacyUser?.pharmacyUserStatus === 'verified','bg-light-dark btn' : pharmacyUser?.pharmacyUserStatus === 'blacklisted',
                            'bg-light-danger btn': pharmacyUser?.pharmacyUserStatus === 'unverified'}">
                              {{ pharmacyUser?.pharmacyUserStatus }}</span>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                    <label class="font-weight-bold">Order Status</label><br>
                    <span
                      *ngIf="selectedOrder.partialShipmentStatus !== 'shipped' && selectedOrder.partialShipmentStatus !== 'completed'"
                      class="text-capitalize">{{ selectedOrder.status }}</span>
                    <span
                      *ngIf="selectedOrder.partialShipmentStatus === 'shipped' || selectedOrder.partialShipmentStatus === 'completed'"
                      class="text-capitalize">{{ selectedOrder.partialShipmentStatus }}
            </span>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                    <label class="font-weight-bold">Address</label>
                    <p>
                      <b>{{ selectedOrder?.addressType }}</b> {{ selectedOrder?.address + ' ' + selectedOrder?.suburb + ' ' + selectedOrder?.state + ' ' + selectedOrder?.postcode }}
                    </p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                       *ngIf="selectedOrder.pickup && selectedOrder?.pickup_name !== ''">
                    <label class="font-weight-bold">Pickup Name</label><br>
                    <p>{{ selectedOrder?.pickup_name }} - {{ selectedOrder?.relationship_with_customer }}</p>
                  </div>
                  <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                       *ngIf="this.selectedOrder.uploadedScript!== '' && this.selectedOrder.uploadedScript!== ' '">
                    <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                      <i
                        *ngIf="showUrl"
                        class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                        (click)="downloadScript(this.selectedOrder?.uploadedScript)"></i>
                    </label>
                    <br>
                    <div *ngIf="showUrl">
                      <img class="cursor-pointer" src="{{this.selectedOrder?.uploadedScript}}" alt="" width="60"
                           height="40"
                           (click)="isCollapsed = !isCollapsed"
                           [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                      <div id="collapseExample" [ngbCollapse]="isCollapsed">
                        <img src="{{this.selectedOrder?.uploadedScript}}" alt="" width="400" height="300">
                      </div>
                    </div>
                    <p *ngIf="!showUrl">{{ this.selectedOrder?.uploadedScript }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-xl-4 col-12">
                <div class="chat-container d-none d-sm-none d-xl-block d-md-none d-lg-none">
                  <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand" [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'" class="cursor-pointer float-right"></i></div>
                  <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                    <div class="messages">
                      <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                        <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                           *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                           (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                        {{ note.fallowUp_note }}
                        <p
                          class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                          {{ note.note_addedBy_userName }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="input-area">
                    <!--                  <input type="text"  id="message-input" #messageBox placeholder="Type a note..." >-->
                    <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(messageBox1)" #messageBox1
                              placeholder="Type a note..."></textarea>
                    <button class="btn btn-success ml-2" style="height: 40px;" [disabled]="disablebtn"
                            (click)="onEnterKey(messageBox1)">
                      <i class="ft-send mr-1"></i>
                    </button>
                  </div>
                </div>
                <div class="d-xl-none">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                      <div class="chat-container">
                        <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand" [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'" class="cursor-pointer float-right"></i></div>
                        <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                          <div class="messages">
                            <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                              <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                                 *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                                 (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                              {{ note.fallowUp_note }}
                              <p
                                class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                                {{ note.note_addedBy_userName }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="input-area">
                          <!--                  <input type="text"  id="message-input" #messageBox placeholder="Type a note..." >-->
                          <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(messageBox)" #messageBox
                                    placeholder="Type a note..."></textarea>
                          <button class="btn btn-success ml-2" style="height: 40px;" [disabled]="disablebtn"
                                  (click)="onEnterKey(messageBox)">
                            <i class="ft-send mr-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                      <div class="chat-container">
                        <div class="chat-header bg-secondary">Pharmacy Notes</div>
                        <div class="chat-box">
                          <div class="messages">
                            <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                              <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                                 *ngIf="pharmacyNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                                 (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                              {{ note.fallowUp_note }}
                              <p
                                class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                                {{ note.note_addedBy_userName }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="input-area">
                <textarea cols="28" rows="1" (keyup.enter)="onEnterKey(pharm_notes1,'pharmacyNote')" #pharm_notes1
                          placeholder="Type a note..."></textarea>
                          <button class="btn bg-success text-white ml-2" style="height: 40px;" [disabled]="disablebtn"
                                  (click)="onEnterKey(pharm_notes1,'pharmacyNote')">
                            <i class="ft-send mr-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <br>
            <h5 class="font-weight-bold font-italic">User Script</h5>
            <div class="table-responsive">
              <table class="table items-table">
                <thead>
                <tr>
                  <!--              <th class="text-left">QR Code</th>-->
                  <th>Token Number</th>
                  <th>Script Info</th>
                  <th>Brand Name</th>
                  <th>Product Name</th>
                  <!--              <th>Medication Strength</th>-->
                  <th>Quantity</th>
                  <th>Partial Shipped Quantity</th>
                  <th>Remaining Quantity</th>
                  <th>Price</th>
                  <!--              <th>Repeats</th>-->
                  <th>Instruction</th>
                  <th>Note</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of this.selectedOrder?.orderedItem;let i = index;">
                  <!--              <td class="py-0">-->
                  <!--                <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
                  <!--              </td>-->
                  <td>
                    <div class="d-flex">
                      <input type="text" class="text-uppercase" name="token" #newToken id="item{{i}}"
                             [value]="item.token">
                      <span class="ft-check-square text-success cursor-pointer ml-2 mt-1"
                            [disabled]="valid"
                            (click)="updateToken(newToken.value,i,modal)">
                  </span>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="d-flex">
                      <img *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                           class="cursor-pointer"
                           src="{{ item?.scriptData?.uploadedScript }}"
                           alt="" width="60" height="40"
                           (click)="viewScript_image(item?.scriptData?.uploadedScript,view_script_image)">
                      <span ngbTooltip="Download ScriptImage"
                            *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                            class="ft-download-cloud cursor-pointer ml-2"
                            (click)="downloadScript(item?.scriptData?.uploadedScript)"></span>
                    </div>
                    <a *ngIf="item?.scriptData?.type === 'url'" target="_blank"
                       href="{{item?.scriptData?.uploadedScript}}" class="text-primary">
                <span style="text-decoration: underline" ngbTooltip="{{item?.scriptData?.uploadedScript}}"
                      placement="top">Click Here</span>
                    </a>
                  </td>
                  <td>{{ item?.product_brand_name }}</td>
                  <td class="cursor-pointer"
                      (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}
                  </td>
                  <!--              <td>{{item?.medication_strength}}</td>-->
                  <td>{{ item?.quantity }}</td>
                  <td>{{ item?.shippedQuantity }}</td>
                  <td>{{ item?.remainingQuantity }}</td>
                  <td>${{ item?.price }}</td>
                  <!--              <td>{{item?.repeats}}</td>-->
                  <td>{{ item?.instruction }}</td>
                  <td>{{ item?.note }}</td>
                </tr>

                <tr>
                  <td colspan="5" class="text-right"><b>Item Total</b></td>
                  <td>${{ selectedOrder?.itemsCost | number:'1.2-2' }}</td>
                </tr>
                <tr *ngIf="!selectedOrder?.pickup">
                  <td colspan="5" class="text-right"><b>Delivery Charge:</b></td>
                  <td>
                    ${{ selectedOrder?.pharmacyId.freightCharge }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="text-right"><b>Total Amount:</b></td>
                  <td><p class="font-weight-bold font-medium-1">${{ selectedOrder?.totalAmount | number:'1.2-2' }}<br>
                    <span
                      class="font-weight-normal font-small-3">*Includes GST of ${{ selectedOrder.taxAmount | number:'1.2-2' }}</span>
                  </p></td>
                </tr>
                </tbody>
              </table>
            </div>
            <br>
            <h5 class="font-weight-bold font-italic">Pharmacy Details</h5>
            <hr>
            <div class="row">
              <div class="col-6">
                <div class="col-12">
                  <label class="font-weight-bold">Pharmacy Name</label>
                  <p>{{ selectedOrder?.pharmacyId?.pharmacy_name }}</p>
                </div>
                <div class="col-12 mt-2">
                  <label class="font-weight-bold">Mobile Number</label>
                  <p>{{ selectedOrder?.pharmacyId?.phoneNumber }}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="col-12">
                  <label class="font-weight-bold">Email</label>
                  <p>{{ selectedOrder?.pharmacyId?.email }}</p>
                </div>
                <div class="col-12">
                  <label class="font-weight-bold">Pharmacy Address</label>
                  <p>{{
                      selectedOrder?.pharmacyId?.address + ' ' + selectedOrder?.pharmacyId?.suburb + ' ' + selectedOrder?.pharmacyId?.state +
                      ' ' + selectedOrder?.pharmacyId?.postcode
                    }}</p>
                </div>
              </div>
            </div>
            <!--Additional Cost -->
            <br>
            <h5 class="font-weight-bold font-italic" *ngIf="selectedOrder?.additionalCosts.length > 0">Additional
              Costs</h5>
            <div class="row" *ngIf="selectedOrder?.additionalCosts.length > 0">
              <div class="col-md-12 col-12">
                <div class="table-responsive">
                  <table class="table m-0">
                    <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Payment Status</th>
                      <th>Amount Added By</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let extracost of selectedOrder?.additionalCosts;let i = index;">
                      <td>{{ extracost.amount }}</td>
                      <td>{{ extracost.description }}</td>
                      <td>{{ extracost.paid ? 'Paid' : 'Un-Paid' }}</td>
                      <td>{{ extracost.amount_addedBy_userName }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!--      Payment Details-->
            <br>
            <div
              *ngIf="selectedOrder.status === 'paymentProcess' || selectedOrder.status === 'pendingShipment'  || selectedOrder.status === 'dispense' || selectedOrder.status === 'shipped' || selectedOrder.status === 'completed'">
              <h5 class="font-weight-bold font-italic">Payment Details
              </h5>
              <hr>
              <div class="table-responsive">
                <table class="table table-bordered m-0">
                  <thead class="table-header">
                  <tr>
                    <th>Order No</th>
                    <th>Order Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Number</th>
                    <th>Date & Time</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let payment of selectedOrder.orderPaymentDetails">
                    <td>{{ payment?.paymentAdditionalDetails[0]?.orderNumber }}</td>
                    <td>
                      <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                    </td>
                    <td>{{ payment?.orderPayment_method }}</td>
                    <td>{{ payment?.orderPayment_reference }}</td>
                    <td>{{ payment?.orderPayment_initiated_date | date:'mediumDate' }} {{ payment?.orderPayment_initiated_date | date:'shortTime' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--      Delivery Details-->
            <br>
            <div *ngIf="selectedOrder?.shippingDetails.length > 0">
              <h5 class="font-weight-bold font-italic">Delivery Details</h5>
              <hr>
              <div class="table-responsive">
                <table class="table table-bordered m-0">
                  <thead class="table-header">
                  <tr>
                    <th>Order No</th>
                    <th>Order Date</th>
                    <th>Carer Number</th>
                    <th>Connote Number</th>
                    <th>Delivery Date & Time</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let ele of selectedOrder?.shippingDetails">
                    <td>{{ ele?.itemShippedDetails[0]?.orderNumber }}</td>
                    <td>
                      <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                    </td>
                    <td>{{ ele?.freightCarrier }}</td>
                    <td>{{ ele?.trackingNo }}</td>
                    <td>{{ ele?.deliveredDate  | date:'mediumDate' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-3 col-12">
          <div class="chat-container mx-1 my-1 d-none d-sm-none d-xl-block d-md-none d-lg-none">
            <div class="chat-header bg-secondary">Pharmacy Notes</div>
            <div class="pharmacy-chat-box">
              <div class="messages">
                <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                  <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                     *ngIf="pharmacyNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                     (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                  {{ note.fallowUp_note }}
                  <p
                    class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                    {{ note.note_addedBy_userName }}
                  </p>
                </div>
              </div>
            </div>
            <div class="input-area">
            <textarea cols="31" rows="1" (keyup.enter)="sendNote(pharmacy_Notes,'pharmacyNote')" #pharmacy_Notes
                      placeholder="Type a note..."></textarea>
              <button class="btn bg-success text-white ml-2" style="height: 40px;" [disabled]="disablebtn"
                      (click)="sendNote(pharmacy_Notes,'pharmacyNote')">
                <i class="ft-send mr-1"></i>
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</ng-template>


<ng-template #Modal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Repeat Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <table class="table m-0">
        <thead>
        <tr>
          <th>Repeat Token</th>
          <th>Number of Repeats</th>
          <th>Repeat Interval</th>
          <th>Action</th>
        </tr>
        </thead>
        <tr *ngFor="let order of selectedOrder.orderedItem;let i = index;">
          <td>
            <input type="text" class="form-control square text-uppercase" name="token" #newToken id="item{{i}}"
                   [value]="order.token.trim()" (keyup)="validateToken(newToken.value)">
            <p *ngIf="valid" class="text-danger">Invalid Token</p>
          </td>
          <td>
            <input type="number" class="form-control square" id="number{{i}}" min="0"
                   value="{{order.repeats}}" name="token"
                   #number_of_Repeats>
          </td>
          <td>
            <input type="number" class="form-control  square" id="duration{{i}}" #selectDuration min="0"
                   [value]="order.duration">
          </td>
          <td>

            <!--            <button class="btn primary-color"-->
            <!--                    (click)="upDatefillDetails(selectDuration.value,newToken.value,number_of_Repeats.value,this.selectedOrder,i)">-->
            <!--              Edit-->
            <!--            </button>-->
            <button class="btn secondary-btn ml-2"
                    [disabled]="valid"
                    (click)="fillDetails(selectDuration.value,newToken.value,number_of_Repeats.value,this.selectedOrder,i,modal)">
             Order
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>


<ng-template #Modal2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Repeat Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <div class="row" *ngFor="let order of selectedOrder.orderedItem;let i = index;">
        <div class="col-md-12 col-12">
          <label>Repeat Token</label>
          <div class="d-flex">
            <input type="text" class="form-control square text-uppercase size" name="token" #newToken id="item{{i}}"
                   [value]="order.token.trim()"
                   (keypress)="validateToken(newToken.value)">
            <button class="btn badge primary-btn ml-2"
                    (click)="advanceRequest(newToken.value,this.selectedOrder,i,modal)">Order
            </button>
          </div>
          <p *ngIf="valid" class="text-danger">Invalid Token</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <label class="font-weight-bold">Customer Notes</label>
          <div class="d-flex">
                <textarea rows="3" cols="3" #noteArea
                          class="form-control square"></textarea>
            <button class="btn-submit" [disabled]="disablebtn" (click)="sendNote(noteArea,'Customer')"><i
              class="ft-check-square text-success"></i></button>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #confirmReOrderModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to delete this order?</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="deleteOrder(modal)">Yes</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #confirmReOrderModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">{{ this.message }}</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger"
                (click)="openModal1(Modal2,this.selectedOrder,'Do you have approval to dispense the script','md')">Yes
        </button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>
