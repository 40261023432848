import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ContentPagesRoutingModule} from './content-pages-routing.module';
import {ErrorPageComponent} from './error/error-page.component';
import {LoginPageComponent} from './login/login-page.component';
import {RegisterComponent} from './register/register.component';
import {ArchwizardModule} from 'angular-archwizard';
import {NgSelectModule} from '@ng-select/ng-select';
import {CustomerLoginComponent} from './customer-login/customer-login.component';
import {LaunchScreenComponent} from './launch-screen/launch-screen.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordPageComponent} from './reset-password-page/reset-password-page.component';
import {FullPagesModule} from '../full-pages/full-pages.module';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


// @ts-ignore
// @ts-ignore
@NgModule({
  imports: [
    CommonModule,
    ContentPagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    ArchwizardModule,
    NgSelectModule,
    FullPagesModule
  ],
    exports: [
        RegisterComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent
    ],
  declarations: [
    ErrorPageComponent,
    LoginPageComponent,
    RegisterComponent,
    CustomerLoginComponent,
    LaunchScreenComponent,
    ForgotPasswordComponent,
    ResetPasswordPageComponent, TermsConditionsComponent, PrivacyPolicyComponent
  ]
})
export class ContentPagesModule {
}
