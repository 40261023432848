import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class JsonXlsxService {

  constructor() { }

  convertJsonToXlsx(jsonData: any[], sheetName: string, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // Save the workbook as an XLSX file
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}
