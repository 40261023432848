import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {
  ModalDismissReasons,
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct, NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {Router, ActivatedRoute} from '@angular/router';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {Order} from '../orders/order';
import {Clipboard} from '@angular/cdk/clipboard';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';
// @ts-ignore
const moment = require('moment');
const momentTimezone = require('moment-timezone');
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-cust-order-history',
  templateUrl: './cust-order-history.component.html',
  styleUrls: ['./cust-order-history.component.scss']
})
export class CustOrderHistoryComponent implements OnInit {

  date = new Date();
  userData: any;
  pharmacy: any;
  viewScript = ''
  closeResult = '';
  orderList: any[] = [];
  dateSearch = new Date();
  rows: any[] = [];
  rowHeight = 0;
  platform: any;
  isCollapsed = true;
  orderedItems: any[] = [];
  selectedOrder: Order | any;
  ordersMessage = '';
  globalSelectedStatus = 'all';
  message = '';
  expand = false;
  childOrder: any;
  showUrl = false;
  maxPageSize = 0;
  pharmacyUser: any;
  customerNotes = [];
  page = 1;
  createdDivs: any[] = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;

  constructor(private authService: AuthService, private monthPipe: MonthFormatPipe,
              private modalService: NgbModal, private clipboard: Clipboard, private storage: LocalStorageService,
              private toastr: ToastrService, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar,
              public router: Router, private downloadFile: DownloadFileService) {
    this.modalService.dismissAll();
  }


  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(localStorage.getItem('platform'));
    this.loadOrders();
  }

  loadOrders() {
    this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, false, '').then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        this.message = 'No orders found';
        this.rows = [];
      } else {
        this.orderList = resp.results;
        this.maxPageSize = resp.totalPages;
        this.rows = resp.results.filter((order) => {
          order.viewmore = false;
          order.icon = 'assets/img/Plus.png';
          return order;
        });
        this.rows.filter(itm => {
          itm.height = (60 * itm.orderedItem.length) + 110;
        });
      }
    });
  }

  copyToken(token: string) {
    this.clipboard.copy(token);
  }

  filter(status: string) {
    this.page = 1;
    this.globalSelectedStatus = status;
    if (status === 'all') {
      this.message = 'No orders found';
      this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, false, '').then((resp: any) => {
        if (resp.response === 'No orders found for this customer') {
          this.message = 'No orders found';
          this.rows = [];
        } else {
          this.orderList = resp.results;
          this.maxPageSize = resp.totalPages;
          this.rows = resp.results.filter((order) => {
            order.viewmore = false;
            order.icon = 'assets/img/Plus.png';
            return order;
          });
          this.rows.filter(itm => {
            itm.height = (60 * itm.orderedItem.length) + 110;
          });
        }
      });
    } else {
      this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, false, this.globalSelectedStatus === '' ? '' : this.globalSelectedStatus).then((resp: any) => {
        if (resp.response === 'No orders found for this customer') {
          this.message = 'No orders found';
          this.rows = [];
        } else {
          this.orderList = resp.results;
          this.maxPageSize = resp.totalPages;
          this.rows = resp.results.filter((order) => {
            order.viewmore = false;
            order.icon = 'assets/img/Plus.png';
            return order;
          });
          this.rows.filter(itm => {
            itm.height = (60 * itm.orderedItem.length) + 110;
          });
        }
      });
    }
    if (this.rows.length === 0) {
      if (status === 'new') {
        this.message = 'There are currently no new orders to process'
      } else if (status === 'pending') {
        this.message = 'There are currently no orders pending';
      } else if (status === 'paymentProcess') {
        this.message = 'There are currently no orders available for payment processing';
      } else if (status === 'dispense') {
        this.message = 'There are currently no orders available for dispensing';
      } else if (status === 'shipped') {
        this.message = 'There are presently no orders available for shipping';
      } else if (status === 'completed') {
        this.message = 'There are currently no orders marked as completed';
      } else if (status === 'cancelled') {
        this.message = 'There are currently no orders that have been canceled';
      } else if (status === 'refund') {
        this.message = 'There are currently no orders eligible for a refund';
      } else if (status === 'pendingShipment') {
        this.message = 'There are currently no orders eligible for a pending Shipment';
      }
    }
  }

  createOrder() {
    this.router.navigate(['/new-order']);
  }

  viewOrder(contnent, order, size = 'full-screen-modal') {
    this.isCollapsed = true;
    this.expand = false;
    this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.createdDivs = this.selectedOrder.notes.length > 0 ? this.selectedOrder.notes.sort((a, b) => {
        if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
          return 1;
        } else {
          return -1;
        }
      }) : [];
      this.createdDivs = this.createdDivs.filter(data => data.customerNote);
      this.pharmacyUser = response.userId;
      this.customerNotes = response.notes.filter(data => data.customerNote);
      this.orderedItems = order.orderedItem.map((item) => {
        return {...item, ['collapse']: true};
      });
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(order.uploadedScript) ? true : false;
      this.selectedOrder.totalQuantity = 0;
      this.selectedOrder.totalCost = 0;
      response.orderedItem.map((data) => {
        this.selectedOrder.totalCost += data.price;
        this.selectedOrder.totalQuantity += data.quantity;
      });
      if (size !== 'full-screen-modal') {
        await this.modalService.open(contnent, {size: size, backdrop: 'static'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
        await this.modalService.open(contnent, {windowClass: size, backdrop: 'static'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    });

  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === 'x-key-press') {
      return 'by pressing the X key';
    } else {
      return `with: ${reason}`;
    }
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'x' || event.key === 'X') {
      this.modalService.dismissAll('x-key-press');
    }
  }

  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.collapseAllRows();
    this.rowHeight = row.height;
    if (row.expand) {
      this.tableRowDetails.rowDetail.collapseAllRows();
      this.rows.filter(itm => {
        itm.expand = false;
      })
    } else {
      this.tableRowDetails.rowDetail.toggleExpandRow(row);
      row.expand = true;
    }
  }


  cancelOrder(textbox) {
    const body = [{propName: 'status', value: 'cancelled'},
      {propName: 'cancellation_reason.reason', value: textbox.value}];
    this.authService.updateOrderStatus(this.userData, body, this.selectedOrder).then((resp) => {
      this.toastr.error('Order Cancelled', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.loadOrders();
      this.filter('pending');
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.error('Order Cancellation Failed!', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  accepteOrder(order: any) {
    const body = {
      status: 'accepted'
    }
    this.authService.accept_Order(this.userData, order, body).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.error(resp.error.error, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 400) {
        this.toastr.error(resp.error.error, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Orders accepted Successfully', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
      }
    }).catch(err => {
      this.toastr.error('Failed to accepte the order', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  acceptManual(order) {
    const body = [{
      propName: 'status',
      value: 'paymentProcess'
    }]
    this.authService.updateOrderStatus(this.userData, body, order).then((resp) => {
      if (resp.status === 404) {
        this.toastr.error(resp.error.error, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Orders accepted Successfully', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
      }
    }).catch(err => {
      this.toastr.error('Failed to accepte the order', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    const queryParams = {data: JSON.stringify(globalProductId)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }


  accept_By_Customer(modal) {
    const body = [{
      propName: 'status',
      value: 'paymentProcess'
    }]
    this.authService.updateOrderStatus(this.userData, body, this.selectedOrder).then((resp) => {
      if (resp.status === 404) {
        this.toastr.error(resp.error.message, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Error!', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Orders Approved Successfully', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.filter('pending');
        this.loadOrders();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.error('Failed to approve the order', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  OnChangeOrder(event: any) {
    this.filter(event.target.value);
  }

  viewMore(row) {
    this.rows.filter((order, rowindex: number) => {
      if (order._id === row._id) {
        order.viewmore = !order.viewmore;
        order.icon = order.viewmore ? 'assets/img/minus.png' : 'assets/img/Plus.png';
        return order.status !== 'preOrder';
      } else {
        order.viewmore = false;
        order.icon = 'assets/img/Plus.png';
        return order.status !== 'preOrder';
      }
    });
  }

  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }


  viewOrdersPage(order) {
    this.router.navigate(['/order-view'], {
      queryParams: {
        orderId: JSON.stringify(order._id)
      }
    });
  }

  copyToClipboard(token: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(token)
        .then(() => {
          this.toastr.clear();
          this.toastr.info('Text copied to clipboard successfully', 'Copied!', {
            positionClass: 'toast-top-center'
          });
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }

  pagination(number: number) {
    this.page = Number(number);
    this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, false, this.globalSelectedStatus === 'all' || this.globalSelectedStatus === '' ? '' : this.globalSelectedStatus).then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        this.message = 'No orders found';
        this.rows = [];
      } else {
        this.orderList = resp.results;
        this.maxPageSize = resp.totalPages;
        this.rows = resp.results.filter((order) => {
          order.viewmore = false;
          order.icon = 'assets/img/Plus.png';
          return order;
        });
        this.rows.filter(itm => {
          itm.height = (60 * itm.orderedItem.length) + 110;
        });
      }
    });
  }

  viewChildOrder(orderNo, modal) {
    this.authService.searchOrder(this.userData, this.selectedOrder?.pharmacyId, 'orderNumber', orderNo, 1).then(async (response: any) => {
      this.childOrder = response.results[0];
      this.modalService.open(modal, {size: 'max', backdrop: 'static'});
    });
  }
}
