import {Component, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {AuthService} from '../../../../shared/auth/auth.service';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {Order} from '../orders/order';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

const moment = require('moment');

@Component({
  selector: 'app-my-scripts',
  templateUrl: './my-scripts.component.html',
  styleUrls: ['./my-scripts.component.scss']
})
export class MyScriptsComponent implements OnInit {

  date = new Date();
  userData: any;
  pharmacy: any;
  page = 1;
  closeResult = '';
  orderList: any[] = [];
  orderedItems: any = [];
  rows: any[] = [];
  rowHeight = 0;
  maxPageSize = 0;
  EnableRepeat = false;
  platform: any;
  showUrl = false;
  isCollapsed = true;
  pharmacyUser: any;
  customerNotes = [];
  selectedOrder: Order | any;
  ordersMessage = 'There are no orders currently';
  globalSelectedStatus = '';
  token_updation = {
    'orderId': '',
    'newToken': '',
    'repeats': '',
    'duration': '',
  };
  disablebtn = false;
  viewScript = ''
  message = 'Thank you for your order the pharmacy will contact you shortly.';
  valid = false;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;

  constructor(private authService: AuthService, private monthPipe: MonthFormatPipe,
              private modalService: NgbModal, private downloadFile: DownloadFileService, private storage: LocalStorageService,
              private toastr: ToastrService, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar,
              public router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(localStorage.getItem('platform'));
    this.loadOrders();
  }

  loadOrders() {
    const today = moment(new Date()).format();
    this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, true, '').then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        this.ordersMessage = 'There are no orders currently';
      } else {
        this.orderList = resp.results;
        this.maxPageSize = resp.totalPages;
        this.filter('all')
        this.rows = this.orderList.filter((order) => {
          return !order.deleted;
        });
        this.rows.filter((order) => {
          if (moment(order.orderDate).format('l') === moment(today).format('l')) {
            order.enableBtn = true;
          } else {
            order.enableBtn = false;
          }
          return order;
        });
        this.rows.filter(itm => {
          itm.isAfterDate = moment(new Date()).isAfter(moment(itm.orderDate));
          itm.isCurrentDate = moment(itm.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
          itm.height = (60 * itm.orderedItem.length) + 110;
        });
      }
    });
  }

  filter(status: string) {
    this.globalSelectedStatus = status;
    if (status === 'all') {
      this.rows = this.orderList;
    } else {
      const result = this.orderList.filter(order => {
        return order.status === status;
      })
      this.rows = result;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === 'x-key-press') {
      return 'by pressing the X key';
    } else {
      return `with: ${reason}`;
    }
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.key === 'x' || event.key === 'X') && event.target instanceof HTMLElement && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
      this.modalService.dismissAll('x-key-press');
    }
  }


  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.collapseAllRows();
    this.rowHeight = row.height;
    if (row.expand) {
      this.tableRowDetails.rowDetail.collapseAllRows();
      this.rows.filter(itm => {
        itm.expand = false;
      })
    } else {
      this.tableRowDetails.rowDetail.toggleExpandRow(row);
      row.expand = true;
    }
  }

  cancelOrder(textbox) {
    const body = [{
      propName: 'status',
      value: 'cancelled'
    }, {
      'propName': 'cancellation_reason.reason',
      'value': textbox.value
    }];
    this.authService.updateOrderStatus(this.userData, body, this.selectedOrder).then((resp) => {
      this.toastr.clear();
      this.toastr.error('Order Cancelled', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.loadOrders();
      this.filter('pending');
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Order Cancellation Failed!', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  ViewOrderModal(order: any, content) {
    this.isCollapsed = true;
    this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.pharmacyUser = response.userId;
      this.customerNotes = response.notes.filter(data => data.customerNote);
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(order.uploadedScript) ? true : false;
      this.selectedOrder.totalQuantity = 0;
      this.selectedOrder.totalCost = 0;
      this.orderedItems = response.orderedItem;
      this.orderedItems.map((data) => {
        this.selectedOrder.totalCost = data.price;
        this.selectedOrder.totalQuantity += data.quantity;
      })
      this.modalService.open(content, {windowClass: 'full-screen-modal', backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });

  }

  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

  updateToken(token, order, index, modal) {
    let body = [];
    if (token === '') {
      this.toastr.clear();
      this.toastr.error('Please Enter the Token', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    body.push({
      propName: 'orderedItem.' + index + '.' + 'token',
      value: token
    });

    this.authService.update_order(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Token updation failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Token Updated Successfully', 'Updated', {
          positionClass: 'toast-top-center'
        });
        body = [];
        this.selectedOrder = resp.updatedBody;
        this.orderedItems = this.selectedOrder.orderedItem;
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
        });
        this.loadOrders();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Ordered items udpation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  fillDetails(newToken, Order, index: number, modal: any, confim: any) {
    if (newToken === '') {
      this.toastr.clear();
      this.toastr.error('Please Provide the token', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const obj = {
      'orderId': '', 'items': []
    }
    this.token_updation.newToken = newToken === '' ? ' ' : newToken;
    this.token_updation.repeats = ''
    this.token_updation.duration = ''
    this.token_updation.orderId = Order._id;
    obj.orderId = Order._id;
    obj.items.push({
      'token': newToken.trim(),
    });
    this.modalService.open(confim, {size: 'md', backdrop: 'static'})

  }

  openModal(Modal1: any, medi: any, message: string, size = 'xl') {
    this.selectedOrder = medi;
    this.valid = false;
    this.disablebtn = false;
    this.message = message;
    this.modalService.open(Modal1, {size: size, backdrop: 'static'});
  }

  copyToClipboard(token: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(token)
        .then(() => {
          this.toastr.clear();
          this.toastr.info('Text copied to clipboard successfully', 'Copied!', {
            positionClass: 'toast-top-center'
          });
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }

  reOrder(modal: any) {
    this.authService.re_order(this.userData, this.selectedOrder, this.token_updation).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Re-order Failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Re-ordered successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
        this.EnableRepeat = false;
        this.token_updation = {
          'orderId': '',
          'newToken': '',
          'repeats': '',
          'duration': '',
        }
        modal.dismiss('Cross-Click');
        this.modalService.dismissAll();
      }
    });
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    const queryParams = {data: JSON.stringify(globalProductId)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }


  validateToken(value: string) {
    const pattern = /^(?!\s).*$/;
    if (value.trim() === '') {
      this.valid = true;
      return;
    }
    if (!pattern.test(value.trim())) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  sendNote(note, modal) {
    if (note.value.trim() === '' || !/^(?!\s).*$/.test(note)) {
      this.toastr.clear();
      this.toastr.error('Customer notes must not be left blank', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.disablebtn = true;
    const body = {'fallowUp_note': note.value};
    this.authService.update_note(this.userData, body, this.selectedOrder, true).then((resp: any) => {
      if (resp.status === 500) {
        this.disablebtn = false;
        return;
      }
      this.toastr.clear();
      this.toastr.info('Note updated', 'Info', {
        positionClass: 'toast-top-center'
      });
      note.value = '';
      this.disablebtn = false;
    }).catch(err => {
      this.disablebtn = false;
    })
  }

  pagination(page: number) {
    this.page = page;
    this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, true).then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        this.ordersMessage = 'There are no orders currently';
      } else {
        this.orderList = resp.results;
        this.maxPageSize = resp.totalPages;
        this.filter('all')
        this.rows = this.orderList.filter((order) => {
          return !order.deleted;
        });
        this.rows.filter((order) => {
          if (moment(order.orderDate).format('l') === moment(new Date()).format('l')) {
            order.enableBtn = true;
          } else {
            order.enableBtn = false;
          }
          return order;
        });
        this.rows.filter(itm => {
          itm.isAfterDate = moment(new Date()).isAfter(moment(itm.orderDate));
          itm.isCurrentDate = moment(itm.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
          itm.height = (60 * itm.orderedItem.length) + 110;
        });
      }
    });
  }
}
