import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/auth/auth.service';
import {CustomizerService} from '../../../shared/services/customizer.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-launch-screen',
  templateUrl: './launch-screen.component.html',
  styleUrls: ['./launch-screen.component.scss']
})
export class LaunchScreenComponent implements OnInit, OnDestroy {
  platform: any;

  constructor(public authService: AuthService, private customizerService: CustomizerService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    // const body = {'url': window.location.origin};
    // const body = {'url': 'http://localhost:4200'};
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    }
    this.platform = JSON.parse(localStorage.getItem('platform'));
  }


  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

}
