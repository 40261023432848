<section id="ngx-datatable-filter">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Leads</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <!-- ngx-datatable-filter -->
            <div class="row">
              <div class="col-6">
                <fieldset class="form-group">
                  <label for="ngx-filter-ref">Search:
                    <input id="ngx-filter-ref" class="form-control form-control-sm d-inline-block width-200" type="text"
                           placeholder="Filter the Name column..."/>
                  </label>
                </fieldset>
              </div>
            </div>
            <section id="shopping-cart">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-content">
                      <div class="card-body table-responsive">
                        <table class="table text-center m-0">
                          <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><a class="btn btn-warning" (click)="open(content)">Convert To Customer</a></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-secondary">Previous</button>
                      <button class="btn btn-primary float-right">Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Contact Form</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <button class="badge bg-light-warning btn mx-1 btn"><i class="ft ft-mail mr-1"></i>Email</button>
    <button class="badge bg-light-primary btn mx-1 btn"><i class="ft ft-phone mr-1"></i>Phone</button>
  </div>
  <div class="modal-footer"></div>
</ng-template>
