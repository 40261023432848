import { Injectable } from '@angular/core';
import { Parser } from '@json2csv/plainjs';

@Injectable({
  providedIn: 'root'
})
export class Json2csvService {

  constructor() { }


  downloadCsv(data: any[], filename: string) {

    try {
      const opts = {};
      const parser = new Parser(opts);
      const csv = parser.parse(data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  }
}
