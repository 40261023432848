<!--Login Page Starts-->
<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="{{this.platform?.logo}}" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <h4 class="mb-2 card-title">Login with Phone number</h4>
                <p>Welcome, please login to your account.</p>
                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0">Login failed!</p>
                </ngb-alert>
                <div class="row" *ngIf="showLogin">
                  <div class="col-12">
                    <form [formGroup]="loginForm">
                      <div class="form-group">
                        <input type="text" formControlName="number" class="form-control" placeholder="Phone number"
                               [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.number.invalid, 'is-valid': loginFormSubmitted && !lf.number.invalid }"
                               required>
                        <div *ngIf="loginFormSubmitted && (lf.number.invalid || lf.number.errors?.required)"
                             class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                          required
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['number'].hasError('pattern')">Invalid Phone number
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <a class="btn primary-btn" (click)="onSubmit()">Submit</a>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="row mt-2" *ngIf="showOTP">
                  <div class="col-12">
                    <form [formGroup]="verifyOTp">
                      <div class="form-group">
                        <input type="text" formControlName="otp" class="form-control" placeholder="OTP"
                               [ngClass]="{ 'is-invalid': otpformSubmitted && of.otp.invalid, 'is-valid': otpformSubmitted && !of.otp.invalid }"
                               required>
                        <div *ngIf="otpformSubmitted && (of.otp.invalid || of.otp.errors?.required)"
                             class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                          required
                        </div>
                        <div class="text-danger" *ngIf="verifyOTp.controls['otp'].hasError('pattern')">Invalid OTP
                        </div>
                      </div>
                      <p class="cursor-pointer">Didn't receive OTP? <a (click)="tryAgain()" class="text-primary">Try Again</a></p>
                      <div class="d-flex justify-content-center align-items-center">
                        <a class="btn primary-btn" (click)="verify_OTP(content)">Verify OTP</a>
                      </div>
                    </form>
                  </div>
                  <div class="col-12" *ngIf="showQRCode">
                    <div class="d-flex justify-content-center align-items-center">
                      <img id="qrcode">
                    </div>
                  </div>
                </div>
                <div class="row mt-2" *ngIf="showTwofa">
                  <div class="col-12">
                    <form [formGroup]="googleAuthCode">
                      <div class="form-group">
                        <input type="text" formControlName="authCode" class="form-control" placeholder="Two FA code"
                               [ngClass]="{ 'is-invalid': authCodeSubmitted && authf.authCode.invalid, 'is-valid': authCodeSubmitted && !authf.authCode.invalid }"
                               required>
                        <div *ngIf="authCodeSubmitted && (authf.authCode.invalid || authf.authCode.errors?.required)"
                             class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                          required
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <a class="btn primary-btn" (click)="verify_Two_fa()">Veriy Two FA</a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Complete your profile</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="registeruser" class="editForm" novalidate (ngSubmit)="registerUser()">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step [stepTitle]="'Step 1'"
                        [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">
          <h4 class="head text-center">Sign Up!</h4>
          <br/>
          <div class='row'>
            <div class='col-12'>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">First Name</label>
                    <input class="form-control input-md" formControlName="firstName" type="text">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['firstName'].hasError('required')">First
                      name
                      is required</p>
                  </div>
                </div>
              </div>
              <div class='row'>
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Last Name</label>
                    <input class="form-control input-md" formControlName="lastName" type="text">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['lastName'].hasError('required')">Last
                      name
                      is required</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Email</label>
                    <input class="form-control input-md" formControlName="email" type="email">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['email'].hasError('required')">Email is
                      required</p>
                    <p class="text-danger" *ngIf="registeruser.controls['email'].hasError('email')">Invalid
                      Email</p>
                  </div>
                </div>
              </div>
              <div class="form-group text-center">
                <!--                    [disabled]="registeruser.controls['firstName'].invalid || registeruser.controls['lastName'].invalid || registeruser.controls['email'].invalid-->
                <!--                    || registeruser.controls['number'].invalid"-->
                <button type="button" uiSref="work" class="btn btn-info"

                        awNextStep>
                  Next
                  <span style="margin-left:10px;"><i class="ft-chevron-right"></i></span>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step [stepTitle]="'Step 2'"
                        [navigationSymbol]="{ symbol: '&#xf0b1;', fontFamily: 'FontAwesome' }">
          <h4 class="head text-center">Address</h4>
          <br/>
          <div class='row'>
            <div class='col-12'>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Street Address</label>
                    <input class="form-control input-md" formControlName="streetAddress" type="text">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['streetAddress'].hasError('required')">
                      Street
                      Address is required</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Suburban</label>
                    <input class="form-control input-md" formControlName="subUrb" type="text">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['subUrb'].hasError('required')">SubUrb is
                      required</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">State</label>
                    <input class="form-control input-md" formControlName="state" type="text">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['state'].hasError('required')">State is
                      required</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Postcode</label>
                    <input class="form-control input-md" formControlName="postcode" type="text" maxlength="6"
                           (keyup)="validatePostCode($event)">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['postcode'].hasError('required')">
                      Postcode
                      is
                      required</p>
                    <p *ngIf="validatepostalCode" class="text-danger">Invalid Postal Code</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Country</label>
                    <input class="form-control input-md" formControlName="country" type="text">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['country'].hasError('required')">Country
                      is
                      required</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group text-center space-20">
            <button uiSref="personal" type="button" class="btn btn-secondary mr-1" awPreviousStep>
                                  <span style="margin-right:10px;">
                                      <i class="ft-chevron-left"></i>
                                  </span> Previous
            </button>
            <!--                [disabled]="registeruser.controls['streetAddress'].invalid ||-->
            <!--                registeruser.controls['state'].invalid || registeruser.controls['postcode'].invalid ||-->
            <!--                registeruser.controls['country'].invalid || registeruser.controls['subUrb'].invalid"-->
            <button uiSref="address" type="button" class="btn btn-info"

                    awNextStep>
              Next
              <span style="margin-left:10px;">
                                      <i class="ft-chevron-right"></i>
                                  </span>
            </button>
          </div>
        </aw-wizard-step>
        <aw-wizard-step [stepTitle]="'Step 3'"
                        [navigationSymbol]="{ symbol: '&#xf0ac;', fontFamily: 'FontAwesome' }">
          <h4 class="head text-center">Document Verification</h4>
          <br/>
          <div class='row'>
            <div class='col-12'>
              <!--              <div class="row">-->
              <!--                <div class='col-12'>-->
              <!--                  <div class="form-group">-->
              <!--                    <label class="form-control-label">Upload License</label>-->
              <!--                    <input class="form-control input-md" formControlName="license" type="file" accept="image/*">-->
              <!--                    <p class="text-danger"-->
              <!--                       *ngIf="isformSubmitted && registeruser.controls['license'].hasError('required')">Upload-->
              <!--                      license-->
              <!--                      please</p>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Select pharmacy</label>
                    <div class="form-group">
                      <ng-select bindLabel="pharmacy_name" formControlName="pharmacy" [items]="pharmacyList"
                                 bindValue="_id" placeholder="Select Pharmacy">
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Medicare number</label>
                    <input class="form-control input-md" formControlName="medicareNumber" maxlength="10"
                           type="text"
                           (keyup)="validateMedicareNumber($event)">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['medicareNumber'].hasError('required')">
                      Medicare number is
                      required</p>
                    <p *ngIf="validateMedicareNum" class="text-danger">Invalid Medicare number</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group text-center">
            <button uiSref="work" type="button" class="btn btn-secondary mr-1" awPreviousStep
            >
              <span style="margin-right:10px;"><i class="ft-chevron-left"></i></span> Previous
            </button>
            <!--                [disabled]="registeruser.controls['medicareNumber'].invalid || registeruser.controls['pharmacy'].invalid || registeruser.controls['license'].invalid"-->
            <button uiSref="address" type="button" class="btn btn-info"

                    awNextStep>
              Next<span style="margin-left:10px;"><i class="ft-chevron-right"></i></span>
            </button>
          </div>
        </aw-wizard-step>
        <aw-wizard-step [stepTitle]="'Step 4'"
                        [navigationSymbol]="{ symbol: '&#xf023;', fontFamily: 'FontAwesome' }">
          <h4 class="head text-center">Set your password</h4>
          <br/>
          <div class='row'>
            <div class='col-12'>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Password</label>
                    <input class="form-control input-md" formControlName="password" type="password">
                    <p class="text-danger"
                       *ngIf="isformSubmitted && registeruser.controls['password'].hasError('required')">
                      Password
                      is
                      required</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class='col-12'>
                  <div class="form-group">
                    <label class="form-control-label">Confirm Password</label>
                    <div class="form-group">
                      <input class="form-control input-md" formControlName="confirm_password" type="password">
                      <p class="text-danger"
                         *ngIf="isformSubmitted && registeruser.controls['confirm_password'].hasError('required')">
                        Confirm password is required</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group text-center">
            <!--                [disabled]="registeruser.controls['password'].invalid || registeruser.controls['confirm_password'].invalid"-->
            <button uiSref="work" type="button" class="btn btn-secondary mr-1" awPreviousStep>
              <span style="margin-right:10px;"><i class="ft-chevron-left"></i></span> Previous
            </button>
            <button uiSref="result" type="submit" class="btn btn-success" (click)="registerUser()">
              Finish<span style="margin-left:10px;"><i class="ft-chevron-right"></i></span>
            </button>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </form>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
<!--Login Page Ends-->
