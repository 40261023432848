<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row d-lg-none d-sm-block">
          <div class="col-md-6 col-12 px-2 py-2">
            <button class="btn secondary-btn badge d-lg-none d-sm-block ml-2" (click)="createOrder()"><i
              class="ft-plus-circle mr-1"></i>Create Order
            </button>
            <select class="form-control d-lg-none d-sm-block square text-box float-right mr-2"
                    (change)="OnChangeOrder($event)">
              <option disabled selected>Select</option>
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="shipped">Shipped</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12">
            <button class="btn mt-2 secondary-btn ml-2 d-none d-sm-none d-lg-block" (click)="createOrder()"><i
              class="ft-plus-circle mr-1"></i>Create Order
            </button>
          </div>
        </div>
        <div class="d-none d-sm-none d-lg-block">
          <button class=" float-right mr-2 mt-3 btn-status-padding"
                  [ngClass]="globalSelectedStatus === 'cancelled'?
                    'bg-white border-danger btn text-danger text-under-line' : 'bg-light-danger btn'"
                  (click)="filter('cancelled')">Cancelled
          </button>
          <button class="float-right mr-2 mt-3 btn-status-padding"
                  [ngClass]="globalSelectedStatus === 'completed'?
                     'bg-white btn secondary-color-border text-under-line' : 'secondary-color btn'"
                  (click)="filter('completed')">Completed
          </button>
          <button class="float-right mr-2 mt-3 btn-status-padding"
                  [ngClass]="globalSelectedStatus === 'pendingShipment'?
                          'btn shipped-dark text-under-line' : 'btn shipped-light'"
                  (click)="filter('pendingShipment')">Pending Shipment
          </button>
          <button class="float-right mr-2 mt-3 btn-status-padding"
                  [ngClass]="globalSelectedStatus ===
                    'pending'?  'bg-white border-secondary btn text-secondary text-under-line'   : 'btn-secondary btn'"
                  (click)="filter('pending')">Pending
          </button>
          <button class="float-right mt-3 mr-2 btn-status-padding"
                  [ngClass]="globalSelectedStatus === 'all'? 'bg-white border-warning btn text-warning text-under-line'   : 'bg-light-warning btn'"
                  (click)="filter('all')">All
          </button>
        </div>
        <div class="card-content">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">Order History</h4>
            </div>
            <div class="card-content">
              <div class="title text-center">
                  <span class="text-uppercase card-title heading-width"
                        *ngIf="rows.length > 0 && globalSelectedStatus !== 'shipped'">{{ globalSelectedStatus === 'all' ? 'ALL ORDERS' : globalSelectedStatus === 'pendingShipment' ? 'Pending Shipment' : globalSelectedStatus }}</span>
                <span class="text-uppercase card-title heading-width"
                      *ngIf="rows.length > 0 && globalSelectedStatus === 'shipped'">{{ globalSelectedStatus === 'shipped' ? 'Pending Shipment' : globalSelectedStatus }}</span>
                <h4 class="card-title mt-2" *ngIf="rows.length === 0">{{ message }}</h4>
              </div>
              <div class="card-body" *ngIf="this.rows.length > 0">

                <!-- ngx-datatable-row-details -->
                <ngx-datatable #tableRowDetails class="bootstrap material manoj d-none d-sm-none d-lg-block"
                               [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" [limit]="10" [rows]="rows"
                               [scrollbarH]="true">
                  <!-- Row Detail Template -->
                  <ngx-datatable-row-detail [rowHeight]="this.rowHeight">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                      <table class="table m-0">
                        <thead class="pb-4 mb-4">
                        <tr>
                          <th class="text-left">QR Code</th>
                          <th class="text-left">Token Id</th>
                          <th class="text-left">Product Name</th>
                          <th class="text-left">Brand Name</th>
                          <th class="text-left">Quantity</th>
                          <!--                          <th class="text-left">Repeats</th>-->
                        </tr>
                        <tr *ngFor="let item of row?.orderedItem">
                          <td class="py-0 pt-1">
                            <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>
                          </td>
                          <td class="text-left text-uppercase">{{ item?.token }}</td>
                          <td class="text-left cursor-pointer"
                              (click)="showGlobalProd(item?.globalProductId)">{{ item?.product_name }}
                          </td>
                          <td class="text-left">{{ item?.product_brand_name }}</td>
                          <td class="text-left">{{ item?.quantity }}</td>
                          <!--                          <td class="text-left">{{item?.repeats}}</td>-->
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                      </table>
                    </ng-template>
                  </ngx-datatable-row-detail>
                  <ngx-datatable-column [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false"
                                        [canAutoResize]="false">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                      <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
                         [class.datatable-icon-down]="expanded" title="Expand/Collapse Row"
                         (click)="rowDetailsToggleExpand(row)">
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Order no" prop="orderNo" [width]="100">
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Date and Time" prop="orderDate" [width]="350">
                    <ng-template let-createdAt="value" ngx-datatable-cell-template>
                      <p>{{ createdAt| date:'mediumDate' }} {{ createdAt | date:'shortTime' }}
                      </p>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Order Total" prop="totalAmount" [width]="220">
                    <ng-template let-totalAmount="value" ngx-datatable-cell-template>
                      <p>$ {{ totalAmount | number:'1.2-2' }}</p>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Status" prop="status" [width]="240">
                    <ng-template let-status="value" ngx-datatable-cell-template>
                      <span class="badge cursor-pointer text-capitalize"
                            [ngClass]="{'bg-light-info':status === 'new',
                      'btn badge-success':status === 'shipped','bg-light-secondary':status === 'pending',
                      'secondary-color':status === 'completed','bg-light-warning':status === 'dispense',
                      'bg-light-danger':status === 'cancelled',
                       'payment-process': status === 'paymentProcess'}"
                      >{{ status === 'paymentProcess' ? 'Payment Process' : status }}
                      </span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Actions" [width]="320">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <button class="badge btn secondary-btn" (click)="viewOrder(ViewOrder,row)">View
                      </button>
                      <span class="text-white cursor-pointer badge-success badge badge-width ml-2"
                            ngbTooltip="Accept Order" placement="bottom"
                            *ngIf="row.status === 'pending'" (click)="viewOrder(confirmModal1,row,'md')">
                        <i class="ft-check-circle"></i>
                      </span>
                      <span class="ml-2 text-white cursor-pointer badge-danger badge badge-width"
                            ngbTooltip="Cancel Order" placement="bottom"
                            *ngIf="row.status === 'pending'"
                            (click)="viewOrder(confirmModal,row,'md')">
                        <i class="ft-x"></i>
                      </span>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>

                <!--                Mobile view -->
                <div *ngFor="let row of rows;let i =index;" class="d-lg-none d-sm-block">
                  <div class="row mt-2 card shadow-card">
                    <div class="col-12 col-md-12 bg-light px-2 py-2 header top-header">
                      <span class="order-no">
                        <span class="hash-font">#</span>:{{ row.orderNo }}</span>
                      <span class="badge badge-width-min cursor-pointer float-right items-font text-capitalize"
                            [ngClass]="{'bg-light-info':row.status === 'new',
                      'bg-light-success':row.status === 'shipped','bg-light-secondary':row.status === 'pending',
                      'secondary-color text-white':row.status === 'completed','bg-light-warning':row.status === 'dispense',
                      'bg-light-danger':row.status === 'cancelled','payment-process': row.status === 'paymentProcess' }"
                      >{{ row.status }}
                      </span>
                      <span *ngIf="row.status === 'pending'" class="badge-danger badge badge-width float-right mr-1"
                            (click)="viewOrder(confirmModal,row,'md')"><i class="ft-x text-white"></i></span>
                      <span *ngIf="row.status === 'pending'" class="badge-success badge badge-width float-right mr-0"
                            (click)="viewOrder(confirmModal1,row,'md')"><i
                        class="ft-check-circle text-white"></i></span>

                      <!--                      <span *ngIf="row.status === 'pending'" (click)="accept_By_Customer(row)" class="badge items-font badge-success text-white"><i class="ml-1 ft-check-circle"></i></span>-->
                      <!--                      <span *ngIf="row.status === 'pending'" class="ml-1 items-font cursor-pointer badge text-white badge-danger" ngbTooltip="Cancel Order" placement="right" -->
                      <!--                            (click)="viewOrder(confirmModal,row,'sm')"><i class="ft-x"></i>-->
                      <!--                      </span>-->


                    </div>
                    <div class="col-12 col-md-12 px-1 py-1">
                      <p class="items-font mt-1">
                        <img [src]="row.icon" alt="" class="icon-image cursor-pointer" (click)="viewMore(row)">
                        Ordered Date: {{ row.createdAt| date:'mediumDate' }} {{ row.createdAt | date:'shortTime' }}
                        Cost:
                        ${{ row.totalAmount }}</p>
                    </div>
                    <div class="col-12 col-md-12" *ngIf="row.viewmore">
                      <p class="items-font" [ngClass]="i % 2 === 0 ? 'light-gray' : 'light-white'"
                         *ngFor="let item of row.orderedItem;let i = index;">
                        {{ i + 1 }}. Product: {{ item.product_name }} | Brand: {{ item.product_brand_name }} |
                        Qty:{{ item.quantity }} | ${{ item.price }}
                      </p>
                    </div>
                    <div class="col-12 px-2 py-2 col-md-12 view-more text-center text-white">
                      <span (click)="viewOrdersPage(row)">VIEW ORDER</span>
                    </div>
                  </div>
                </div>

                <!-- ngx-datatable-row-details -->
              </div>
              <div class="card-footer">
                <ngb-pagination class="float-right" [collectionSize]="this.maxPageSize * 10"
                                (pageChange)="pagination($event)"
                                [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #ViewOrder let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Order - #{{ selectedOrder.orderNo }}</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-dialog-scrollable">
    <div class="modal-body">
      <div style="overflow-y: scroll !important;overflow-x: hidden !important;">
        <h5 class="font-weight-bold font-italic">User Information</h5>
        <hr>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-8 col-12">
            <div class="row">
              <div class="col-md-6 col-lg-3 col-sm-12 col-xl-4 col-12">
                <label class="font-weight-bold">Name</label>
                <p>{{ selectedOrder?.name }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Mobile Number</label>
                <p>{{ selectedOrder?.mobile }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Email</label>
                <p style="word-break: break-word">{{ selectedOrder?.email }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Order By</label><br>
                <p>{{ selectedOrder?.orderedBy }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Ordered Date and Time</label><br>
                <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12"
                   *ngIf="selectedOrder?.status === 'cancelled' && (selectedOrder?.rejectReason !== '' || selectedOrder?.cancellation_reason.reason !== '')">
                <label class="font-weight-bold">Cancellation Reason</label><br>
                <p *ngIf="selectedOrder?.rejectReason !== ''">{{ selectedOrder?.rejectReason }}</p>
                <p
                  *ngIf="selectedOrder?.cancellation_reason.reason !== ''">{{ selectedOrder?.cancellation_reason.reason }}</p>
              </div>

              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Delivery Type</label><br>
                <span class="badge bg-light-primary">{{ selectedOrder?.pickup ? 'Pickup' : 'Delivery' }}</span>
                <span class="ml-2">{{ !selectedOrder?.pickup ? selectedOrder?.deliveryInstruction : '' }}</span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Status</label><br>
                <span
                  class="badge bg-light-success btn text-capitalize" [ngClass]="{'bg-light-success btn' : pharmacyUser?.pharmacyUserStatus === 'verified','bg-light-dark btn' : pharmacyUser?.pharmacyUserStatus === 'blacklisted',
                            'bg-light-danger btn': pharmacyUser?.pharmacyUserStatus === 'unverified'}">
                              {{ pharmacyUser?.pharmacyUserStatus }}</span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                <label class="font-weight-bold">Order Status</label><br>
                <span
                  *ngIf="selectedOrder.partialShipmentStatus !== 'shipped' && selectedOrder.partialShipmentStatus !== 'completed'"
                  class="text-capitalize">{{ selectedOrder.status }}</span>
                <span
                  *ngIf="selectedOrder.partialShipmentStatus === 'shipped' || selectedOrder.partialShipmentStatus === 'completed'"
                  class="text-capitalize">{{ selectedOrder.partialShipmentStatus }}
            </span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                <label class="font-weight-bold">Address</label>
                <p>
                  <b>{{ selectedOrder?.addressType }}</b> {{ selectedOrder?.address + ' ' + selectedOrder?.suburb + ' ' + selectedOrder?.state + ' ' + selectedOrder?.postcode }}
                </p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                   *ngIf="selectedOrder.pickup && selectedOrder?.pickup_name !== ''">
                <label class="font-weight-bold">Pickup Name</label><br>
                <p>{{ selectedOrder?.pickup_name }} - {{ selectedOrder?.relationship_with_customer }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                   *ngIf="this.selectedOrder.uploadedScript!== '' && this.selectedOrder.uploadedScript!== ' '">
                <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                  <i
                    *ngIf="showUrl"
                    class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                    (click)="downloadScript(this.selectedOrder?.uploadedScript)"></i>
                </label>
                <br>
                <div *ngIf="showUrl">
                  <img class="cursor-pointer" src="{{this.selectedOrder?.uploadedScript}}" alt="" width="60"
                       height="40"
                       (click)="isCollapsed = !isCollapsed"
                       [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                  <div id="collapseExample" [ngbCollapse]="isCollapsed">
                    <img src="{{this.selectedOrder?.uploadedScript}}" alt="" width="400" height="300">
                  </div>
                </div>
                <p *ngIf="!showUrl">{{ this.selectedOrder?.uploadedScript }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-xl-4 col-12">
            <div class="chat-container" *ngIf="this.customerNotes.length > 0;">
              <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand" [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'" class="cursor-pointer float-right"></i></div>
              <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                <div class="messages">
                  <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                    {{ note.fallowUp_note }}
                    <p
                      class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                      {{ note.note_addedBy_userName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <h5 class="font-weight-bold font-italic">User Script</h5>
          <hr>
        </div>
        <div class="table-responsive">
          <table class="table items-table">
            <thead class="text-center">
            <tr>
              <!--              <th class="text-left">QR Code</th>-->
              <th>Token Number</th>
              <th>Script Info</th>
              <th>Brand Name</th>
              <th>Product Name</th>
              <!--              <th>Medication Strength</th>-->
              <th>Quantity</th>
              <th *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">Partial Shipped
                Quantity
              </th>
              <th *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">Remaining Quantity</th>
              <th>Price</th>
              <!--              <th>Repeats</th>-->
              <th>Instruction</th>
              <th>Note</th>
              <th>Backorder</th>
            </tr>
            </thead>
            <tbody class="text-center">
            <tr *ngFor="let item of this.orderedItems;let i = index;">
              <!--              <td class="py-0">-->
              <!--                <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
              <!--              </td>-->
              <td class="text-uppercase"><span class="ft-copy cursor-pointer mr-2"
                                               (click)="copyToClipboard(item?.token)"></span>{{ item?.token }}
              </td>
              <td class="text-center">
                <div class="d-flex">
                  <img *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                       class="cursor-pointer"
                       src="{{ item?.scriptData?.uploadedScript }}"
                       alt="" width="60" height="40"
                       (click)="viewScript_image(item?.scriptData?.uploadedScript,view_script_image)">
                  <span ngbTooltip="Download ScriptImage"
                        *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                        class="ft-download-cloud cursor-pointer ml-2"
                        (click)="downloadScript(item?.scriptData?.uploadedScript)"></span>
                </div>
                <a *ngIf="item?.scriptData?.type === 'url'" target="_blank"
                   href="{{item?.scriptData?.uploadedScript}}" class="text-primary">
                <span style="text-decoration: underline" ngbTooltip="{{item?.scriptData?.uploadedScript}}"
                      placement="top">Click Here</span>
                </a>
              </td>
              <td>{{ item?.product_brand_name }}</td>
              <td class="cursor-pointer"
                  (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}
              </td>
              <!--              <td>{{item?.medication_strength}}</td>-->
              <td>{{ item?.quantity }}</td>
              <td
                *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.shippedQuantity }}
              </td>
              <td
                *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.remainingQuantity }}
              </td>
              <td>${{ item?.price }}</td>
              <!--              <td>{{item?.repeats}}</td>-->
              <td>{{ item?.instruction }}</td>
              <td>{{ item?.note }}</td>
              <td *ngIf="item.isBackOrder" class="text-center">
                 <span
                   class="badge bg-light-warning btn text-capitalize">Backorder</span>
              </td>
            </tr>
            </tbody>

            <tbody>
            <tr>
              <td colspan="5" class="text-right"><b>Item Total</b></td>
              <td>${{ selectedOrder.itemsCost }}</td>
            </tr>
            <tr *ngIf="!selectedOrder.pickup">
              <td colspan="5" class="text-right"><b>Delivery Charge:</b></td>
              <td>
                ${{ selectedOrder.freightCharge }}
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-right"><b>Total Amount:</b></td>
              <td><p class="font-weight-bold font-medium-1">${{ selectedOrder?.totalAmount | number:'1.2-2' }}<br>
                <span
                  class="font-weight-normal font-small-3">*Includes GST of ${{ selectedOrder.taxAmount | number:'1.2-2' }}</span>
              </p></td>
            </tr>
            </tbody>
          </table>
        </div>
        <br>
        <h5 class="font-weight-bold font-italic">Pharmacy Details</h5>
        <hr>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="col-12">
              <label class="font-weight-bold">Pharmacy Name</label>
              <p>{{ selectedOrder?.pharmacyId?.pharmacy_name }}</p>
            </div>
            <div class="col-12 mt-2">
              <label class="font-weight-bold">Mobile Number</label>
              <p>{{ selectedOrder?.pharmacyId?.phoneNumber }}</p>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="col-12">
              <label class="font-weight-bold">Email</label>
              <p>{{ selectedOrder?.pharmacyId?.email }}</p>
            </div>
            <div class="col-12">
              <label class="font-weight-bold">Pharmacy Address</label>
              <p>{{
                  selectedOrder?.pharmacyId?.address + ' ' + selectedOrder?.pharmacyId?.suburb + ' ' + selectedOrder?.pharmacyId?.state +
                  ' ' + selectedOrder?.pharmacyId?.postcode
                }}</p>
            </div>
          </div>
        </div>
        <!--Additional Cost -->
        <br>
        <h5 class="font-weight-bold font-italic" *ngIf="selectedOrder?.additionalCosts.length > 0">Additional Costs</h5>
        <div class="row" *ngIf="selectedOrder?.additionalCosts.length > 0">
          <div class="col-md-12 col-12">
            <div class="table-responsive">
              <table class="table m-0">
                <thead>
                <tr>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Payment Status</th>
                  <th>Amount Added By</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let extracost of selectedOrder?.additionalCosts;let i = index;">
                  <td>{{ extracost.amount }}</td>
                  <td>{{ extracost.description }}</td>
                  <td>{{ extracost.paid ? 'Paid' : 'Un-Paid' }}</td>
                  <td>{{ extracost.amount_addedBy_userName }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--      Payment Details-->
        <br>
        <div
          *ngIf="selectedOrder.status === 'paymentProcess' || selectedOrder.status === 'pendingShipment'  || selectedOrder.status === 'dispense' || selectedOrder.status === 'shipped' || selectedOrder.status === 'completed'">
          <h5 class="font-weight-bold font-italic">Payment Details
          </h5>
          <hr>
          <div class="table-responsive">
            <table class="table table-bordered m-0">
              <thead class="table-header">
              <tr>
                <th>Order No</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>Payment Method</th>
                <th>Transaction Number</th>
                <th>Date & Time</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let payment of selectedOrder.orderPaymentDetails">
                <td>{{ payment?.paymentAdditionalDetails[0]?.orderNumber }}</td>
                <td>$ {{ payment?.orderPayment_Amount }}</td>
                <td>
                  <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                </td>
                <td>{{ payment?.orderPayment_method }}</td>
                <td>{{ payment?.orderPayment_reference }}</td>
                <td>{{ payment?.orderPayment_initiated_date | date:'mediumDate' }} {{ payment?.orderPayment_initiated_date | date:'shortTime' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--      Delivery Details-->
        <br>
        <div *ngIf="selectedOrder?.shippingDetails.length > 0">
          <h5 class="font-weight-bold font-italic">Delivery Details</h5>
          <hr>
          <div class="table-responsive">
            <table class="table table-bordered m-0">
              <thead class="table-header">
              <tr>
                <th>Order No</th>
                <th>Order Date</th>
                <th>Carrier Number</th>
                <th>Connote Number</th>
                <th>Delivery Date & Time</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let ele of selectedOrder?.shippingDetails">
                <td class="cursor-pointer"
                    (click)="viewChildOrder(ele?.itemShippedDetails[0]?.orderNumber,productsShipped)">

                  <span style="text-decoration: underline;">{{ ele?.itemShippedDetails[0]?.orderNumber }}</span>

                </td>
                <td>
                  <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                </td>
                <td>{{ ele?.freightCarrier }}</td>
                <td>{{ ele?.trackingNo }}</td>
                <td>{{ ele?.deliveredDate  | date:'mediumDate' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Cancel Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Would like to know the reason for the order cancellation</p>
    <div class="row">
      <div class="col-12 col-md-12">
        <label class="font-weight-bold">Reason</label>
        <input type="text" class="form-control square" #reasonbox>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12 text-center col-md-12">
        <button class="btn btn-danger" (click)="cancelOrder(reasonbox)">Yes</button>
      </div>
    </div>
  </div>
</ng-template>

<!--View scriptImage-->
<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>

<ng-template #confirmModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Accept Order</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <p class="text-center">Please review and confirm the modifications made to your order by the pharmacy.
    </p>
    <div class="row mt-1">
      <div class="col-md-12 col-12 text-center">
        <button class="btn btn-danger" (click)="accept_By_Customer(modal)">Yes</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #productsShipped let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Shipped Products #{{ childOrder.orderNo }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table items-table">
        <thead>
        <tr>
          <!--          <th class="text-left">QR Code</th>-->
          <th>Token Number</th>
          <th>Brand Name</th>
          <th>Product Name</th>
          <th>Ordered Quantity</th>
          <th>Shipped Quantity</th>
          <th>Remaining Quantity</th>
        </tr>
        </thead>
        <tr *ngFor="let item of this.childOrder.orderedItem;let i = index;">
          <td><span (click)="copyToken(item?.token)" class="text-UpperCase"><i
            class="cursor-pointer text-primary ft-copy mr-1 "
            ngbTooltip="Token copied"
            triggers="click:blur"></i></span>{{ item?.token }}
          </td>
          <td>{{ item?.product_brand_name }}</td>
          <td class=" cursor-pointer" (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}</td>
          <td>{{ item?.quantity }}</td>
          <td>{{ item?.shippedQuantity }}</td>
          <td>{{ item?.remainingQuantity }}</td>
        </tr>
        <tbody>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
