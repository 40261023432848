import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../orders/order';
import {Clipboard} from '@angular/cdk/clipboard';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-orders-mobileview-page',
  templateUrl: './orders-mobileview-page.component.html',
  styleUrls: ['./orders-mobileview-page.component.scss']
})
export class OrdersMobileviewPageComponent implements OnInit {

  selectedOrder: Order | any;
  userData: any;
  platform: any;
  viewScript = '';
  isCollapsed = true;
  childOrder: any;
  pharmacyUser: any;
  notes = [];
  customerNotes = [];
  showUrl = false;

  constructor(private authService: AuthService, private router: Router, private activatedRouter: ActivatedRoute, private clipboard: Clipboard,
              private downloadFile: DownloadFileService, private modalService: NgbModal, private storage: LocalStorageService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(localStorage.getItem('platform'));
    this.activatedRouter.queryParams.subscribe((data: any) => {
      this.authService.getOrder_by_Id(this.userData, JSON.parse(data.orderId)).then((response: any) => {
        this.selectedOrder = response;
        this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(response.uploadedScript) ? true : false;
        this.pharmacyUser = response.userId;
        this.customerNotes = response.notes.filter(data => data.customerNote);
        this.selectedOrder.totalQuantity = 0;
        this.selectedOrder.totalCost = 0;
        this.selectedOrder.orderedItem.map((item) => {
          this.selectedOrder.totalCost += item.price;
          this.selectedOrder.totalQuantity += item.quantity;
        })
      });
    })
  }

  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    this.authService.fetch_particularglobalProdcut(this.userData, globalProductId, this.platform).then((response: any) => {
      const queryParams = {data: JSON.stringify(globalProductId)};
      const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
      window.open(urlWithQueryParams, '_blank');
    });
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  copyToken(token: string) {
    this.clipboard.copy(token);
  }

  viewChildOrder(orderNo, modal) {
    this.authService.searchOrder(this.userData, this.selectedOrder?.pharmacyId, 'orderNumber', orderNo, 1).then(async (response: any) => {
      this.childOrder = response.results[0];
      this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
    });
  }
}
