import {Router} from '@angular/router';
import {EventEmitter, Injectable} from '@angular/core';
import firebase from 'firebase/app'
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {LocalStorageService} from '../storage/local-storage.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import saveAs from 'file-saver';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

const moment = require('moment');

@Injectable()
export class AuthService {

  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  public baseURL = 'https://backend.posdirect.com.au:3000/';
  // public baseURL = 'https://backend.scriptit.com.au:3000/';
  // public baseURL = 'http://192.168.1.7:8010/';
  public userData: any = {
    'auth': {},
  };

  pharmacySwitch = new BehaviorSubject<string>('');
  userSubject = new BehaviorSubject({});
  upcomingOrers = new BehaviorSubject(0);
  passText = new BehaviorSubject('unselect');
  productPageRefresh = new Subject();
  platform: any;

  userType = '';
  globalPharmcySelected: any;

  constructor(public router: Router, private modalService: NgbModal, private storage: LocalStorageService, private http: HttpClient) {
  }


  sendResponse(response) {
    this.userSubject.next(response);
  }


  fetchPlatForm(body: any) {
    return this.http.post(this.baseURL + 'platform/urlBasedOnPlatform', body).toPromise().then(async (res: any) => {
      if (res.data !== 'No platform found for the requested URL') {
        localStorage.setItem('platform', JSON.stringify(res.data));
        this.platform = res.data;
      }
      return res;
    });
  }

  signupUser(body: any) {
    return this.http.post(this.baseURL + 'users/userReg', body).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      console.log(err);
    })
  }

  register_new_user(body: any) {
    return this.http.post(this.baseURL + 'users/register', body).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    })
  }

  signinUser(email: string, password: string, platform: any) {
    return this.http.post(this.baseURL + 'login/email/' + platform._id, {
      'email': email.trim(),
      'password': password.trim()
    }).toPromise().then(async (res: any) => {
      this.userData.auth = {
        'token': res.token + '',
        'refreshToken': res.refreshToken,
        'data': res.user,
        'client_id': res.client_id
      }
      await this.storage.saveData('script-capture', JSON.stringify(this.userData));
      // if (this.userData.data.role === 'Super Admin') {
      //   await this.fetch_pharmacy_platformId(this.userData, this.platform).then(async resp => {
      //     await this.getSelectedPharmacy(resp.result.length > 0 ? resp.result[0]._id : '');
      //   })
      // } else {
      await this.getSelectedPharmacy(this.userData.auth.data.pharmacy.length > 0 ? this.userData.auth.data.pharmacy[0]._id : '');
      // }
      return res;
    });
  }

  async getSelectedPharmacy(pharmacyId) {
    this.pharmacySwitch.next(pharmacyId);
    localStorage.setItem('pharmacyId', pharmacyId);
  }


  logout() {
    localStorage.removeItem('script-capture');
    localStorage.removeItem('pharmacyId');
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    }
  }


  fetch_particularglobalProdcut(user: any, globalProdID: any, platform: any, filteredResponse = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    let filtered_Response = filteredResponse ? 'true' : 'false'
    return this.http.get(this.baseURL + 'pharmacyProduct/getSpecific/globalProducts/' + globalProdID + '/' + platform._id + '?filteredResponse=' + filtered_Response, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  loginPhone(body, platform: any) {
    return this.http.post(this.baseURL + 'login/loginPhone/' + platform._id, body).toPromise().then(async (resp: any) => {
      return resp;
    })
  }


  search_Customer(user, searchValue, searchType, platform, orderStatus = '') {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId')
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });

    return this.http.get(this.baseURL + 'users/searchUser?platformId=' + platform._id + '&' + searchType + '=' + searchValue, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  verify_otp(body, platform: any) {
    return this.http.post(this.baseURL + 'login/verifyOtp/' + platform._id, body).toPromise().then(async (resp: any) => {
      await this.storage.saveData('script-capture', JSON.stringify(resp));
      return resp;
    }).catch(err => {
      return err;
    })
  }

  getAllPharmacy() {
    return this.http.get(this.baseURL + 'pharmacy/listAll/noAuthCheck').toPromise().then(async (res: any) => {
      return res;
    })
  }


  // getMimeType(fileUrl: any): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/octet-stream', // set a default content type
  //     'Access-Control-Allow-Origin': '*' // set CORS headers if needed
  //   });
  //
  //   return this.http.head('https://ttbackendstorage.s3.ap-southeast-2.amazonaws.com/2024-02-08T11%3A56%3A12.455Z2024-02-08T11_44_31.558ZScript%20%285%29%20%281%29.jpg',
  //     {headers, 'observe': 'response'}).pipe(map(response => {
  //       const contentType = response.headers.get('Content-Type');
  //       return contentType || 'application/octet-stream'; // return default if Content-Type is not available
  //     }),
  //   );
  // }


  generateQrCode(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.token + '',
      'client_id': user.client_id,

    });
    return this.http.get(this.baseURL + 'googleAuth/generate-secret/' + user.user._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  verify_two_faAuth(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.token + '',
      'client_id': user.client_id,
    });
    return this.http.post(this.baseURL + 'googleAuth/verify-2fa/' + user.user._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  editUser(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'users/updateSpecific/' + user.user._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  createProduct(user: any, body: any, GL_product) {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'pharmacyProduct/addProductToPharmacy/' + GL_product._id + '/' + this.globalPharmcySelected, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  create_global_prod(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'pharmacyProduct/create', body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetchProductsList_of_pharmacy(user: any, pharmacyId, platform, pagenum: number, product = '', limit) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    if (product === '') {
      return this.http.get(this.baseURL + 'pharmacyProduct/listSpecific/' + platform._id + '/' + pharmacyId + '?page=' + pagenum + '&limit=' + limit, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    } else {
      return this.http.get(this.baseURL + 'pharmacyProduct/listSpecific/' + platform._id + '/' + pharmacyId + '?page=' + pagenum + '&limit=10&productName=' + product, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    }

  }

  loadProductsList_of_pharmacy(user: any, pharmacyId, platform) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'pharmacyProduct/listSpecific/' + platform._id + '/' + pharmacyId + '?page=1&limit=20000',
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  isAuthenticated() {
    return true;
  }

  deleteProduct(user: any, product: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.delete(this.baseURL + 'pharmacyProduct/' + product._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  deletePharcyProduct(user: any, product, platform) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.delete(this.baseURL + 'pharmacyProduct/deletePharmacyData/'
      + platform._id + '/' + product._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  editProduct(user: any, product: any, body: any, platform) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'pharmacyProduct/updatePharmacyData/' + platform._id + '/' + product._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetchCustomers(user: any, page, searchType = '', searchValue = '') {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    if (searchValue !== '' && searchType !== '') {
      return this.http.get(this.baseURL + 'pharmacyUser/getUserByPharmacy/' + localStorage.getItem('pharmacyId') + '?page=' + page + '&limit=10&' + searchType + '=' + searchValue, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    } else {
      return this.http.get(this.baseURL + 'pharmacyUser/getUserByPharmacy/' + localStorage.getItem('pharmacyId') + '?page=' + page + '&limit=10', {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    }

  }

  update_pharmacy_user(customer: any, user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.patch(this.baseURL + 'pharmacyUser/updateSpecific/' + customer._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  update_user(customer: any, user: any, body: any) {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.post(this.baseURL + 'pharmacyUser/verifyPharmacyUser/' + customer._id + '/' + this.globalPharmcySelected, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  update_user_details(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'users/updateSpecific/' + user.auth.data._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


  fetchOrders_by_date(user: any, startDate: string, endDate: string, pagenum: number, status = '') {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });

    if (status === '') {
      return this.http.get(this.baseURL + 'orders/getOrderByDate/' + this.globalPharmcySelected +
        '/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?page=' + pagenum + '&pageSize=10&partialShipment=false', {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    } else {
      return this.http.get(this.baseURL + 'orders/getOrderByDate/' + this.globalPharmcySelected +
        '/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?page=' + pagenum + '&pageSize=10&partialShipment=false' + '&status=' + status, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    }


  }


  fetch_audit_logs(user: any, startDate: string, endDate: string, page, limit = 10, tableContent = '') {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    if (tableContent !== '') {
      return this.http.get(this.baseURL + 'auditLog/getLogsByDate/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?page=' + page + '&perpageLimit=' + limit + '&tableContent=' + tableContent, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    } else {
      return this.http.get(this.baseURL + 'auditLog/getLogsByDate/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?page=' + page + '&perpageLimit=' + limit, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    }

  }


  upload_csv(user: any, file: File, platform: any) {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    // @ts-ignore
    const formData = new FormData();
    formData.append('pharmacyProductsFile', file, file.name);
    return this.http.post(this.baseURL + 'uploadcsv/uploadPharmacyProducts/' + this.globalPharmcySelected + '/' + platform._id, formData, {'headers': headers})
      .toPromise().then((resp: any) => {
        return resp;
      });
  }

  downloadFile() {
    const url = 'assets/csv/SampleProducts.xlsx'; // Replace with your PDF URL
    const headers = new HttpHeaders({
      'Content-Type': 'application/xlsx',
      'Accept': 'application/xlsx'
    });

    this.http.get(url, {'responseType': 'blob', 'headers': headers})
      .subscribe((response: any) => {
        const blob = new Blob([response], {'type': 'application/xlsx'});
        const filename = 'downloaded.xlsx'; // Replace with desired filename
        saveAs(blob, filename);
      });
  }


  fetchOrders(user: any, status: string, pagenum: number, pharmacy) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    if (status === 'partialShipment') {
      return this.http.get(this.baseURL + 'orders/pendingShipment/' + pharmacy +
        '?page=' + pagenum + '&pageSize=10&partialShipment=true', {'headers': headers}).toPromise().then(
        async (resp: any) => {
          return resp;
        });
    } else {
      return this.http.get(this.baseURL + 'orders/' + status + '/' + pharmacy +
        '?page=' + pagenum + '&pageSize=10&partialShipment=false', {'headers': headers}).toPromise().then(
        async (resp: any) => {
          return resp;
        });
    }
  }

  fetchStaff(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'users/listStaff/' +
      localStorage.getItem('pharmacyId'), {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fill_exel(user: any, platform: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'pharmacyProduct/csvDumpForProducts/' + platform._id + '/' + pharmacy, {'headers': headers}
    ).toPromise().then(async (res: any) => {
      return res;
    });
  }

  createStaff(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'users/staffRegister', body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch((err) => {
      return err;
    });
  }

  deleteStaff(user: any, staff: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.delete(this.baseURL + 'users/' + staff._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


  removePharmacy_for_Admin(user: any, admin: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'users/removePharmacy/' + admin._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  AddPharmacy_for_Admin(user: any, admin: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'users/addPharmacy/' + admin._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch((err => {
      return err;
    }));
  }


  deletePharmacy(user: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.delete(this.baseURL + 'pharmacy/' + pharmacy._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  editstaff(user: any, body: any, staff: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'users/updateSpecific/' + staff._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  createPharmacy(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'pharmacy/create', body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetchAllPharmacy(user: any, page: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'pharmacy/listAll/pharmacy?page=' + page + '&perpageLimit=10', {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  editPharmacy(user: any, body: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'pharmacy/updateSpecific/' + pharmacy._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  updateOrderStatus(user: any, body: any, order: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'orders/updateSpecific/' + order._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetchUser(user: any) {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'users/getSpecific/' + user.auth.data._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  updateUserProfile(user: any, body: any) {
    this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'users/updateSpecific/' +
      user.auth.data._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetch_pharmacy_user(user: any, customer: any, pharmacy) {
    // this.globalPharmcySelected = localStorage.getItem('pharmacyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'pharmacyUser/getDetails/' +
      customer._id + '/' + pharmacy, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


  createCategory(user: any, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'pharmacyCategory/create', body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


  fetchAllCategory(user: any, platform: any, categoryName = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    if (categoryName === '') {
      return this.http.get(this.baseURL + 'pharmacyCategory/listCategory/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    } else {
      return this.http.get(this.baseURL + 'pharmacyCategory/listCategory/' + platform._id + '?categoryName=' + categoryName, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    }

  }

  deleteCategory(user: any, category: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.delete(this.baseURL + 'pharmacyCategory/' + category._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetch_my_orders(user: any, platform: any, page: number, userId, preOrders = false, status = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    if (preOrders) {
      return this.http.get(this.baseURL + 'orders/getUserOrders/' + platform._id + '/' + userId + '?page=' + page + '&limit=10&preOrders=true', {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    } else if (status !== '') {
      return this.http.get(this.baseURL + 'orders/getUserOrders/' + platform._id + '/' + userId + '?page=' + page + '&limit=10&status=' + status, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    } else {
      return this.http.get(this.baseURL + 'orders/getUserOrders/' + platform._id + '/' + userId + '?page=' + page + '&limit=10', {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      });
    }

  }

  fetch_pharmacy_user_verified(user: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'pharmacyUser/getVerifiedUserPharmacy/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


  fetch_verification_note(user: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'pharmacyUser/listNotes/' + pharmacy + '/' + user.auth.data._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  add_verification_note(user: any, pharmacy: any, body: any, customer: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'pharmacyUser/addNote/' + pharmacy._id + '/' + customer.userId._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetch_pharmacy_user_status(user: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'pharmacyUser/userVerification/' + user.auth.data._id + '/' + pharmacy._id,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetch_pharmacy_postcode(user: any, platform: any, searchBy: string, value: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseURL + 'pharmacy/postCodeOrState/getPharmacyByPostCode/' + platform._id + '?' + searchBy + '=' + value, {},
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  getMedication_token_scan(user: any, token: string, selectedCustomer, selectedPharmacy, orderby) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    if (orderby === 'Customer') {
      return this.http.get(this.baseURL + 'orders/tokenScan/' + token,
        {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.get(this.baseURL + 'orders/tokenScan/' + token + '?pharmacyId=' + selectedPharmacy._id
        + '&userId=' + selectedCustomer._id,
        {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    }

  }

  sendReciept(user, body, order, isFirstPaymentForOrder, isAdditionalCostForMainOrder = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });

    if (isAdditionalCostForMainOrder) {
      return this.http.post(this.baseURL + 'orders/updatePayment/' + order._id + '?isAdditionalCostForMainOrder=' + isAdditionalCostForMainOrder + '', body,
        {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.post(this.baseURL + 'orders/updatePayment/' + order._id + '?isFirstPaymentForOrder=' + isFirstPaymentForOrder, body,
        {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    }

  }

  createOrder(user: any, orderObject: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.post(this.baseURL + 'orders/create', orderObject,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  upload_licence(user: any, pharmacyUser: any, file: any, type: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    // @ts-ignore
    const formData = new FormData();
    formData.append('document', file, file.name);
    return this.http.post(this.baseURL + 'pharmacyUser/uploadDocument/' + pharmacyUser._id + '/' + type, formData, {'headers': headers})
      .toPromise().then((resp: any) => {
        return resp;
      });
  }

  upload_product_image(user: any, file: any, product) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    // @ts-ignore
    const formData = new FormData();
    formData.append('globalProduct', file, file.name);
    return this.http.post(this.baseURL + 'pharmacyProduct/uploadProduct/' + product._id, formData, {'headers': headers})
      .toPromise().then((resp: any) => {
        return resp;
      });
  }


  update_order(user: any, body: any, order: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.patch(this.baseURL + 'orders/updateSpecific/' + order._id, body,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  refund_Order(user: any, order: any, status: string, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });

    return this.http.post(this.baseURL + 'orders/refund/' + order._id + '/' + status, body,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  update_note(user: any, body: any, order: any, customerNote = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'orders/add-note/' + order._id + '?customerNote=' + String(customerNote), body,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }


  deleteNote(user, order, note) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });

    return this.http.delete(this.baseURL + 'orders/delete-note/' + order._id + '/' + note._id,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });

  }


  fetch_customer_phone(user: any, phoneNumber) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'users/phone/91' + phoneNumber,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  accept_Order(user: any, order: any, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.post(this.baseURL + 'orders/acceptOrder/' + order._id, body,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  fetchAllOrder(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    return this.http.get(this.baseURL + 'orders/allOrders',
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  createPharmacy_user(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.post(this.baseURL + 'pharmacyUser/create', body,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_orders_data_status(user: any, start: any, end: any, status, pharmacy) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.get(this.baseURL + 'orders/dashboard/' + moment(start).format().split('+')[0] + '/'
      + moment(end).format().split('+')[0] + '/' + status + '/' + pharmacy, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    });
  }

  fetch_user_address(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.get(this.baseURL + 'users/listofUserAddress/' + user.auth.data._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  create_Address(body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + user.auth.token + '',
      // 'client_id': user.auth.client_id

    });
    return this.http.post(this.baseURL + 'users/addAddress', body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  async fetch_pharmacy_platformId(user: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.get(this.baseURL + 'pharmacy/listPharmacy/platform/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_catetories(user: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.get(this.baseURL + 'pharmacyCategory/listCategory/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  move_To_shipped(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.post(this.baseURL + 'orders/updateOrdersToShipped', body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_particular_pharmacy(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.get(this.baseURL + 'pharmacy/getPharmacy/' + localStorage.getItem('pharmacyId'), {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  deleteAddress(user: any, address: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.delete(this.baseURL + 'users/addresses/' + address._id + '/'
      + user.auth.data._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_global_prods(user: any, platform: any, pagenum: number, limit = 10, categoryName = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id
    });
    if (categoryName === '') {
      return this.http.get(this.baseURL + 'pharmacyProduct/globalProducts/productByPlatform/' + platform._id
        + '?page=' + pagenum + '&limit=' + limit, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.get(this.baseURL + 'pharmacyProduct/globalProducts/productByPlatform/' + platform._id
        + '?page=' + pagenum + '&limit=' + limit + '&categoryName=' + categoryName, {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    }

  }

  update_global_products(user, product: any, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.patch(this.baseURL + 'pharmacyProduct/' + product._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  delete_User(user: any, userData, deletePrescription = 'false') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.delete(this.baseURL + 'users/' + userData._id + '?deletePresciption=' + deletePrescription, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  create_platform(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });

    return this.http.post(this.baseURL + 'platform/create', body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_all_platform(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.get(this.baseURL + 'platform/getAllPlatfrom', {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  deletePlatform(user, platform) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.delete(this.baseURL + 'platform/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  edit_Platform(user, platform, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    return this.http.patch(this.baseURL + 'platform/updateSpecific/' + platform._id, body,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  upload_platform_logo(user, platform, file) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id

    });
    // @ts-ignore
    const formData = new FormData();
    formData.append('logo', file, file.name);
    return this.http.post(this.baseURL + 'platform/upload/' + platform._id, formData, {'headers': headers})
      .toPromise().then((resp: any) => {
        return resp;
      });
  }

  fetch_user_detail(user) {
    if (!user) {
      this.router.navigate(['/pages/launch']);
      return;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'users/getSpecific/' + user.auth.data._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  search_global_prd_Name(user, productName, page = 1, limit = 10) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'pharmacyProduct/productName/' + productName + '?page=' + page + '&limit=' + limit, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  uploadScript_image(user: any, file: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    const formData = new FormData();
    formData.append('uploadedScripts', file, file.name);

    return this.http.post(this.baseURL + 'orders/upload', formData, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_superAdmin_Platform(user: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });

    return this.http.get(this.baseURL + 'users/getSuperAdmin/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_pharmacy_admin(user: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'users/getAdmins/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

// dashboard api on platform level.

  super_Admin_dashboardUpdates(user: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });

    return this.http.get(this.baseURL + 'platform/dashboard/' + platform._id, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  re_order(user: any, order: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });

    return this.http.post(this.baseURL + 'orders/reOrder/' + order._id, body, {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // user pharmacy order

  user_pharmacy_order(user: any, pharmacy: any, customer: any, page = 1, status = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });

    if (status === '') {
      return this.http.get(this.baseURL + 'orders/getUserOrders/ofPharmacy/' + customer?.userId?._id + '/' + pharmacy + '?page=' + page + '&pageSize=10',
        {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.get(this.baseURL + 'orders/getUserOrders/ofPharmacy/' + customer?.userId?._id + '/' + pharmacy + '?page=' + page + '&pageSize=10&status=' + status,
        {'headers': headers}).toPromise().then(async (res: any) => {
        return res;
      }).catch(err => {
        return err;
      });
    }


  }

  reOrder_MultiOrders(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });

    return this.http.post(this.baseURL + 'orders/reorder/multipleorders/preorders', body,
      {'headers': headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // forgotpassword through phonenumber

  forgotpassword(body: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(this.baseURL + 'login/forgotPassword/' + platform._id, body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // change_password through OTP

  changePassword(body: any, platform: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseURL + 'login/changePassword/verifyOtp/' + platform._id, body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // fetchIssuedOrders

  fetchIssuedOrders(user: any, phamacyId: any, pagenum: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'orders/issueReported/true/' + phamacyId._id + '?page=' + pagenum + '&pageSize=10', {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })

  }

  // api call of combined version to get nearby pharmacies based on postcode and filter by radius

  fetchPharmacyByRadius(user, postcode) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get(this.baseURL + 'pharmacy/getCoordinates/getPharmacyData/nearBy-Pharmacies/' + postcode, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to get order by orderId and this api will recalculate the total amount of orders

  getOrder_by_Id(user, orderId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'orders/listSpecific/' + orderId, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

// api to search order by orderNo, name of customer, customer phone number, email of customer

  searchOrder(user, pharmacy, searchBy = 'customerName', value, pagenum, status = '', ForOrderHistory = true) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    let notForOrderHistory = ForOrderHistory ? 'true' : 'false';
    if (status === '') {
      return this.http.get(this.baseURL + 'orders/searchOrder/filteredResponse/' + pharmacy._id + '?' + searchBy + '='
        + value + '&page=' + pagenum + '&limit=10&filterSevenDays=true&notForOrderHistory=' + notForOrderHistory, {headers}).toPromise().then((resp) => {
        return resp;
      }).catch(err => {
        return err;
      })
    } else {
      if (status === 'issues') {
        return this.http.get(this.baseURL + 'orders/searchOrder/filteredResponse/' + pharmacy._id + '?' + searchBy + '='
          + value + '&page=' + pagenum + '&limit=10&issueReported=true&notForOrderHistory=' + notForOrderHistory, {headers}).toPromise().then((resp) => {
          return resp;
        }).catch(err => {
          return err;
        })
      } else {
        if (status === 'partialShipment') {
          return this.http.get(this.baseURL + 'orders/searchOrder/filteredResponse/' + pharmacy._id + '?' + searchBy + '='
            + value + '&page=' + pagenum + '&limit=10' + '&filterSevenDays=true&needPartialShipment=true&notForOrderHistory=' + notForOrderHistory, {headers}).toPromise().then((resp) => {
            return resp;
          }).catch(err => {
            return err;
          })
        } else {
          return this.http.get(this.baseURL + 'orders/searchOrder/filteredResponse/' + pharmacy._id + '?' + searchBy + '='
            + value + '&page=' + pagenum + '&limit=10' + '&status=' + status + '&filterSevenDays=true&needPartialShipment=false&notForOrderHistory=' + notForOrderHistory, {headers}).toPromise().then((resp) => {
            return resp;
          }).catch(err => {
            return err;
          })
        }

      }

    }
  }

  searchProductByName(user, product: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'pharmacyProduct/productName/' + product, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to trigger the email for forgot password

  sendPassword_reset_link(body, data, platform, type: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (type === 'email') {
      return this.http.post(this.baseURL + 'login/triggerEmailForForgotPassword/' + platform._id + '?email=' + data, body, {headers}).toPromise().then((resp) => {
        return resp;
      }).catch(err => {
        return err;
      })
    } else {
      return this.http.post(this.baseURL + 'login/triggerEmailForForgotPassword/' + platform._id + '?phoneNumber=' + data, body, {headers}).toPromise().then((resp) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }
  }

  // api to delete orders

  deleteOrder(user, order) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.delete(this.baseURL + 'orders/' + order._id, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to list the category based on filtration

  fetchOrderIssues_category(user, platform) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'orders/issueCategory/listCategory/' + platform._id + '/' + localStorage.getItem('pharmacyId') + '?isForDropDown=true', {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to create the category based on filtration
  create_OrderIssues_category(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.post(this.baseURL + 'orders/issueCategory/create', body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to delete the category based on filtration

  delete_OrderIssues_category(user, category) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.delete(this.baseURL + 'orders/issueCategory/deleteCategory/' + category._id, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

//   list the reported issues on filter
  list_reported_issue_on_filter(user, category) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.get(this.baseURL + 'orders/issueReported/true/' + localStorage.getItem('pharmacyId') + '?categoryId=' + category, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

//   Update partial order

  update_partial_order(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.post(this.baseURL + 'orders/updateOrders/toPartialShipments', body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to push the additional cost payment for specific order.

  additionalCostforOrder(user, body, order) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.post(this.baseURL + 'orders/addAdditionalCost/' + order._id, body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call  make the orders to completed state (partial orders)

  updatePartialToComplete(user, order) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.post(this.baseURL + 'orders/completeOrders/' + order._id, {}, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

//   update the shipping details for Order
  updateShippingDetailsForOrder(user, order, body, isPartialShipment) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.post(this.baseURL + 'orders/updateShippingDetails/' + order._id + '/' + isPartialShipment, body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // upload of pdf file

  uploadPdf(user, files: FileList | any, globalproduct) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    const formData = new FormData();
    let filenames = '';
    for (const file of files) {
      formData.append('resources', file, file.name);
      filenames += file.name + ','
    }
    filenames = filenames.substring(0, filenames.length - 1);
    return this.http.post(this.baseURL + 'pharmacyProduct/uploadPdf/additionalResources/' + globalproduct._id + '?filenames=' + filenames, formData, {'headers': headers})
      .toPromise().then((resp: any) => {
        return resp;
      });
  }


  deleteFile(user, file, globalproduct) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.delete(this.baseURL + 'pharmacyProduct/deleteUploadedObject/' + globalproduct._id + '/' + file._id, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // fetch category labels
  fetchCategoryLabels(user, platformId, labelName = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    if (labelName === '') {
      return this.http.get(this.baseURL + 'pharmacyCategory/categoryProperties/listCategoryProperties/' + platformId, {headers}).toPromise().then((resp) => {
        return resp;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.get(this.baseURL + 'pharmacyCategory/categoryProperties/listCategoryProperties/' + platformId + '?labelName=' + labelName, {headers}).toPromise().then((resp) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }

  }

  //create category labels

  create_category_labels(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.post(this.baseURL + 'pharmacyCategory/categoryProperties/create', body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // update category details

  update_category_details(user, body, category) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.patch(this.baseURL + 'pharmacyCategory/updateSpecific/' + category._id, body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // delete label
  deleteLabel(user, label) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.delete(this.baseURL + 'pharmacyCategory/categoryProperties/' + label._id, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // update label detatils

  update_label_details(user, body, label) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
      'client_id': user.auth.client_id,
    });
    return this.http.patch(this.baseURL + 'pharmacyCategory/categoryProperties/updateSpecific/' + label._id, body, {headers}).toPromise().then((resp) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


}
