import {Component, ViewContainerRef, OnInit, OnDestroy, HostListener} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './shared/auth/auth.service';
import {TimeActivityService} from './shared/storage/time-activity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  platform: any;

  constructor(private router: Router, private authService: AuthService,
              private inactivityTimer: TimeActivityService) {
  }

  ngOnInit() {

    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));

    const body = {'url': window.location.origin};
    // const body = {'url': 'https://pop.posdirect.com.au'};
    // const body = {'url': 'https://login.scriptit.com.au'};

    this.authService.fetchPlatForm(body).then((resp: any) => {
      localStorage.setItem('platform', JSON.stringify(resp.data));
    })
    this.platform = JSON.parse(localStorage.getItem('platform'));
    const root = document.documentElement;
    if (this.platform !== 'No platform found for the requested URL') {
      root.style.setProperty('--primary-color', this.platform.primaryColour);
      root.style.setProperty('--secondary-color', this.platform.secondaryColour);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('window:mousemove') onMouseMove() {
    this.inactivityTimer.resetTimer();
  }

  @HostListener('window:click') onClick() {
    this.inactivityTimer.resetTimer();
  }

  @HostListener('window:keypress') onKeyPress() {
    this.inactivityTimer.resetTimer();
  }

}
