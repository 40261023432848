<div class="row">
  <div class="col-sm-12">
    <div class="content-header">Place Order</div>
  </div>
</div>
<section id="ngx">
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step [stepTitle]="'Step 1'" [navigationSymbol]="{ symbol: '&#xf19c;', fontFamily: 'FontAwesome' }">
            <form>
              <h4 class="head text-center">Select Pharmacy </h4>
              <br/>
              <div class="row" *ngIf="orderBy === 'Customer'">
                <div class="col-12">
                  <div class="row mt-2">
                    <div class="col-md-4 col-12">
                      <label>SELECT FROM YOUR VERIFIED PHARMACY</label>
                      <ng-select [items]="pharmacyList" bindLabel="pharmacy_name" bindValue="_id"
                                 [(ngModel)]="selectedPharmacyState"
                                 [ngModelOptions]="{standalone: true}"
                                 (change)="getPharmacy($event,postcode)"
                                 placeholder="Select Pharmacy"
                                 name="pharmacy">
                      </ng-select>
                    </div>
                    <div class="col-md-1 text-center pt-3">
                      <p class="font-weight-bold">OR</p>
                    </div>
                    <div class="col-md-3 col-12">
                      <label>SEARCH BY STATE</label>
                      <ng-select (change)="OnChangeState($event,postcode)" [(ngModel)]="selectedState"
                                 [ngModelOptions]="{standalone: true}"
                                 placeholder="Select State">
                        <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-1 text-center pt-3">
                      <p class="font-weight-bold">OR</p>
                    </div>
                    <div class="col-md-3 col-12">
                      <label>SEARCH BY POSTCODE</label>
                      <fieldset>
                        <div class="input-group">
                          <input type="text" #postcode class="form-control"
                                 maxlength="4"
                                 (change)="validatepostcode(postcode)"
                                 placeholder="Postcode">
                          <div class="input-group-append">
                            <button class="btn btn-secondary"
                                    required
                                    (click)="searh_phar_post(postcode)"
                                    type="button"><i class="ft-search"></i></button>
                          </div>
                        </div>
                        <p class="text-danger" *ngIf="validationObject.invalid">Invalid post code</p>
                        <p class="text-danger" *ngIf="validationObject.required">Postcode is required</p>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="row d-sm-block d-lg-none">-->
              <!--                <div class="col-md-5 col-12">-->
              <!--                  <label>SELECT RANGE TO ADD POSTCODE</label>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="table-responsive d-none d-lg-block d-sm-none" *ngIf="post_code_pharmacyList.length > 0">
                <table class="table m-0">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Delivery Type</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let pharmacy of post_code_pharmacyList;let i = index;">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ pharmacy.pharmacy_name }}</td>
                    <td>{{ pharmacy.address + ' ' + pharmacy.suburb + ' ' + pharmacy.state + ' ' + pharmacy.postcode }}</td>
                    <td>
                      <span *ngIf="pharmacy.deliveryType === 'delivery'">Delivery</span>
                      <span *ngIf="pharmacy.deliveryType === 'pickup'">Pickup</span>
                      <div class="flex" *ngIf="pharmacy.deliveryType === 'both'">
                        <span>Delivery & Pickup</span>
                      </div>
                    </td>
                    <td>
                      <button class="btn"
                              [ngClass]="this.index === i ? 'btn secondary-btn' : 'btn primary-btn'"
                              (click)="SelectedPharmacy(pharmacy,i,content6)">Select
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-lg-none d-sm-block table-responsive" *ngIf="post_code_pharmacyList.length > 0">
                <table class="table table-width">
                  <thead>
                  <tr>
                    <th class="font-small-2">Name</th>
                    <th class="font-small-2">Address</th>
                    <th class="font-small-2" colspan="2">Delivery Type</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let pharmacy of post_code_pharmacyList;let i = index;" style="height: 40px;"
                      [ngStyle]="{'background-color': i % 2 === 0 ? '#ffffff' : '#f2f2f2'}">
                    <td class="font-small-2 px-0 py-0">{{ pharmacy.pharmacy_name }}</td>
                    <td class="font-small-2 px-1 py-1">
                      {{ pharmacy.address }}
                      {{ pharmacy.suburb }}
                      {{ pharmacy.state }}
                      {{ pharmacy.postcode }}
                    </td>
                    <td style="width: 200px !important;">
                      <span class="font-small-2" *ngIf="pharmacy.deliveryType === 'delivery'">Delivery</span>
                      <span class="font-small-2" *ngIf="pharmacy.deliveryType === 'pickup'">Pickup</span>
                      <div class="flex" *ngIf="pharmacy.deliveryType === 'both'">
                        <span>Delivery & Pickup</span>
                      </div>
                    </td>
                    <td>
                      <span class="ft-play-circle" [ngClass]="this.index === i ? 'text-success' : 'text-primary'"
                            (click)="SelectedPharmacy(pharmacy,i,content6)"></span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <span *ngIf="post_code_pharmacyList.length > 0" class="text-info mt-1 d-lg-none d-sm-block"><i
                  class="ft-info mr-1"></i>Please scroll across for more detail</span>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="form-group text-center">
                    <button type="button" class="btn primary-btn" (click)="gotoNext()" [disabled]="!selectedPharmacy">
                      Next
                      <span style="margin-left:10px;"><i class="ft-chevron-right"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div class="row mt-2">
              <div class="col-md-12 col-12">
                <p>
                  <button type="button" class="btn mr-2 help-btn">Help&nbsp;{{ platform.number }}</button>
                </p>
              </div>
            </div>
          </aw-wizard-step>
          <aw-wizard-step [stepTitle]="'Step 2'" [navigationSymbol]="{ symbol: '&#xf15b;', fontFamily: 'FontAwesome' }">
            <form>
              <h4 class="head text-center">Upload Script</h4>
              <br/>
              <div class="row d-none d-sm-none d-lg-block">
                <div class="col-md-12 col-12">
                  <button class="btn primary-btn mt-2" (click)="open(ScriptOpen,'lg')"><i class="ft-plus mr-1"></i>Add
                    Medicine
                  </button>
                  <!--                  <button class="btn secondary-btn ml-1 mt-2" (click)="open(ScriptImage_Upload,'md')"><i-->
                  <!--                    class="ft-upload mr-1"></i>Upload Script Image / URL-->
                  <!--                  </button>-->
                </div>
              </div>
              <div class="row d-lg-none d-sm-block">
                <div class="col-md-12 col-12">
                  <button class="btn badge primary-btn mt-2 font-small-1" (click)="open(ScriptOpen,'lg')"><i
                    class="ft-plus mr-1"></i>Add Script
                  </button>
                  <!--                  <button class="btn badge secondary-btn font-small-1 ml-4 mt-2 float-right"-->
                  <!--                          (click)="open(ScriptImage_Upload,'md')"><i class="ft-camera mr-1"></i>Upload Script / URL-->
                  <!--                  </button>-->
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-content">
                      <div class="card-body table-responsive" id="card_body">

                        <!--                        <table class="table text-center m-0" *ngIf="this.rows.length > 0">-->
                        <!--                          <thead>-->
                        <!--                          <tr>-->
                        <!--                            <th>Checkbox</th>-->
                        <!--                            <th class="text-left">Product Name</th>-->
                        <!--                            <th>Quantity</th>-->
                        <!--                            <th>Price</th>-->
                        <!--                            <th>Repeats</th>-->
                        <!--                            <th>Instruction</th>-->
                        <!--                            <th>Note</th>-->
                        <!--                            <th>Actions</th>-->
                        <!--                          </tr>-->
                        <!--                          </thead>-->
                        <!--                          <tbody>-->
                        <!--&lt;!&ndash;                          <ng-container *ngFor="let script of rows;let scriptIndex = index;">&ndash;&gt;-->
                        <!--&lt;!&ndash;                            <tr *ngFor="let medicine of script.data;let i = index">&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <div class="checkbox">&ndash;&gt;-->
                        <!--&lt;!&ndash;                                  <input type="checkbox" id="{{medicine.product_name}}"&ndash;&gt;-->
                        <!--&lt;!&ndash;                                         (click)="setItem(medicine,$event)">&ndash;&gt;-->
                        <!--&lt;!&ndash;                                  <label for="{{medicine.product_name}}"></label>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              </td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td class="text-left">{{medicine.product_name}}</td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td>{{medicine.quantity}}</td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td>${{medicine.price}}</td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td>{{medicine.repeats}}</td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td>{{medicine.instruction}}</td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td>{{medicine.note}}</td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              <td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                &lt;!&ndash;                                (confirmModal,medicine,scriptIndex,script)&ndash;&gt;&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <button class="badge bg-light-danger btn"&ndash;&gt;-->
                        <!--&lt;!&ndash;                                        (click)="openConfimation(confirmModal,medicine,scriptIndex,script)">&ndash;&gt;-->

                        <!--&lt;!&ndash;                                  &lt;!&ndash;                                        (click)="remove(medicine,scriptIndex,script)">&ndash;&gt;&ndash;&gt;-->
                        <!--&lt;!&ndash;                                  Remove&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </button>&ndash;&gt;-->
                        <!--&lt;!&ndash;                              </td>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            </tr>&ndash;&gt;-->
                        <!--&lt;!&ndash;                          </ng-container>&ndash;&gt;-->

                        <!--                          </tbody>-->
                        <!--                        </table>-->
                        <table class="table text-center m-0" *ngIf="this.Medicinerows.length > 0">
                          <thead>
                          <tr>
                            <th>Product Name</th>
                            <th></th>
                            <th>Token Number <sup class="text-danger">*</sup></th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <!--                          <th>Repeats</th>-->
                            <!--                          <th>Instruction</th>-->
                            <!--                          <th>Note</th>-->
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let item of this.Medicinerows;let i = index;">
                            <!--                            <td>-->
                            <!--                              <div class="checkbox">-->
                            <!--                                <input type="checkbox" (click)="setItem(item,$event,token)"-->
                            <!--                                       [disabled]="token.value === '' || validation"-->
                            <!--                                       id="{{item.productName}}">-->
                            <!--                                <label for="{{item.productName}}"></label>-->
                            <!--                              </div>-->
                            <!--                            </td>-->
                            <td
                              class="cursor-pointer">
                               <span style="text-decoration: underline;" (click)="showGlobalProd(item.globalProductId)"> {{ item.productName }}
                                 <i class="float-right font-small-1 font-weight-bold" ngbTooltip="Backorder"
                                    placement="top"
                                    [ngClass]="item.isBackOrder ? 'ft-circle round bg-warning text-warning mt-1' : ''"></i></span>
                            </td>
                            <td>
                            <span class="btn secondary-btn badge" ngbTooltip="Script Upload" placement="top"
                                  (click)="AddScriptImage(ScriptImage_Upload,'md',i)">
                             <i class="ft-upload"></i>
                            </span>
                            </td>
                            <td>
                              <div class="d-flex">
                                <input type="text" class="form-control square text-UpperCase" #token
                                       value="{{item.token}}"
                                       (change)="OnChangeToken(item,token,'token')"
                                       (keyup)="validateText(token)">
                              </div>
                              <br>
                              <p class="text-danger" *ngIf="validation">Invalid Token</p>
                            </td>
                            <td>${{ item.productCost }}</td>
                            <td>
                              <div class="btn-group" id="{{item._id}}">
                                <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                                   ngbTooltip="Update quantity" (click)="decrease(item)">-</a>
                                <a class="btn secondary-btn px-1 py-1">{{ item.quantity }}</a>
                                <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                                   ngbTooltip="Update quantity" (click)="increase(item)">+</a>
                              </div>
                            </td>
                            <td>
                              <button class="badge bg-light-danger btn"
                                      (click)="openConfimation(confirmModal1,item)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          <!--                        <ng-container *ngFor="let script of Medicinerows;let scriptIndex = index;">-->
                          <!--                          <tr *ngFor="let medicine of script.data;let i = index">-->
                          <!--                            <td>-->
                          <!--                              <div class="checkbox">-->
                          <!--                                <input type="checkbox" id="{{medicine.product_name}}"-->
                          <!--                                       (click)="setItem(medicine,$event)">-->
                          <!--                                <label for="{{medicine.product_name}}"></label>-->
                          <!--                              </div>-->
                          <!--                            </td>-->
                          <!--                            <td class="text-left">{{medicine.product_name}}</td>-->
                          <!--                            <td>${{medicine.price}}</td>-->
                          <!--                            <td>{{medicine.quantity}}</td>-->
                          <!--                            <td>{{medicine.repeats}}</td>-->
                          <!--                            <td>{{medicine.instruction}}</td>-->
                          <!--                            <td>{{medicine.note}}</td>-->
                          <!--                            <td>-->
                          <!--                              <button class="badge bg-light-danger btn"-->
                          <!--                                      (click)="openConfimation(confirmModal1,medicine,scriptIndex,script)">-->
                          <!--                                Remove-->
                          <!--                              </button>-->
                          <!--                            </td>-->
                          <!--                          </tr>-->
                          <!--                        </ng-container>-->

                          </tbody>
                        </table>
                      </div>
                      <span *ngIf="Medicinerows.length > 0" class="text-info mt-1 d-lg-none d-sm-block"><i
                        class="ft-info mr-1"></i>Please scroll across for more detail</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group text-center space-20 mt-2">
                <button uiSref="work" type="button" class="btn secondary-btn mr-1" awPreviousStep>
                                                    <span style="margin-right:10px;">
                                                        <i class="ft-chevron-left"></i>
                                                    </span> Previous
                </button>
                <button type="button" class="btn primary-btn" (click)="nextStep($event,wizard)">Next<span
                  style="margin-left:10px;"><i class="ft-chevron-right"></i>
                </span>
                </button>
              </div>
            </form>
            <div class="row mt-2">
              <div class="col-md-12 col-12">
                <p>
                  <button type="button" class="btn mr-2 help-btn">Help&nbsp;{{ selectedPharmacy?.phoneNumber }}</button>
                </p>
              </div>
            </div>
          </aw-wizard-step>
          <aw-wizard-step [stepTitle]="'Step 3'" [navigationSymbol]="{ symbol: '&#xf2bd;', fontFamily: 'FontAwesome' }">
            <h4 class="head text-center">ORDER SUMMARY</h4>
            <br/>
            <h5 class="font-weight-bold font-italic">Your Information</h5>
            <hr>
            <div class="row">
              <div class="col-md-6 col-12">
                <label class="font-weight-bold">Status</label><br>
                <p class="badge bg-light-success btn"
                   [ngClass]="userStatus === 'verified' ? 'bg-light-success' : 'bg-light-warning'">
                  <i class="ft-check-circle mr-1"
                     [ngClass]="userStatus === 'verified'? 'ft-check-circle' : 'ft-alert-triangle'"></i>{{ userStatus === 'verified' ? 'Verified' : 'Unverified' }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <label class="font-weight-bold">Name</label>
                <p>{{ this.selectedCustomer.firstName }} {{ this.selectedCustomer.middleName }} {{ this.selectedCustomer.lastName }}</p>
              </div>
              <div class="col-md-6 col-12">
                <label class="font-weight-bold">Mobile Number</label>
                <p>{{ this.selectedCustomer.number }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <label class="font-weight-bold">Email</label>
                <p>{{ this.selectedCustomer.email }}</p>
              </div>
              <div class="col-md-6 col-12 d-none d-sm-none d-lg-block">
                <label class="font-weight-bold">Address</label>
                <div class="d-flex">
                  <ng-select [items]="this.userAddress" bindLabel="completeAddress" bindValue="_id" class="size"
                             (change)="OnChangeAddress($event,content6)"
                             name="userAddress">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <b>{{ item.completeAddress }} <i class="{{item.icon}} float-right font-weight-bold"></i></b>
                    </ng-template>
                  </ng-select>
                  <button class="btn primary-btn ml-1" *ngIf="orderBy === 'Customer'"
                          (click)="openAddressModal(Address,'lg')"><i class="ft-plus"></i>Add Address
                  </button>
                </div>
              </div>
              <div class="col-12 d-lg-none d-sm-block">
                <label class="font-weight-bold">Address</label>
                <div class="d-flex">
                  <ng-select [items]="this.userAddress" bindLabel="completeAddress" bindValue="_id"
                             class="address-select"
                             (change)="OnChangeAddress($event,content6)"
                             name="userAddress">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <b>{{ item.completeAddress }} <i class="{{item.icon}} float-right font-weight-bold"></i></b>
                    </ng-template>
                  </ng-select>
                  <button class="btn badge secondary-btn font-small-3 ml-1" *ngIf="orderBy === 'Customer'"
                          (click)="openAddressModal(Address,'lg')"><i class="ft-plus"></i>Add Address
                  </button>
                </div>
              </div>
            </div>
            <div class="row"
                 *ngIf="(frontLicense!== '' && backLicense!== '') || medicareCard!== '' || pensionCard!== ''">
              <div class="col-12 col-md-12">
                <label class="font-weight-bold">Government Issued Photo ID</label><br>
                <div class="d-flex">
                  <img *ngIf="frontLicense!== ''" [src]="frontLicense" alt="" width="200" height="40"
                       class="img-thumbnail">
                  <img *ngIf="backLicense!== ''" [src]="backLicense" alt="" width="200" height="40"
                       class="ml-1 img-thumbnail">
                  <img *ngIf="medicareCard!== ''" [src]="medicareCard" alt="" width="200" height="40"
                       class="ml-1 img-thumbnail">
                  <img *ngIf="pensionCard!== ''" [src]="pensionCard" alt="" width="200" height="40"
                       class="ml-1 img-thumbnail">
                </div>
              </div>
            </div>
            <div class="row" *ngIf="scriptImage!== ''">
              <div class="col-12 col-md-12">
                <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                  <i
                    *ngIf="showUrl"
                    class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                    (click)="downloadScript(scriptImage)"></i>
                </label><br>
                <div *ngIf="showUrl">
                  <img class="cursor-pointer" src="{{this.scriptImage}}" alt="" width="60" height="40"
                       (click)="isCollapsed = !isCollapsed"
                       [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample1">
                  <div id="collapseExample1" [ngbCollapse]="isCollapsed">
                    <img src="{{this.scriptImage}}" alt="" width="400" height="400">
                  </div>
                </div>
                <div *ngIf="!showUrl">
                  <p>{{ scriptImage }}</p>
                </div>
              </div>
            </div>
            <br>
            <p class="font-weight-bold font-italic font-medium-2">Your Script
              <button class="btn badge primary-btn float-right" (click)="open(ScriptOpen,'lg','step-3')"><i
                class="ft-plus mr-1"></i>Add Medicine
              </button>
            </p>

            <hr>
            <div class="table-responsive">
              <table class="table text-center m-0" *ngIf="this.orderedItems.length > 0">
                <thead>
                <tr>
                  <th class="text-left">Product Name</th>
                  <th>Token <sup class="text-danger">*</sup></th>
                  <th>Script Info</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Repeats</th>
                  <th>Instruction</th>
                  <th>Note</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let medicine of this.orderedItems;let i = index;">
                  <td class="text-left">{{ medicine.product_name }}
                    <i class="float-right font-weight-bold font-small-1" ngbTooltip="Backorder"
                       placement="top"
                       [ngClass]="medicine.isBackOrder ? 'ft-circle round bg-warning text-warning mt-1' : ''"></i>
                  </td>
                  <td class="text-UpperCase">
                    <input type="text" class="form-control square text-UpperCase" #token value="{{ medicine?.token }}"
                           (change)="OnChangeToken1(medicine,token,'token')"
                           (keyup)="validateText(token)">
                  </td>
                  <td class="text-center">
                    <img *ngIf="medicine.scriptData.uploadedScript !== '' && medicine.scriptData.type !== 'url'"
                         class="cursor-pointer"
                         src="{{ medicine?.scriptData.uploadedScript }}"
                         alt="" width="60" height="40"
                         (click)="viewScript_image(medicine.scriptData.uploadedScript,view_script_image)">
                    <a *ngIf="medicine.scriptData.type === 'url'" target="_blank"
                       href="{{medicine.scriptData.uploadedScript}}" class="text-primary">
                      <span ngbTooltip="{{medicine.scriptData.uploadedScript}}" placement="top"
                            style="text-decoration: underline">Click Here</span>
                    </a>
                    <span *ngIf="medicine?.scriptData?.uploadedScript === ''" class="btn secondary-btn badge"
                          ngbTooltip="Script Upload" placement="top"
                          (click)="AddScriptImage(ScriptImage_Upload,'md',i)">
                             <i class="ft-upload"></i>
                            </span>
                  </td>
                  <td>
                    <div class="btn-group" id="{{medicine.product_name}}">
                      <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                         ngbTooltip="Update quantity" (click)="decrease(medicine,'step-3')">-</a>
                      <a class="btn secondary-btn px-1 py-1">{{ medicine.quantity }}</a>
                      <a class="btn secondary-btn px-1 py-1 font-medium-5" placement="top"
                         ngbTooltip="Update quantity" (click)="increase(medicine,'step-3')">+</a>
                    </div>
                  </td>
                  <td>${{ medicine.price }}</td>
                  <td>{{ medicine.repeats }}</td>
                  <td>{{ medicine.instruction }}</td>
                  <td>{{ medicine.note }}</td>
                  <td>
                    <button class="badge bg-light-danger btn"
                            (click)="openConfimation(confirmModal1,medicine,'step-3')">
                      Remove
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <span *ngIf="orderedItems.length > 0" class="text-info mt-1 d-lg-none d-sm-block"><i
              class="ft-info mr-1"></i>Please scroll across for more detail</span>
            <br>
            <h5 class="font-weight-bold font-italic">Selected Pharmacy</h5>
            <hr>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="col-12">
                  <label class="font-weight-bold">Pharmacy Name</label>
                  <p>{{ this.selectedPharmacy?.pharmacy_name }}</p>
                </div>
                <div class="col-12 mt-2">
                  <label class="font-weight-bold">Mobile Number</label>
                  <p>{{ this.selectedPharmacy?.phoneNumber }}</p>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="col-12">
                  <label class="font-weight-bold">Email</label>
                  <p>{{ this.selectedPharmacy?.email }}</p>
                </div>
                <div class="col-12">
                  <label class="font-weight-bold">Pharmacy Address</label>
                  <p>{{
                      this.selectedPharmacy?.address + ' ' + this.selectedPharmacy?.suburb + ' '
                      + this.selectedPharmacy?.state + ' ' + this.selectedPharmacy?.postcode
                    }}</p>
                </div>
              </div>
            </div>
            <br>
            <h5 class="font-weight-bold font-italic">Delivery Type <sup class="text-danger">*</sup></h5>
            <hr>
            <div class="d-flex">
                <span class="badge bg-light-primary btn"
                      *ngIf="this.selectedPharmacy?.deliveryType === 'delivery' || this.selectedPharmacy?.deliveryType === 'both'"
                      [ngClass]="deliverModal.delivery ? 'bg-light-secondary' : 'bg-light-primary'"
                      (click)="openDeliveryModal(content4,'sm',false)"> <i
                  class="ft-truck mr-1"></i>Delivery</span>

              <span class="badge bg-light-primary btn ml-1"
                    *ngIf="this.selectedPharmacy?.deliveryType === 'pickup' || this.selectedPharmacy?.deliveryType === 'both'"
                    [ngClass]="deliverModal.pickup ? 'bg-light-secondary' : 'bg-light-primary'"
                    (click)="openDeliveryModal(content4,'lg',true)"><i
                class="ft-shopping-bag mr-1"></i>Pickup</span>
            </div>
            <div class="form-group text-center space-20 mt-2 px-1 py-1">
              <button uiSref="work" type="button" class="btn secondary-btn mr-1" awPreviousStep>
                <span style="margin-right:10px;"><i class="ft-chevron-left"></i></span> Previous
              </button>
              <button type="button" class="btn primary-btn" (click)="confirmation(content3,'sm')">Submit
                <span style="margin-left:10px;"><i class="ft-check-circle"></i></span>
              </button>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 col-12">
                <p>
                  <button type="button" class="btn mr-2 help-btn">Help&nbsp;{{ selectedPharmacy?.phoneNumber }}</button>
                </p>
              </div>
            </div>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div>
</section>


<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>

<ng-template #confirmModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Medicine</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to remove this Medicine?</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="remove(modal)">Delete</button>
        <button class="btn btn-secondary ml-2" (click)="modal.dismiss('Cross click')">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>


<!--Token passing for erx-->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="scriptForm" (ngSubmit)="add(smallModal)">
      <h5 class="head text-center">Enter Token Number</h5>
      <br/>
      <div class="row">
        <div class="col-md-12 col-12">
          <label class="form-control-label">Token Number</label>
          <input class="form-control input-md token"
                 formControlName="token" type="text">
          <p *ngIf="scriptForm.controls['token'].hasError('pattern')" class="text-danger">Invalid Token number</p>
          <p *ngIf="submitted && scriptForm.controls['token'].hasError('required')" class="text-danger">Token is
            required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12 px-2 py-2 text-center">
          <button type="submit" class="btn primary-btn">
            Add
            <span style="margin-left:10px;"><i class="ft-plus"></i></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #content6 let-c="close" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Document!</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeMod(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="showMessageBlock">
      <p class="text-justify font-weight-bold">Please upload your government issued photo ID for pharmacy
        verification.</p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-success" (click)="createPharmacyUser()">Okay</button>
      </div>
    </div>
    <ng-container *ngIf="showUpload">
      <div class="row">
        <div class="col-md-6 col-6">
          <label class="form-label font-small-3">Select Document</label>
          <select id="licenseType" class="form-control square" (change)="OnChangeType($event)">
            <option selected>Select</option>
            <option value="DriversLicense">Drivers License</option>
            <option value="medicareCardNumber">Medicare Card</option>
            <option value="pensionCardNumber">Pension Card</option>
          </select>
        </div>
      </div>

      <div class="row mt-2" *ngIf="this.type === 'DriversLicense'">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload Front Side</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,'licenseFront')">
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload Back Side</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,'licenseBack')">
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="this.type === 'pensionCardNumber' || this.type === 'medicareCardNumber'">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,this.type)">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 text-center">
          <button class="btn secondary-btn" [disabled]="this.trackFileUpload" (click)="goNext()">Next</button>
        </div>
      </div>
    </ng-container>

  </div>
</ng-template>


<ng-template #verifyAddressModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Warning!</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="showMessageBlock">
      <p class="text-justify font-weight-bold">Please upload your government issued photo ID for pharmacy
        verification.</p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-success" (click)="createPharmacyUser()">Okay</button>
      </div>
    </div>
    <ng-container *ngIf="showUpload">
      <div class="row mt-2">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload Front Side</label>
            <input type="file" class="form-control-file" accept="image/*"
                   (change)="uploadLicence($event,'licenseFront')">
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload Back Side</label>
            <input type="file" class="form-control-file" accept="image/*"
                   (change)="uploadLicence($event,'licenseBack')">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 text-center">
          <button class="btn secondary-btn" (click)="closeMe()">Next</button>
        </div>
      </div>
    </ng-container>

  </div>
</ng-template>


<ng-template #content3 let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Warning!</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-justify">Are you certain about proceeding with the order to the selected pharmacy?</p>
    <div class="text-center">
      <button class="btn secondary-btn" [disabled]="lockButton" (click)="placeOrder(content3)">Yes</button>
    </div>
  </div>
</ng-template>


<ng-template #content4 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ pickup ? 'Pickup' : 'Delivery' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="pickup;else others">
      <p class="text-justify text-center font-weight-bold">Who is picking up?</p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn" (click)="selectMe('Me')" [ngClass]="choiceMade === 'Me' ? 'primary-btn' :'secondary-btn'">
          Me
        </button>
        <button class="btn ml-2" (click)="selectMe('Carrier')"
                [ngClass]="choiceMade === 'Carrier' ? 'primary-btn' : 'secondary-btn'">Carer
        </button>
      </div>
      <div class="px-1 py-2" *ngIf="showAuthTPAForm">
        <h4>Authorized TPA Details</h4>
        <div class="row">
          <div class="col-md-6">
            <label>Full Name</label>
            <input type="text" name="fullName" class="form-control square" #fullname>
          </div>
          <div class="col-md-6">
            <label>Relationship</label>
            <select name="relationship" class="form-control square" #relType>
              <option value="mother">Mother</option>
              <option value="father">Father</option>
              <option value="spouse">Spouse</option>
              <option value="brother">Brother</option>
              <option value="sister">Sister</option>
              <option value="grandParent">Grand parent</option>
              <option value="son">Son</option>
              <option value="daughter">Daugther</option>
            </select>
          </div>
        </div>
        <p class="mt-1"><b>Note:</b> TPA Must Provide Goverment issued ID on pickup that matches full Name</p>
        <div class="row">
          <div class="col-12 col-md-12 text-center">
            <label class="text-justify font-weight-bold">Pharmacy Address</label>
            <p>{{ this.selectedPharmacy.pharmacy_name }}
              <br> {{
                this.selectedPharmacy?.address + ' ' + this.selectedPharmacy?.suburb + ' '
                + this.selectedPharmacy?.state + ' ' + this.selectedPharmacy?.postcode
              }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 text-center px-2 py-2">
            <button class="btn primary-btn" (click)="fillData(fullname.value,relType.value,modal)">Submit</button>
          </div>
        </div>
      </div>
      <div class="row mt-1" *ngIf="choiceMade === 'Me'">
        <div class="col-12 col-md-12 text-center">
          <label class="text-justify font-weight-bold">Pharmacy Address</label>
          <p>{{ this.selectedPharmacy.pharmacy_name }}
            <br> {{
              this.selectedPharmacy?.address + ' ' + this.selectedPharmacy?.suburb + ' '
              + this.selectedPharmacy?.state + ' ' + this.selectedPharmacy?.postcode
            }}</p>
        </div>
      </div>
      <div class="row" *ngIf="choiceMade === 'Me'">
        <div class="col-12 col-md-12 text-center px-2 py-2">
          <button class="btn primary-btn" (click)="modal.dismiss('Cross-Click')">Submit</button>
        </div>
      </div>
    </div>
    <ng-template #others>
      <p class="text-justify font-weight-bold text-danger"> Delivery Fee:
        <span>${{ this.selectedPharmacy?.freightCharge }}</span></p>
      <p class="text-justify font-weight-bold">Your Delivery Address is:
        <br> <span class="font-weight-normal"> {{
            this.selectedAddress.address + ' ' + this.selectedAddress.suburb + ' '
            + this.selectedAddress.state + ' ' + this.selectedAddress.postcode
          }}</span></p>
      <div class="row">
        <div class="col-12">
          <label>Delivery Instructions</label>
          <textarea class="form-control square" cols="10" rows="2" #delivery_Instruction></textarea>
        </div>
      </div>
      <div class="text-center mt-1">
        <button class="btn primary-btn" (click)="confirm(delivery_Instruction.value,content4)">Confirm</button>
      </div>
    </ng-template>
  </div>
</ng-template>


<ng-template #Address let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Add New Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addressForm" (ngSubmit)="submitAddress(Address)">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Address Type</label>
          <select formControlName="addressType" id="" class="form-control square">
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Others">Others</option>
          </select>
          <p class="text-danger" *ngIf="submitted && addressForm.controls['addressType'].invalid">Address Type is
            required</p>
        </div>
        <div class="col-12 col-md-6">
          <label>Address</label>
          <input type="text" class="form-control square" formControlName="address">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['address'].invalid">Address is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Suburb</label>
          <input type="text" class="form-control square" formControlName="suburb">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['suburb'].invalid">Suburb is required</p>
        </div>
        <div class="col-12 col-md-6">
          <label>State</label>
          <ng-select formControlName="state" placeholder="Select State" [(ngModel)]="selectedState">
            <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
          </ng-select>
          <p class="text-danger" *ngIf="submitted && addressForm.controls['state'].invalid">State is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Postcode</label>
          <input type="text" class="form-control square" formControlName="postcode" maxlength="4">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['postcode'].invalid">Postcode is required</p>
          <p class="text-danger" *ngIf="addressForm.controls['postcode'].hasError('pattern')">Invalid postcode</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-right">
          <button type="submit" class="btn secondary-btn">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Medicine</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to remove this Medicine?</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="remove(modal)">Delete</button>
        <button class="btn btn-secondary ml-2" (click)="modal.dismiss('Cross click')">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Warning!</h4>
  </div>
  <div class="modal-content">
    <div class="modal-body text-center">
      <p>If you have a paper script or token, make sure to store it securely or dispose it properly when no longer
        needed.</p>
      <div class="text-center">
        <button class="btn primary-btn" (click)="d('Cross click')">OK</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #ScriptOpen let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Search Medicine</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <label>Select Product</label>
          <ng-select [items]="productList" [virtualScroll]="true"
                     (change)="onChangeProduct($event,modal)" bindLabel="productName" bindValue="productName">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p>{{ item.productName }}
                <span *ngIf="item.stock <= 0 && !item?.isDiscontinued" class="badge  btn text-capitalize float-right"
                      [ngClass]="this.selectedPharmacy?.acceptBackOrders ? 'bg-light-warning' : 'bg-light-danger'">
                {{ this.selectedPharmacy?.acceptBackOrders ? 'Backorder' : 'Out of stock' }}</span>

                <span *ngIf="item.stock > 0 && !item?.isDiscontinued"
                      class="badge bg-light-success btn text-capitalize float-right">In-Stock</span>

                <span  *ngIf="item?.isDiscontinued" class="badge bg-light-secondary btn text-capitalize float-right">Discontinued</span>
              </p>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #ScriptImage_Upload let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Script</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-12">
        <select class="form-control square" (change)="OnChangeUploadType($event)">
          <option selected disabled>Select Type</option>
          <option value="image">Image</option>
          <option value="url">Url</option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="uploadType === 'image'">
      <div class="col-md-12 col-12">
        <input type="file" accept="image/jpg,image/png,image/jpeg" id="scriptupload" class="form-control d-none"
               (change)="upload($event,modal)">
        <label class="btn secondary-btn ml-2 mt-2" for="scriptupload"><i class="ft-camera mr-1"></i>Upload Script
          Image</label>
      </div>
    </div>
    <div class="row mt-2" *ngIf="uploadType === 'url'">
      <div class="col-md-12 col-12">
        <div class="d-flex">
          <input type="url" class="form-control square" #scriptUrl>
          <button class="btn ml-1 secondary-btn"
                  (click)="paste(scriptUrl.value,modal)">Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ngx-spinner></ngx-spinner>
