<section id="ngx-datatable-filter" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Platform Details</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <!-- ngx-datatable-filter -->
            <div class="row">
              <div class="col-md-4 col-lg-4 col-sm-12">
                <input id="ngx-filter-ref" class="form-control form-control" type="text"
                       (keyup)="filterUpdate($event)"
                       placeholder="Search Platform"/>
              </div>
              <div class="col-md-8 col-lg-8 col-sm-12">
                <button class="float-right btn primary-btn mt-2 d-none d-lg-block d-md-block d-sm-none" (click)="openModal(content)"><i class="ft-plus-circle mr-1"></i>Add Platform</button>
                <button class="float-right btn primary-btn d-lg-none d-md-none d-sm-block btn-block mt-2" (click)="openModal(content)"><i class="ft-plus-circle mr-1"></i>Add Platform</button>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-12 col-sm-12 col-lg-12 col-12">
                <ngx-datatable #tableRowDetails class="bootstrap material" [scrollbarH]="true"
                               [headerHeight]="50" [footerHeight]="50" [rowHeight]="65" [limit]="10"
                               [rows]="platformList">
                  <!-- Row Detail Template -->
                  <ngx-datatable-row-detail [rowHeight]="this.rowHeight">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                      <div class="table-responsive">
                        <table class="table m-0">
                          <thead>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Role</th>
                          <th>Actions</th>
                          </thead>
                          <tbody>
                          <tr *ngFor="let supad of superAdmins">
                            <td>{{ supad.firstName }}</td>
                            <td>{{ supad.email }}</td>
                            <td>{{ supad.number }}</td>
                            <td>{{ supad.role }}</td>
                            <td>
                              <a class="ft-edit text-primary" (click)="open(EditAdmin,supad,'xl')"></a>
                              <a class="ft-trash text-danger ml-1" (click)="open(deleteModal1,supad,'md')"></a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </ng-template>
                  </ngx-datatable-row-detail>
                  <ngx-datatable-column [width]="150" [resizeable]="false" [sortable]="false" [draggable]="false"
                                        [canAutoResize]="false">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                      <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
                         [class.datatable-icon-down]="expanded" title="Expand/Collapse Row"
                         (click)="rowDetailsToggleExpand(row)">
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Logo" [width]="200">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <div class="image-box">
                        <img src="{{row.logo}}" alt="" width="100%">
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Platform" [width]="350">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <p>{{ row.name }}</p>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="URL" [width]="240">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <p>{{ row.url }}</p>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Website" [width]="240">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <p>{{ row.website }}</p>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Actions" [width]="320">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <a class="primary ml-2" (click)="confirmModal(editContent,row,'lg')">
                        <i class="ft-edit font-medium-3"></i>
                      </a>
                      <a class="danger ml-2" (click)="deletePlatform(row,deleteModal)">
                        <i class="ft-trash font-medium-3"></i>
                      </a>
                      <a class="warning ml-2" (click)="confirmModal(view,row,'xl')">
                        <i class="ft-user-plus font-medium-3"></i>
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">PlatForm Registration</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="platform" (ngSubmit)="create_platform()" *ngIf="showForm">
      <br/>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Platform Name</label>
            <input formControlName="name" class="form-control input-md" type="text">
            <p class="text-danger" *ngIf="submitted && platform.controls['name'].invalid">Platform Name is
              required</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Email</label>
            <input formControlName="email" class="form-control input-md" type="email">
            <p class="text-danger" *ngIf="submitted && platform.controls['email'].invalid">Email is
              required</p>
            <p class="text-danger" *ngIf="platform.controls['email'].hasError('email')">Invalid Email</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Phone Number</label>
            <input formControlName="number" class="form-control input-md" type="text">
            <p class="text-danger" *ngIf="submitted && platform.controls['number'].invalid">Phone number
              required</p>
            <p class="text-danger" *ngIf="platform.controls['number'].hasError('pattern')">Invalid Phone Number</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Website</label>
            <input class="form-control input-md" type="text" formControlName="website">
            <p class="text-danger" *ngIf="submitted && platform.controls['website'].invalid">Website is required</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Primary Color</label>
            <input class="form-control input-md" type="color"
                   formControlName="primaryColour">
            <p class="text-danger" *ngIf="submitted && platform.controls['primaryColour'].invalid">Primary Color is
              required</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Secondary Color</label>
            <input class="form-control input-md" type="color" formControlName="secondaryColour">
            <p class="text-danger" *ngIf="submitted && platform.controls['secondaryColour'].invalid">Secondary Color is
              required</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">URl</label>
            <input class="form-control input-md" type="text" formControlName="url">
            <p class="text-danger" *ngIf="submitted && platform.controls['url'].invalid">URl is required</p>
          </div>
        </div>
      </div>
      <div class="form-group text-center">
        <button type="submit" class="btn secondary-btn"><i class="ft-check-circle mr-1"></i>Save
        </button>
      </div>
    </form>
    <div class="row" *ngIf="showblock">
      <div class="col-12">
        <label class="form-control-label">Upload Logo</label>
        <input type="file" accept="image/*" class="form-control square" (change)="uploadLogo($event)">
      </div>
    </div>
  </div>
</ng-template>


<ng-template #editContent let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Platform</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="platform" (ngSubmit)="edit_platform(modal)">
      <br/>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Platform Name</label>
            <input formControlName="name" class="form-control input-md" type="text">
            <p class="text-danger" *ngIf="submitted1 && platform.controls['name'].invalid">Platform Name is
              required</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Email</label>
            <input formControlName="email" class="form-control input-md" type="email">
            <p class="text-danger" *ngIf="submitted && platform.controls['email'].invalid">Email is
              required</p>
            <p class="text-danger" *ngIf="platform.controls['email'].hasError('email')">Invalid Email</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Phone Number</label>
            <input formControlName="number" class="form-control input-md" type="text">
            <p class="text-danger" *ngIf="submitted && platform.controls['number'].invalid">Phone number
              required</p>
            <p class="text-danger" *ngIf="platform.controls['number'].hasError('pattern')">Invalid Phone Number</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Website</label>
            <input class="form-control input-md" type="url" formControlName="website">
            <p class="text-danger" *ngIf="submitted1 && platform.controls['website'].invalid">Website is required</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Primary Color</label>
            <input class="form-control input-md" type="color"
                   formControlName="primaryColour">
            <p class="text-danger" *ngIf="submitted1 && platform.controls['primaryColour'].invalid">Primary Color is
              required</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Secondary Color</label>
            <input class="form-control input-md" type="color" formControlName="secondaryColour">
            <p class="text-danger" *ngIf="submitted1 && platform.controls['secondaryColour'].invalid">Secondary Color is
              required</p>
          </div>
        </div>
      </div>
      <div class="row">

        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">URl</label>
            <input class="form-control input-md" type="url" formControlName="url">
            <p class="text-danger" *ngIf="submitted1 && platform.controls['url'].invalid">URl is required</p>
          </div>
        </div>
      </div>
      <div class="form-group text-center">
        <button type="submit" class="btn secondary-btn"><i class="ft-check-circle mr-1"></i>Save
        </button>
      </div>
    </form>
    <div class="row">
      <div class="col-12">
        <label class="form-control-label">Upload Logo</label>
        <input type="file" accept="image/*" class="form-control square" (change)="uploadLogo($event)">
      </div>
    </div>
  </div>
</ng-template>


<ng-template #view let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Super Admin</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <!-- Card data starts -->
        <form [formGroup]="userRegisteration" (ngSubmit)="onSubmit(modal)">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-1">First Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" id="horizontal-form-1"
                         formControlName="firstName"
                  >
                  <p class="text-danger" *ngIf="submitted && rf.firstName.invalid">First Name is required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-2">Middle Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" id="horizontal-form-2"
                         formControlName="middleName">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-3">Last Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" id="horizontal-form-3" formControlName="lastName">
                  <p class="text-danger" *ngIf="submitted && rf.lastName.invalid">Last Name is required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-5">Phone Number</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" id="horizontal-form-5" formControlName="number"
                         maxlength="10" minlength="8">
                  <p class="text-danger" *ngIf="submitted && rf.number.invalid">Phone number is required</p>
                  <p class="text-danger" *ngIf="rf.number.hasError('pattern')">Invalid Phone number</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-4">Email</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" id="horizontal-form-4" formControlName="email">
                  <p class="text-danger" *ngIf="submitted && rf.email.invalid">Email is required</p>
                  <p class="text-danger" *ngIf="rf.email.hasError('email')">Invalid email</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-4">Password</label>
                <div class="col-md-9">
                  <div class="input-group">
                    <input type="password" class="form-control square" #passsword formControlName="password">
                    <div class="input-group-prepend">
                      <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                        [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                    </div>
                  </div>
                  <p class="text-danger" *ngIf="submitted && rf.password.invalid">Password is required</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-4">Confirm Password</label>
                <div class="col-md-9">
                  <div class="input-group">
                    <input type="password" class="form-control square" #confirmpassword
                           formControlName="confirm_password">
                    <div class="input-group-prepend">
                      <span class="input-group-text cursor-pointer"
                            (click)="showPassword(confirmpassword,'confirm_password')"><i
                        [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                    </div>
                  </div>
                  <p class="text-danger" *ngIf="submitted && rf.confirm_password.invalid">Confirm Password is
                    required</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 text-center">
              <button type="submit" class="btn secondary-btn mb-2"><i class="ft-check-square mr-1"></i>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Card data ends -->
  </div>
</ng-template>

<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Platform</h4>
  </div>
  <div class="modal-body text-center">
    <p>Are you certain you wish to delete the platform?</p>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" class="btn btn-danger" (click)="removePlatform(modal)">Yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>

<ng-template #deleteModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Super Admin</h4>
  </div>
  <div class="modal-body text-center">
    <p>Are you certain you wish to delete the Super Admin?</p>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" class="btn btn-danger" (click)="removeSuperAdmin(modal)">Yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>


<ng-template #EditAdmin let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Super Admin</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <!-- Card data starts -->
        <form [formGroup]="userRegisteration" (ngSubmit)="updateUser(modal)">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-1">First Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square"
                         formControlName="firstName"
                  >
                  <p class="text-danger" *ngIf="submitted && rf.firstName.invalid">First Name is required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-2">Middle Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square"
                         formControlName="middleName">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-3">Last Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" formControlName="lastName">
                  <p class="text-danger" *ngIf="submitted && rf.lastName.invalid">Last Name is required</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-5">Phone Number</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" formControlName="number"
                         maxlength="10" minlength="8">
                  <p class="text-danger" *ngIf="submitted && rf.number.invalid">Phone number is required</p>
                  <p class="text-danger" *ngIf="rf.number.hasError('pattern')">Invalid Phone number</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group row">
                <label class="col-md-3 col-form-label" for="horizontal-form-4">Email</label>
                <div class="col-md-9">
                  <input type="text" class="form-control square" formControlName="email">
                  <p class="text-danger" *ngIf="submitted && rf.email.invalid">Email is required</p>
                  <p class="text-danger" *ngIf="rf.email.hasError('email')">Invalid email</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 text-center">
              <button type="submit" class="btn secondary-btn mb-2"><i class="ft-check-square mr-1"></i>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Card data ends -->
  </div>
</ng-template>
