import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-pharmacy-info',
  templateUrl: './pharmacy-info.component.html',
  styleUrls: ['./pharmacy-info.component.scss']
})
export class PharmacyInfoComponent implements OnInit {

  collapse = true;
  paymentType = false;
  userData: any;
  platform: any;
  backOrder_toggle = false;
  submitted = false;
  selectedPharmacy: any;
  editPharmacyProfile = new FormGroup({
    pharmacy_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    manualPaymentType: new FormControl(false),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  })
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];

  constructor(private authService: AuthService, private toastr: ToastrService, private spinner: NgxSpinnerService, private storage: LocalStorageService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(localStorage.getItem('platform'));
    this.authService.productPageRefresh.subscribe((data: string) => {
      if (data !== 'select') {
        localStorage.setItem('pharmacyId', data);
        this.loadingScreen();
        this.loadPharmacy();
      }
      return;
    });
    this.loadPharmacy();
  }

  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }

  loadPharmacy() {
    this.authService.fetch_particular_pharmacy(this.userData).then(resp => {
      this.selectedPharmacy = resp;
      this.spinner.hide();
      this.initialize();
    }).catch(err => {
      this.spinner.hide();
    });
  }

  initialize() {
    this.editPharmacyProfile.controls['pharmacy_name'].setValue(this.selectedPharmacy.pharmacy_name);
    this.editPharmacyProfile.controls['email'].setValue(this.selectedPharmacy.email);
    this.editPharmacyProfile.controls['phoneNumber'].setValue(this.selectedPharmacy.phoneNumber.split('+61')[1]);
    this.editPharmacyProfile.controls['address'].setValue(this.selectedPharmacy.address);
    this.editPharmacyProfile.controls['suburb'].setValue(this.selectedPharmacy.suburb);
    this.editPharmacyProfile.controls['state'].setValue(this.selectedPharmacy.state);
    this.editPharmacyProfile.controls['postcode'].setValue(this.selectedPharmacy.postcode);
    this.paymentType = this.selectedPharmacy.manualPaymentType;
    this.backOrder_toggle = this.selectedPharmacy.acceptBackOrders;
  }


  OnChanePayment_mode(event: any) {
    this.paymentType = event.target.checked;
  }

  updatePharmacy(toggle, backOrders) {
    this.submitted = true;
    if (this.editPharmacyProfile.invalid) {
      return;
    }
    let body = [];
    for (const key in this.editPharmacyProfile.controls) {
      if (key === 'phoneNumber' && this.editPharmacyProfile.controls[key].dirty) {
        body.push({propName: 'phoneNumber', value: '+61' + this.editPharmacyProfile.controls[key].value})
      } else if (key === 'manualPaymentType' && this.editPharmacyProfile.controls[key].dirty) {
        body.push({
          propName: key, value: String(this.paymentType).toString()
        })

      } else if (this.editPharmacyProfile.controls[key].dirty) {
        body.push({propName: key, value: this.editPharmacyProfile.controls[key].value})
      }
    }

    if (backOrders.checked !== this.selectedPharmacy.acceptBackOrders) {
      body.push({
        propName: 'acceptBackOrders', value: backOrders.checked ? 'true' : 'false'
      })
    }

    if (body.length === 0) {
      this.toastr.error('Edit any of the fields', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    this.authService.editPharmacy(this.userData, body, this.selectedPharmacy).then(resp => {
      this.toastr.success('Pharmacy updated successfully.', 'Message', {
        positionClass: 'toast-top-center'
      });
      body = [];
      this.submitted = false;
      this.loadPharmacy();

    }).catch(err => {
      this.toastr.error('Pharmacy updation failed.', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }
}
