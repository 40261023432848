import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class TimeActivityService {

  private timeout: any;
  private readonly INACTIVITY_PERIOD = 15 * 60 * 1000; // 15 minutes in milliseconds

  constructor(private authService: AuthService, private router: Router, private modalService: NgbModal) {
  }

  resetTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // Trigger logout logic here
      this.modalService.dismissAll();
      this.authService.logout();
      this.router.navigate(['/pages/launch']);
    }, this.INACTIVITY_PERIOD);
  }
}
