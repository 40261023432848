<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12 col-lg-6 col-sm-12">
              <h2 class="card-title">Global Product List</h2>
              <fieldset>
                <div class="input-group box-size">
                  <input type="text" #prdName class="form-control"
                         placeholder="Search Product" (keyup.backspace)="clearText()"
                         (keyup.enter)="filterUpdate(prdName.value)">
                  <div class="input-group-append">
                    <button class="btn btn-secondary"
                            (click)="filterUpdate(prdName.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-12 col-lg-6 col-sm-12">
              <button class="btn primary-btn float-right mr-2" *ngIf="false" (click)="openImportModal(import)"><i
                class="ft-upload-cloud mr-1"></i>Import
              </button>
              <button
                class="btn secondary-btn float-right mr-2" *ngIf="this.userData.auth.data.role === 'Super Admin'"
                (click)="openCreateModal(createGlobalProd)"><i class="ft-plus-circle mr-1"></i>Create
              </button>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                <select (change)="OnChangeLimit($event)" class="form-control float-right" style="width: 150px;">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
                <select (change)="OnChangeCategory($event)" class="form-control float-right mr-1" style="width: 200px;">
                  <option value="All">All</option>
                  <option *ngFor="let category of categoryList" value="{{category.categoryName}}">{{category.categoryName}}</option>
              </select>
              </div>
            </div>
            <div class="table-responsive mt-1">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let product of productList;let i = index;">
                  <td>{{ product.slNumber }}</td>
                  <td>
                    <img
                      src="{{product.productImage.trim() === ''.trim() || product.productImage === null ? 'https://ttbackendstorage.s3.ap-southeast-2.amazonaws.com/2023-11-03T17%3A49%3A26.359Z160-1609305_vegan-logo-png-vegan-or-vegetarian-symbol-transparent.png' :product.productImage}}"
                      alt="{{product.originalName}}" width="50" height="50"
                      (click)="openModal_1(View_image,product,'md')"
                      class="cursor-pointer">
                  </td>
                  <td (click)="showProduct(product,true)"
                      class="cursor-pointer">{{ product.originalName }}
                  </td>
                  <td>{{ product.category }}</td>
                  <td>${{ product.originalCost }}</td>
                  <td>
                    <a href="javascript:;"
                       class="mx-2"
                       (click)="showProduct(product,false)">
                      <i class="ft-eye text-info"></i>
                    </a>
                    <a href="javascript:;" class="primary mx-2" (click)="openContent(create,product,'lg')"
                       *ngIf="this.userData.auth.data.role === 'Admin'">
                      <i class="ft-archive"></i>
                    </a>
                    <a *ngIf="this.userData.auth.data.role === 'Super Admin'" href="javascript:;" class="primary mx-2"
                       (click)="open(updateGlobalProd,product,'xl')">
                      <i class="ft-edit text-primary"></i>
                    </a>
                    <a *ngIf="this.userData.auth.data.role === 'Super Admin'" href="javascript:;" class="primary mx-2"
                       (click)="open(confirm,product,'md')">
                      <i class="ft-trash text-danger"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <ngb-pagination class="float-right" [collectionSize]="this.maxPageSize * 10" (pageChange)="pagination($event)"
                          [(page)]="pageTracker" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #confirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Global Product</h4>
  </div>
  <div class="modal-body text-center">
    <p>Would you like to delete this product?</p>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" class="btn btn-danger" (click)="deleteProduct(modal)">Yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>

<ng-template #View_image let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <img width="100%" [src]="this.globalProdselected.productImage.trim() === '' || this.globalProdselected.productImage === null
    ? 'https://ttbackendstorage.s3.ap-southeast-2.amazonaws.com/2023-11-03T17%3A49%3A26.359Z160-1609305_vegan-logo-png-vegan-or-vegetarian-symbol-transparent.png' : this.globalProdselected.productImage"
         alt="">
  </div>
</ng-template>


<ng-template #import let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">INSTRUCTION</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul>
      <li>Please DO NOT edit anything in red highlighted row.</li>
      <li>For further instructions place your cursor on the red highlighted cell in the excel spreadsheet.</li>
      <li>For you convenience we have pre-filled the columns as an example.</li>
      <li>While uploading the edited file convert the excel to CSV.</li>
    </ul>
    <button class="btn btn-primary" (click)="download()"><i class="ft ft-download-cloud mr-1"></i>Sample File</button>
    <label for="productsUpload" class="btn btn-success float-right cursor-pointer"><i
      class="ft ft-upload-cloud mr-1"></i>Upload</label>
    <input type="file" accept="text/csv" id="productsUpload" class="d-none" (change)="uploadCSv($event)">
  </div>
</ng-template>


<ng-template #create let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Add Stock</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="stockForm" (ngSubmit)="OnSubmit()">
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product SKU</label>
            <input type="text" class="form-control" formControlName="productSKU">
            <p *ngIf="submitted && phar.productSKU.hasError('required')" class="text-danger">Product SKU is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product Name</label>
            <input type="text" class="form-control" formControlName="productName">
            <p *ngIf="submitted && phar.productName.hasError('required')" class="text-danger">Product name is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Stock Availability</label>
            <input type="number" class="form-control" formControlName="stock">
            <p *ngIf="submitted && phar.stock.hasError('required')" class="text-danger">Stock
              is required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Price</label>
            <input type="text" class="form-control" formControlName="cost">
            <p *ngIf="submitted && phar.cost.hasError('required')" class="text-danger">Cost is
              required</p>
            <p *ngIf="phar.cost.hasError('pattern')" class="text-danger">Invalid Price</p>
          </div>
        </div>
      </div>
      <button type="submit" class="btn primary-btn float-right"><i class="ft-check-square mr-1"></i>Save</button>
    </form>
  </div>
</ng-template>

<!--craete Global Products-->
<ng-template #createGlobalProd let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Create Global Product</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="globalProdcutForm" (ngSubmit)="Create_GlobalPharmacyProd(modal)">
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Brand Name <sup class="text-danger font-small-2">*</sup></label>
            <input type="text" class="form-control" formControlName="brandName">
            <p *ngIf="submitted && globalProdcutForm.controls['brandName'].hasError('required')"
               class="text-danger">Brand Name is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Global Product Name<sup class="text-danger font-small-2">*</sup></label>
            <input type="text" class="form-control" formControlName="originalName">
            <p *ngIf="submitted && globalProdcutForm.controls['originalName'].hasError('required')"
               class="text-danger">Product Name is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Cost<sup class="text-danger font-small-2">*</sup></label>
            <input type="text" class="form-control" formControlName="originalCost">
            <p *ngIf="submitted && globalProdcutForm.controls['originalCost'].hasError('required')" class="text-danger">
              Cost is required</p>
            <p *ngIf="globalProdcutForm.controls['originalCost'].hasError('pattern')" class="text-danger">
              Invalid cost</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Price <span class="text-lowercase">(per g)</span></label>
            <input type="text" class="form-control" formControlName="price_per_mg">
            <p *ngIf="submitted && globalProdcutForm.controls['price_per_mg'].invalid" class="text-danger">Price (per g)
              is required</p>
            <p *ngIf="globalProdcutForm.controls['price_per_mg'].hasError('pattern')" class="text-danger">Invalid Price
              (per g)</p>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Size Amount</label>
            <input type="text" class="form-control" formControlName="size_amount">
            <p *ngIf="submitted && globalProdcutForm.controls['size_amount'].invalid" class="text-danger">Size Amount is
              required</p>
            <p *ngIf="globalProdcutForm.controls['size_amount'].hasError('pattern')" class="text-danger">
              Invalid size amount</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Size Measure</label>
            <input type="text" class="form-control" formControlName="size_measure">
            <p *ngIf="submitted && globalProdcutForm.controls['size_measure'].invalid"
               class="text-danger">Size measure is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>TGA Product</label>
            <input type="text" class="form-control" formControlName="TGAProductCategory">
            <p *ngIf="submitted && globalProdcutForm.controls['TGAProductCategory'].invalid" class="text-danger">TGA
              product Amount is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product Type</label>
            <input type="text" class="form-control" formControlName="productType">
            <p *ngIf="submitted && globalProdcutForm.controls['productType'].invalid"
               class="text-danger">Product Type is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Aroma</label>
            <input type="text" class="form-control" formControlName="aromas">
            <p *ngIf="submitted && globalProdcutForm.controls['aromas'].invalid" class="text-danger">Aroma is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>THC Label</label>
            <input type="text" class="form-control" formControlName="THCLabel">
            <p *ngIf="submitted && globalProdcutForm.controls['THCLabel'].invalid"
               class="text-danger">THC label is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>CBD Label</label>
            <input type="text" class="form-control" formControlName="CBDLabel">
            <p *ngIf="submitted && globalProdcutForm.controls['CBDLabel'].invalid" class="text-danger">CBD label is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product Schedule</label>
            <input type="text" class="form-control" formControlName="productSchedule">
            <p *ngIf="submitted && globalProdcutForm.controls['productSchedule'].invalid"
               class="text-danger">Product Schdedule is required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Category<sup class="text-danger font-small-2">*</sup></label>
            <div class="form-group">
              <div class="form-group">
                <ng-select formControlName="category" [items]="categoryList" bindLabel="categoryName"
                           bindValue="categoryName"
                           placeholder="Select Category" [(ngModel)]="selectedCategory">
                </ng-select>
                <p *ngIf="submitted && stk.category.hasError('required')" class="text-danger">Category is
                  required</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product Species</label>
            <input type="text" class="form-control" formControlName="productSpecies">
            <p *ngIf="submitted && globalProdcutForm.controls['productSpecies'].invalid"
               class="text-danger">Product Species is required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 col-lg-12 col-12">
          <div class="form-group mb-2">
            <label>Description</label>
            <textarea cols="10" rows="2" formControlName="description" class="form-control square"></textarea>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <label>Upload Product Image</label>
          <input type="file" accept="image/*" #imageFile class="form-control square"
                 (change)="uploadImage($event,'image',imageFile);">
          <p *ngIf="fileValidation.image"
             class="text-danger">Only .jpeg .jpg .png image files</p>
        </div>
        <div class="col-md-6 col-12">
          <label>Additional Resources</label>
          <input type="file" accept="application/pdf,image/*" #pdf class="form-control square" multiple
                 (change)="uploadImage($event,'pdf',pdf)">
          <p *ngIf="fileValidation.pdftye"
             class="text-danger">Only .pdf files</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12 px-1 py-1">
          <div class="col-md-6 col-12 px-1 py-1">
            <img src="{{file64.url}}" alt="{{file64.name}}" class="ml-1" width="100" *ngIf="file64.url !== ''"><br>
            <p *ngIf="file64.url !== ''">{{ file64.name }} <i class="ft-x text-danger mr-1 cursor-pointer"
                                                              (click)="remove('image',{},imageFile)"></i></p>
          </div>
        </div>
        <div class="col-md-6 col-12 px-1 py-1">
          <div class="d-flex">
            <div *ngFor="let image of this.images">
              <img src="{{image.url}}" alt="{{image.name}}" class="ml-1" width="100" height="50">
              <p class="text-center">{{ image.name }} <i class="ft-x text-danger mr-1 cursor-pointer"
                                                         (click)="remove('addtional',image,pdf)"></i></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 text-center">
          <button type="submit" [disabled]="disableBtn || fileValidation.image || fileValidation.pdftye"
                  class="btn primary-btn mt-2"><i
            class="ft-check-square mr-1"></i>Save
          </button>
          <!--          <button type="submit" [disabled]="disableBtn || fileValidation.image" class="btn primary-btn mt-2"><i-->
          <!--            class="ft-check-square mr-1"></i>Save-->
          <!--          </button>-->
        </div>
      </div>
    </form>

  </div>
</ng-template>


<!--Update Global Products-->
<ng-template #updateGlobalProd let-c="close" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Global Product</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="globalProdcutForm" (ngSubmit)="updateGlobalPrds(modal)">
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Brand Name<sup class="text-danger font-small-2">*</sup></label>
            <input type="text" class="form-control" formControlName="brandName">
            <p *ngIf="submitted && globalProdcutForm.controls['brandName'].hasError('required')"
               class="text-danger">Brand Name is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Global Product Name<sup class="text-danger font-small-2">*</sup></label>
            <input type="text" class="form-control" formControlName="originalName">
            <p *ngIf="submitted && globalProdcutForm.controls['originalName'].hasError('required')"
               class="text-danger">Product Name is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Cost<sup class="text-danger font-small-2">*</sup></label>
            <input type="text" class="form-control" formControlName="originalCost">
            <p *ngIf="submitted && globalProdcutForm.controls['originalCost'].hasError('required')" class="text-danger">
              Cost is required</p>
            <p *ngIf="globalProdcutForm.controls['originalCost'].hasError('pattern')" class="text-danger">
              Invalid cost</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Price <span class="text-lowercase">(per g)</span></label>
            <input type="text" class="form-control" formControlName="price_per_mg">
            <p *ngIf="submitted && globalProdcutForm.controls['price_per_mg'].invalid"
               class="text-danger">Price (per g)
              required</p>
            <p *ngIf="globalProdcutForm.controls['price_per_mg'].hasError('pattern')"
               class="text-danger">Invalid Price (per g)</p>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Size Amount</label>
            <input type="text" class="form-control" formControlName="size_amount">
            <p *ngIf="submitted && globalProdcutForm.controls['size_amount'].invalid" class="text-danger">Size Amount is
              required</p>
            <p *ngIf="globalProdcutForm.controls['size_amount'].hasError('pattern')" class="text-danger">
              Invalid Size Amount</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Size Measure</label>
            <input type="text" class="form-control" formControlName="size_measure">
            <p *ngIf="submitted && globalProdcutForm.controls['size_measure'].invalid"
               class="text-danger">Size measure is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>TGA Product</label>
            <input type="text" class="form-control" formControlName="TGAProductCategory">
            <p *ngIf="submitted && globalProdcutForm.controls['TGAProductCategory'].invalid" class="text-danger">TGA
              product Amount is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product Type</label>
            <input type="text" class="form-control" formControlName="productType">
            <p *ngIf="submitted && globalProdcutForm.controls['productType'].invalid"
               class="text-danger">Product Type is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Aroma</label>
            <input type="text" class="form-control" formControlName="aromas">
            <p *ngIf="submitted && globalProdcutForm.controls['aromas'].invalid" class="text-danger">Aroma is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>THC Label</label>
            <input type="text" class="form-control" formControlName="THCLabel">
            <p *ngIf="submitted && globalProdcutForm.controls['THCLabel'].invalid"
               class="text-danger">THC label is
              required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>CBD Label</label>
            <input type="text" class="form-control" formControlName="CBDLabel">
            <p *ngIf="submitted && globalProdcutForm.controls['CBDLabel'].invalid" class="text-danger">CBD label is
              required</p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product Schedule</label>
            <input type="text" class="form-control" formControlName="productSchedule">
            <p *ngIf="submitted && globalProdcutForm.controls['productSchedule'].invalid"
               class="text-danger">Product Schedule is required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Category<sup class="text-danger font-small-2">*</sup></label>
            <div class="form-group">
              <ng-select formControlName="category" [items]="categoryList" bindLabel="categoryName"
                         bindValue="categoryName"
                         placeholder="Select Category" [(ngModel)]="selectedCategory">
              </ng-select>
              <p *ngIf="submitted && stk.category.invalid" class="text-danger">Category is
                required</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Product Species</label>
            <input type="text" class="form-control" formControlName="productSpecies">
            <p *ngIf="submitted && globalProdcutForm.controls['productSpecies'].invalid"
               class="text-danger">THC label is required</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 col-lg-12 col-12">
          <div class="form-group mb-2">
            <label>Description</label>
            <textarea cols="10" rows="2" formControlName="description" class="form-control square"></textarea>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <label>Upload Product Image</label>
          <input type="file" accept="image/*" #imageFile class="form-control square"
                 (change)="uploadImage($event,'image',imageFile);">
          <p *ngIf="fileValidation.image" class="text-danger">Only .jpeg .jpg .png image files</p>
          <div class="text-left">
            <img src="{{selectedProduct?.productImage}}" *ngIf="selectedProduct?.productImage !== ''" class="px-1 py-1"
                 width="50" height="50" alt="image">
            <p *ngIf="selectedProduct?.productImage !== ''">{{ selectedProduct.originalName }}
              <i class="ft-x text-danger mr-1 cursor-pointer" (click)="deleteGlobalProdImage()"></i></p>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <label>Additional Resources</label>
          <input type="file" accept="application/pdf,image/*" #pdf class="form-control square" multiple
                 (change)="uploadImage($event,'pdf',pdf)">
          <p *ngIf="fileValidation.pdftye"
             class="text-danger">Only .pdf files</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12 px-1 py-1"  *ngIf="file64.url !== ''">
          <div class="text-left">
            <img src="{{file64.url}}" alt="{{file64.name}}" class="ml-1" width="50" height="50"
                 *ngIf="file64.url !== ''"><br>
            <p *ngIf="file64.url !== ''">{{ file64.name }} <i class="ft-x text-danger mr-1 cursor-pointer"
                                                              (click)="remove('image',{},imageFile)"></i></p>
          </div>
        </div>
        <div class="col-md-6 col-12 px-1 py-1">
          <div class="d-flex">
            <div *ngFor="let image of this.images">
              <img src="{{image.url}}" alt="{{image.name}}" class="ml-1" width="100" height="50">
              <p class="text-center">{{ image.name }} <i class="ft-x text-danger mr-1 cursor-pointer"
                                                         (click)="remove('addtional',image,pdf)"></i></p>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-12" *ngIf="this.selectedProduct?.uploadProductInfo.length > 0">
          <label>Uploaded Additional Resources</label><br>
          <div class="btn-group" *ngFor="let url of this.selectedProduct?.uploadProductInfo;let i = index;">
            <a class="cursor-pointer ml-2" style="text-decoration: underline;"
               (click)="downloadFiles(url,i)">{{ url?.fileName }}
              <i class="ft-download mr-1"></i>
            </a>
            <a *ngIf="userData?.auth?.data.role !== 'User'" ngbTooltip="Delete file" placement="top"
               class="ft-x text-danger mr-1 cursor-pointer" (click)="deleteFile(url)"></a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 text-center">
          <button [disabled]="disableBtn" type="submit" class="btn secondary-btn mt-2"><i
            class="ft-check-square mr-1"></i>Save
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #View_global_prod let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h1 class="head-title">{{ this.globalProdselected.originalName }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6 class="font-weight-bold">{{ this.globalProdselected.brandName }}</h6>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-8">
        <div class="row">
          <div class="col-sm-3">
            <label class="font-weight-bold">Type</label>
            <p>{{ this.globalProdselected.productType }}</p>
          </div>
          <div class="col-sm-3">
            <label class="font-weight-bold">TGA Product Category</label>
            <p>{{ this.globalProdselected.TGAProductCategory }}</p>
          </div>
          <div class="col-sm-3">
            <label class="font-weight-bold">THC Label <i class="ft-info cursor-pointer text-info" placement="top"
                                                         ngbTooltip="tetrahydrocannabinol"></i></label>
            <p>{{ this.globalProdselected.THCLabel }}</p>
          </div>
          <div class="col-sm-3">
            <label class="font-weight-bold">CBD Label <i class="ft-info cursor-pointer text-info" placement="top"
                                                         ngbTooltip="cannabidiol"></i></label>
            <p>{{ this.globalProdselected.CBDLabel }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <label class="font-weight-bold">Schedule</label>
            <p>{{ this.globalProdselected.productSchedule }}</p>
          </div>
          <div class="col-sm-3">
            <label class="font-weight-bold">Category</label>
            <p>{{ this.globalProdselected.category }}</p>
          </div>
          <div class="col-sm-3">
            <label class="font-weight-bold">Plant Species</label>
            <p>{{ this.globalProdselected.productSpecies }}</p>
          </div>
          <div class="col-sm-3">
            <label class="font-weight-bold">Aromas</label>
            <p>{{ this.globalProdselected.aromas }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="font-weight-bold font-italic text-info"
                   (click)="this.showBox = !this.showBox">{{ this.showBox ? 'Show less' : 'Show more' }}</label>
          </div>
        </div>
        <div class="row" *ngIf="this.showBox">
          <div class="col-6">
            <label class="font-weight-bold">Description</label>
            <textarea class="form-control square" disabled cols="60"
                      rows="4">{{ this.globalProdselected.description }}</textarea>
          </div>
          <div class="col-6">
            <label class="font-weight-bold">Original Data</label>
            <textarea class="form-control square" disabled cols="60"
                      rows="4">{{ this.globalProdselected.originalData }}</textarea>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="col-sm-2">
          <img [src]="this.globalProdselected.productImage" alt="{{this.globalProdselected.originalName}}"
               class="users-avatar-shadow rounded" height="250" width="300">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 px-2 py-2">
        <div class="table-responsive">
          <table class="table m-0">
            <thead>
            <tr>
              <th>Size</th>
              <th>Price (per mg)</th>
              <th>Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ this.globalProdselected.size_amount + ' ' + this.globalProdselected.size_measure }}</td>
              <td>{{ this.globalProdselected.price_per_mg }}</td>
              <td>{{ this.globalProdselected.originalCost }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--    <section class="users-view">-->
    <!--      &lt;!&ndash; Media object starts &ndash;&gt;-->
    <!--      <div class="row">-->
    <!--        <div class="col-12 col-sm-7">-->
    <!--          <div class="media d-flex align-items-center">-->
    <!--            <a href="javascript:;">-->
    <!--              <img [src]="this.globalProdselected.productImage" alt="{{this.globalProdselected.originalName}}"-->
    <!--                   class="users-avatar-shadow rounded" height="64" width="64">-->
    <!--            </a>-->
    <!--            <div class="media-body ml-3">-->
    <!--              <h4>-->
    <!--                <span class="users-view-name mr-1">{{this.globalProdselected.originalName}}</span>-->
    <!--              </h4>-->
    <!--              <span class="users-view-username">{{this.globalProdselected.brandName}}</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      &lt;!&ndash; Media object ends &ndash;&gt;-->

    <!--      <div class="row">-->
    <!--        <div class="col-12">-->
    <!--          &lt;!&ndash; Card data starts &ndash;&gt;-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-4">-->
    <!--              <table class="table table-borderless">-->
    <!--                <tbody>-->
    <!--                <tr>-->
    <!--                  <th>Original Name:</th>-->
    <!--                  <td>{{this.globalProdselected.originalName}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th>Brand Name:</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.brandName}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th>originalCost:</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.originalCost}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th>Category:</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.category}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-capitalize">price per mg</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.price_per_mg}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-capitalize">size measure</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.size_measure}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-capitalize">size amount</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.size_amount}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-capitalize">TGA Product Category</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.TGAProductCategory}}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <table class="table table-borderless">-->
    <!--                <tbody>-->
    <!--                <tr>-->
    <!--                  <th>Product Type:</th>-->
    <!--                  <td>{{this.globalProdselected.productType}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th>Aroma:</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.aromas}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th>THC Label:</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.THCLabel}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th>CBD Label:</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.CBDLabel}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-capitalize">product Schedule</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.productSchedule}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-capitalize">product Species</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.productSpecies}}</td>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th class="text-capitalize text-justify">description</th>-->
    <!--                  <textarea class="form-control square" cols="60"-->
    <!--                            rows="4">{{this.globalProdselected.description}}</textarea>-->
    <!--                </tr>-->
    <!--                <tr>-->
    <!--                  <th>Original Data</th>-->
    <!--                  <td class="users-view-latest-activity">{{this.globalProdselected.originalData}}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
  </div>
</ng-template>


<ngx-spinner></ngx-spinner>
