import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private _pulseRightData$ = new BehaviorSubject<any>(null);
  public pulseRightData$ = this._pulseRightData$.asObservable();

  constructor() { }

  public watchStorage(): Observable<any> {
    return this.pulseRightData$;
  }

  public async saveData(key: string, value: string) {
    await localStorage.setItem(key, value);
    this._pulseRightData$.next(JSON.parse(value));
  }
  public async getData(key: string) {
    const data = localStorage.getItem(key);
    this._pulseRightData$.next(JSON.parse(data));
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }
  public clearData() {
    localStorage.clear();
  }
}
