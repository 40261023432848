<footer class="footer">
  <p class="text-center">Copyright &copy; {{ currentDate | date: 'yyyy' }}<span
    class="text-primary"> {{ platform.name }} All rights reserved</span></p>
  <p class="text-center text-primary cursor-pointer">
    <span (click)="open(smallModal,'T&C')">Terms and Conditions</span> | <span (click)="open(smallModal,'P&P')">Privacy & Policy</span>
  </p>
</footer>
<!--<ng-template #smallModal let-modal let-d="dismiss">-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title">{{modalType === 'T&C' ? 'Chemist POS Direct Terms & Conditions' : 'Chemist POS Direct - Privacy Policy'}}</h4>-->
<!--    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--    <app-terms-conditions *ngIf="modalType === 'T&C'"></app-terms-conditions>-->
<!--    <app-privacy-policy *ngIf="modalType !== 'T&C'"></app-privacy-policy>-->
<!--  </div>-->
<!--</ng-template>-->

<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{modalType === 'T&C' ? 'Script IT Terms & Conditions' : 'Script IT - Privacy Policy'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-terms-conditions *ngIf="modalType === 'T&C'"></app-terms-conditions>
    <app-privacy-policy *ngIf="modalType !== 'T&C'"></app-privacy-policy>
  </div>
</ng-template>
